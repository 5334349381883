import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useRedoModal } from 'utils/hooks/useRedoModal';
import {
  StyledTestHeader,
  StyleLogoBox,
  StyleLogoName,
  StyledLinkZone,
  StyledLink,
  StyledTestHeaderLogo,
  StyleUserName,
  StyledSlash
} from './OneTestHeader.style';

export const OneTestHeader = ({ className, logout, userData, authStateValue, env, history, openEduSubjectModalToggle }) => {
  const { renderRedoModal, goToHome, reDo } = useRedoModal(userData.organizationType);
  const location = useLocation();
  const goToOneTestEntrance = () => {
    window.location.href = `https://onetest${env !== 'release' ? `-${env}` : ''}.oneclass.com.tw/entrance`;
  };
  const goTo = (pathName) => {
    history.push(pathName);
  };

  const isCreatePaper = () => {
    if (location.pathname !== '/examPaperList' && location.pathname !== '/memberCenter') return true;
    return false;
  };

  const isCreateQuestionProcess = useMemo(() => {
    if (location.pathname === '/computerQuestion' || location.pathname === '/manualProposition' || location.pathname === '/knowledgeProposition') return true;
    return false;
  }, [location.pathname]);

  const linkData = [
    {
      pathname: 'showModal',
      linkText: '開始出卷',
      click: openEduSubjectModalToggle
    },
    {
      pathname: '/examPaperList',
      linkText: '試卷列表'
    },
    {
      pathname: '/memberCenter',
      linkText: '常用設定'
    },
  ];

  return (
    <StyledTestHeader>
      <StyleLogoBox>
        <div className='logoBox' onClick={() => {goToOneTestEntrance();}}>
          <StyledTestHeaderLogo/>
          <StyleUserName>{userData.name}<div className='triangle' /></StyleUserName>
        </div>
        <StyledSlash></StyledSlash>
        <StyleLogoName>題庫系統</StyleLogoName>

      </StyleLogoBox>
      <StyledLinkZone>
        {
          linkData.map((item) => (
            item.pathname === 'showModal' ?
              <StyledLink
                key={item.pathname}
                active={isCreateQuestionProcess}
                onClick={() => isCreateQuestionProcess ? null : item.click()}
              >
                {item.linkText}
              </StyledLink>
              :
              <StyledLink
                key={item.pathname}
                active={location.pathname === item.pathname}
                onClick={() => {
                  isCreatePaper() ? goToHome(item.pathname, item.linkText) : goTo(item.pathname);
                }}>{item.linkText}
              </StyledLink>
          ))
        }
      </StyledLinkZone>
      {renderRedoModal()}
    </StyledTestHeader>
  );
};

OneTestHeader.propTypes = {
  className: PropTypes.string,
  logout: PropTypes.func,
  userData: PropTypes.object
};