/**
 * variable & Object key 大寫
 */

export const DIFFICULTY = ['BEGIN', 'INTERMEDIATE', 'EXPERT', 'BASIC', 'ADVANCED'];

export const DIFFICULTY_TAG_DISPLAY = ['BEGIN', 'INTERMEDIATE', 'EXPERT'];

export const LANGUAGE_LIST = ['EW', 'CN', 'PC', 'EN', 'CH'];

export const OPTION_ERROR_MSG = {
  schoolName: '請輸入學校名稱',
  paperName: '請輸入試卷名稱',
  teacherSign: '請輸入教師名稱',
  room: '請輸入班級',
  grade: '請輸入年級'
};

export const ENVIRONMENT_NAME = {
  DEV: 'dev',
  UAT: 'uat',
  RELEASE: 'release',
};

export const OUTPUT_TYPE_NAME = {
  Files: '紙本卷類',
  Quick: '速測',
  Online: '線上測驗',
};