import styled from 'styled-components';
export const StyledHomePage = styled.div`
	position: relative;
	.start{
      background: url('assets/homePage.svg');
      background-repeat: repeat;
      background-size: 100%;
      background-position: center;
      width: 100%;
      height: calc(100vh - 56px - 93px);
      min-height: 530px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .startImage {
        max-width: 674px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
		.startContent{
      padding-left: 4vw;
			.topTitle{
				margin-bottom: 8px;
				color: #41415B;
			}
			.title{
				font-size: 36px;
				font-weight: bold;
				color: #41415B;
			}
			.text{
				font-size: 20px;
				color: #41415B;
				margin: 20px 0;
			}
			.btn{
				display: inline-flex;
			}
		}
  @media (max-width: 1024px) {
    .start{
      display: flex;
      flex-direction: column;
      background: url('assets/mobileHomePage.svg');
      background-size: cover;
      width: 100%;
      height: calc(100vh - 70px);
      padding: 72px 0 86px 0;
      justify-content: space-around;
      align-items: start;
      .startImage {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
	.footerBtn{
		display: flex;
		&> *:first-child{
			margin-right: 8px;
		}
	}
	.scrollBtn{
		cursor: pointer;
		display:flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		background: ${({ theme }) => theme.colors.ui.ui_03};
		padding: 12px;
		border-radius: 50%;
		color: ${({ theme }) => theme.colors.ui.white};
		z-index: 300;
		bottom: 44px;
		right: 44px;
		svg{
			margin-right: 0;
      font-size: 24px;
		}
	}
	
	.warningBar{
		display: flex;
		align-items: center;
		padding: 12px 16px;
		color:${({ theme }) => theme.colors.text.text_07};
		background: ${({ theme }) => theme.colors.support.middle};
		margin: 0px 8px;
		@media (max-width: 1024px) {
			margin: 16px 24px 0 24px;
		}
		svg{
			font-size: 20px;
			color: ${({ theme }) => theme.colors.support.warning};
			@media (max-width: 1024px) {
				font-size: 30px;
			}
		}
	}
	.ant-radio-group.radioList{
		width: 100%;
		.ant-radio-button-wrapper{
			width: 18.4%;
			margin-bottom: 8px;
			&:not(:first-child){
				margin-left:2%;
			}
			&:nth-child(5n+1){
				margin-left: 0;
			}
		}
	}
`;

export const StyledHomePageContent = styled.div`
    position: relative;
	display: flex;
	overflow: ${({ notLimitHeight }) => notLimitHeight ? 'visible' : 'auto'};
	overflow-y: ${({ notLimitHeight }) => notLimitHeight ? 'visible' : 'overlay'};
	margin: 12px 8px;
	border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
    box-sizing: border-box;
    border-radius: 8px;
	height:${({ notLimitHeight }) => notLimitHeight ? 'auto' : '70vh'};

	> div {
		height: 100%;
		width: 960px;
	}
	.tempDone{
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
		font-weight: 500;
	}
	
`;
export const UiBtnZone = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	& > div:not(:first-child){
		margin-left: 8px;
	}
`;