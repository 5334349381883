import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Tooltip } from 'antd';
import { AuthProvider } from '@oneclass/ui-components';
import { RedoCircleSolid, SignOutSolid } from '@onedesign/icon';
import { useRedoModal } from 'utils/hooks/useRedoModal';
import classNames from 'classnames';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { useHistory } from 'react-router-dom';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { StyledExamPaperHeader } from './ExamPaperHeader.style';
// eslint-disable-next-line react/prop-types
export const ExamPaperHeader = ({ className, pageName, globalExamPaperData, step }) => {
  // const { isDesktop } = useDeviceDetect();
  const history = useHistory();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const [userData, setUserData] = useState({
    identity: '',
    organizationType: ''
  });
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);

  useEffect(() => {
    if (authState.context.userDetails?.userStatus) {
      const { organization, identity } = authState.context.userDetails?.userStatus;
      setUserData({
        organizationType: organization.type,
        identity
      });
    }
  }, [authState.value]);

  const { renderRedoModal, goToHome, reDo } = useRedoModal(userData.organizationType);

  const checkSetupBtnIsShow = () => {
    if (deviceData.isDesktop) {
      return false;
    } else {
      const path = window.location.pathname;
      if (path === '/computerQuestion' && step === 3 | step === 4 | step === 5) {
        return true;
      } else if ((path === '/manualProposition' || path === '/knowledgeProposition') && (step === 2 || step === 3)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const showReDoBtn = useMemo(() => {
    const path = window.location.pathname;
    if (path === '/computerQuestion' && step !== 6) {
      return true;
    } else if ((path === '/manualProposition' || path === '/knowledgeProposition') && step !== 5) {
      return true;
    } else {
      return false;
    }
  }, [step, window.location.pathname]);

  const ReturnHomeBtn = () => {
    if (showReDoBtn) {
      goToHome(userData.organizationType === 'Tutoring' ? '/examPaperList' : '/');
    } else {
      if (userData.organizationType === 'Tutoring' ) {
        history.push('/examPaperList');
      } else {
        history.push('/');
      }
    }
  };

  return (
    <>
      <StyledExamPaperHeader className={className} data-testid="ExamPaperHeader" id={'ExamPaperHeader'}>
        <div className='pageTopTitle'>
          {globalExamPaperData?.eduSubjectName?.edu}{globalExamPaperData?.eduSubjectName?.subject}-{pageName}
          {}
          {
            showReDoBtn &&
              <div className='redo' onClick={() => {reDo(userData.organizationType === 'Tutoring' ? '/examPaperList' : '/');}}>
                <Tooltip placement="top" title={'重新選擇欲出卷的學制科目與命題方式'} zIndex={100} color='#4e4e5a'>
                  <RedoCircleSolid/>
                </Tooltip>
              </div>
          }
        </div>
        <div className='returnHome' onClick={() => {ReturnHomeBtn();}}><SignOutSolid/>{userData.organizationType === 'Tutoring' ? '返回試卷列表' : '返回首頁'}</div>
        {
          !deviceData.isDesktop &&
            <div id={'setupBtn'} className={classNames('setupBtn', checkSetupBtnIsShow() ? 'showSetupBtn' : '')}>題數配分</div>
        }
      </StyledExamPaperHeader>
      {renderRedoModal()}
    </>

  );
};