// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getAll } from 'firebase/remote-config';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const env = process.env.REACT_APP_NODE_ENV;

const firebaseConfig = {
  dev: {
    apiKey: 'AIzaSyDm8hwenX6gRGjo_7E_RVcyGXmZsvY797s',
    authDomain: 'onepaper-dev.firebaseapp.com',
    projectId: 'onepaper-dev',
    storageBucket: 'onepaper-dev.appspot.com',
    messagingSenderId: '636658359840',
    appId: '1:636658359840:web:762a743d520335c443e645',
    measurementId: 'G-NS512EL028'
  },
  uat: {
    apiKey: 'AIzaSyAk9nXRjOw7YTStaqTl6PEEV2LT2-3f8HU',
    authDomain: 'onepaper-uat.firebaseapp.com',
    projectId: 'onepaper-uat',
    storageBucket: 'onepaper-uat.appspot.com',
    messagingSenderId: '340796888060',
    appId: '1:340796888060:web:29bed58097115206941879',
    measurementId: 'G-EQEVKWW9KF'
  },
  release: {
    apiKey: 'AIzaSyCyUg16dfdOdmeWI2lXSt3F4I7F7OdgVfM',
    authDomain: 'onepaper-318201.firebaseapp.com',
    projectId: 'onepaper-318201',
    storageBucket: 'onepaper-318201.appspot.com',
    messagingSenderId: '61215795844',
    appId: '1:61215795844:web:1359d8f2c73f0d8cb230d1',
    measurementId: 'G-4C8TV56N5N'
  },
  test: {
    apiKey: 'AIzaSyCyUg16dfdOdmeWI2lXSt3F4I7F7OdgVfM',
    authDomain: 'onepaper-318201.firebaseapp.com',
    projectId: 'onepaper-318201',
    storageBucket: 'onepaper-318201.appspot.com',
    messagingSenderId: '61215795844',
    appId: '1:61215795844:web:1359d8f2c73f0d8cb230d1',
    measurementId: 'G-4C8TV56N5N'
  },
};

// const firebaseConfig = {
//   apiKey: 'AIzaSyDm8hwenX6gRGjo_7E_RVcyGXmZsvY797s',
//   authDomain: 'onepaper-dev.firebaseapp.com',
//   projectId: 'onepaper-dev',
//   storageBucket: 'onepaper-dev.appspot.com',
//   messagingSenderId: '636658359840',
//   appId: '1:636658359840:web:762a743d520335c443e645',
//   measurementId: 'G-NS512EL028'
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig[env]);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 10; // 本機 cache 時間長短（時間內將不會重複打api)

// 取得 全部 RemoteConfig 資料
export const getAllRemoteConfig = async() => {
  const response = await fetchAndActivate(remoteConfig).then((res) => {
    const data = getAll(remoteConfig);
    return data;
  }).catch((err) => {
    console.log('RemoteConfig error:', err);
  });
  return response;
};

