import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
// import PropTypes from 'prop-types';
import { getAnomalySelectOption } from 'api/drawUpFast';
import { useMachine } from '@xstate/react';
import {
  Box,
  Table,
  Input,
  Select,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  PickListDropdown,
  sliceTableData,
  Pagination,
  sectionToChinese,
  DifferentTag,
  diffStr,
  search,
  setSelectOptions,
  AuthProvider,
  Modal,
  Drawer,
  Checkbox as CheckboxType,
  CheckboxTree,
  openNotificationWithIcon,
} from '@oneclass/ui-components';
import { Checkbox, Button, RadioGroup } from '@oneclass/onedesign';
import { DIFFICULTY, LANGUAGE_LIST } from 'constants/index';
import { useBoolean, useSelections } from 'ahooks';
import { BookSolid1, ChevronRightOutline, ChevronLeftOutline, ListAltSolid, SearchPlusSolid, XmarkOutline, StarSolid, ReturnSolid, XmarkCircleSolid } from '@onedesign/icon';
import { StyledMpQuestionSelectContent, StyledFilterBar, StyledAreaSelectList, StyledAreaSelectRow, StyledAreaSelectContainer, StyleReportModalContent, StyledMobileFilterContent } from './MpQuestionSelectContent.style';
import { Radio } from 'antd';
import { useQuestionAnalysisModal } from 'utils/hooks/useQuestionAnalysisModal';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useTouch } from '../../utils/hooks/useTouch';
import classNames from 'classnames';
import { postGetQuestion } from 'api/query';
import { postQuestionReport } from 'api/question';

const questionDisplayOptions = [
  {
    name: '全部試題',
    value: 'all',
  },
  {
    name: '已選試題',
    value: 'checked',
  },
  {
    name: '未選試題',
    value: 'notCheck',
  },
];

const mobileQuestionDisplayOptions = [
  {
    label: '全部試題',
    value: 'all',
  },
  {
    label: '已選試題',
    value: 'checked',
  },
  {
    label: '未選試題',
    value: 'notCheck',
  },
];

const defaultCountInputValue = {
  answer: '',
  question: '',
  score: {
    perQues: '',
    perAns: '',
    allScore: 0,
  },
  difficulty: {
    BEGIN: '',
    INTERMEDIATE: '',
    EXPERT: '',
  }
};
const { Header, Body, Row, Item } = Table;
const filterDifficulty = {
  BEGIN: ['BEGIN'],
  INTERMEDIATE: ['INTERMEDIATE', 'BASIC', 'ADVANCED'],
  EXPERT: ['EXPERT']
};
export const MpQuestionSelectContent = ({
  className,
  examPaperInfo,
  prevKeyList,
  examPaperData,
  searchKey,
  memberEduSubject,
  saveData,
  getTableData,
  getTotalQuesTypeData,
  getQuestions,
  getSaveData,
  getKeyList,
  globalExamPaperData,
  searchParams,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  isEditCopy,
  reCreateSearchParams,
  editCopyExamPaperData,
}) => {
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { useAuthDispatch, useAuthState } = AuthProvider;
  const authState = useAuthState();

  const [tableData, setTableData] = useState(examPaperData);
  const [filterCondition, setFilterCondition] = useState({
    name: ''
  });
  const [modalImageSrc, setModalImageSrc] = useState(null);
  const [nowFilterChapter, setNowFilterChapter] = useState('all');
  const [displayQuestionsType, setDisplayQuestionsType] = useState('all');
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const [filterChapterVisible, { toggle: setFilterChapterVisibleToggle, setFalse: setFilterChapterVisibleFalse }] = useBoolean(true);
  const [keyList, setKeyList] = useState(prevKeyList);
  const tableRef = useRef();
  const env = process.env.REACT_APP_NODE_ENV;
  const [questions, setQuestions] = useState([]);
  const [bookChapters, setBookChapters] = useState([]);

  const [statePostGetQuestion, sendPostGetQuestion] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        setIsLoading(true);
        const res = await postGetQuestion(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { books, questions } = data;
        if (isSuccess) {
          setIsLoading(false);
        }
        // 若為 複製編輯流程 一次多選已選題目
        if (isEditCopy) {
          //判斷是否為第一次進入頁面（尚未帶入已選題目）
          let firstTime = true;
          Object.entries(tableData).forEach(([key, value]) => {
            if (value.pickUpQuestions) firstTime = false;
          });
          if (firstTime) selectedQuesForEditCopy(editCopyExamPaperData.selectedQuestion);
        }
        setQuestions(transformQuestions(questions, books));
        setBookChapters(transformBookChapters(books));
        return {
          isSuccess, systemCode, message,
          questions: transformQuestions(questions),
          bookChapters: transformBookChapters(books),
        };
      },
    },
  });

  // const { questions, bookChapters } = statePostGetQuestion.context.result || {};

  const transformKnowledgeList = (array) => {
    if (!array) return [];
    const nextData = array.map((item) => {
      return {
        itemCode: item.code,
        itemName: item.name,
      };
    });
    return nextData;
  };

  const transformBookChapters = (books) => {

    if (!books) return [];
    // 層級判斷 （會依照學制科目而不同）
    const nextData = books.map((book, index) => {
      let chaptersArray = [];

      book.data.forEach((chapter) => {
        if (chapter.data) {
          // 三層結構
          chaptersArray.push({
            code: chapter?.code,
            desc: `[${chapter?.code}] ${chapter?.name}`,
            hasKnowledge: false,
            knowledgeList: null,
            name: chapter?.name,
            parentCode: null,
          });
          chapter.data.forEach((item) => {
            chaptersArray.push({
              code: item?.code,
              desc: `[${item?.code}] ${item?.name}`,
              hasKnowledge: true,
              knowledgeList: transformKnowledgeList(item?.knowledges),
              name: item?.name,
              parentCode: chapter?.code,
            });
          });
        } else {
          // 兩層結構
          chaptersArray.push({
            code: chapter.code,
            desc: `[${chapter.code}] ${chapter.name}`,
            hasKnowledge: true,
            knowledgeList: transformKnowledgeList(chapter.knowledges),
            name: chapter.name,
            parentCode: null,
          });
        }
      });
      return {
        bookDesc: book?.volumn?.volumnName,
        bookID: book?.bookId,
        chapters: chaptersArray
      };
    });

    return nextData;
  };

  const [typeOptions, setTypeOptions] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [learnContentOptions, setLearnContentOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);
  const [knowledgeOptions, setKnowledgeOptions] = useState([]);

  const transformTabSelectOptions = (object) => {
    let array = [];
    for (const [key, value] of Object.entries(object)) {
      let subMap = [];
      value.forEach((item) => {
        subMap.push({
          name: item,
          value: item,
        });
      });
      array.push({
        name: key,
        value: key,
        subMap,
      });
    }
    return array;
  };

  const transformQuestions = (questions, books) => {
    if (!questions) return [];
    let questionArray = [];
    let learnContentArray = [];
    let difficultyArray = [];
    let knowledgeArray = [];
    let sourceObject = {};
    let quesTypeObject = {};

    questions?.forEach((ques) => {
      if (ques?.metaData?.learnContent?.name) {
        if (learnContentArray?.indexOf(ques?.metaData?.learnContent?.name) === -1) {
          learnContentArray?.push(ques?.metaData?.learnContent?.name);
        }
      }
      if (ques?.metaData?.difficulty?.name) {
        if (difficultyArray?.indexOf(ques?.metaData?.difficulty?.name) === -1) {
          difficultyArray?.push(ques?.metaData?.difficulty?.name);
        }
      }
      if (ques?.metaData?.knowledge?.name) {
        if (knowledgeArray?.indexOf(ques?.metaData?.knowledge?.name) === -1) {
          knowledgeArray?.push(ques?.metaData?.knowledge?.name);
        }
      }
      if (ques.metaData.questionType.name) {
        if (quesTypeObject[ques.questionGroup]) {
          if (quesTypeObject[ques.questionGroup].indexOf(ques.metaData.questionType.name) === -1) {
            quesTypeObject[ques.questionGroup].push(ques.metaData.questionType.name);
          }
        } else {
          quesTypeObject[ques.questionGroup] = [ques.metaData.questionType.name];
        }
      }
      if (ques?.metaData?.subSource?.name) {
        if (sourceObject[ques?.metaData?.source?.name]) {
          if (sourceObject[ques?.metaData?.source?.name]?.indexOf(ques?.metaData?.subSource?.name) === -1) {
            sourceObject[ques?.metaData?.source?.name]?.push(ques?.metaData?.subSource?.name);
          }
        } else {
          sourceObject[ques?.metaData?.source?.name] = [ques?.metaData?.subSource?.name];
        }
      }

      let chapter = {};
      books.forEach((book) => {
        book?.data.forEach((item) => {
          if (item?.data) {
            item?.data.forEach((item_2) => {
              item_2?.knowledges.forEach((item_3) => {
                if (item_3?.code === ques?.metaData?.knowledge?.code) chapter = { code: item_2?.code, name: item_2?.name };
              });
            });
          } else {
            item?.knowledges.forEach((item_2) => {
              if (item_2?.code === ques?.metaData?.knowledge?.code) chapter = { code: item?.code, name: item?.name };
            });
          }
        });
      });

      questionArray?.push({
        ...ques,
        chapter,
      });
    });

    setSourceOptions(transformTabSelectOptions(sourceObject));
    setTypeOptions(transformTabSelectOptions(quesTypeObject));

    setLearnContentOptions(learnContentArray?.map((type) => {
      return {
        value: type,
        name: type,
      };
    }));

    setDifficultyOptions(difficultyArray?.map((type) => {
      return {
        value: type,
        name: type,
      };
    }));

    setKnowledgeOptions(knowledgeArray?.map((item) => {
      return {
        name: item,
        value: item,
      };
    }));

    return questionArray;
  };

  const transformRadio = (array) => {
    if (!array) return [];
    return array?.map((item) => {
      return {
        name: item?.name,
        code: item?.value,
      };
    });
  };

  const goTop = () => {
    const main = document.getElementById('mainLayout');
    main.scrollTop = 0;
  };

  const onTabPickListChange = (key, value, innerKey) => {
    let nextData = { ...filterCondition };
    if (value.length === 0) {
      delete nextData[key][innerKey];
    } else {
      nextData = {
        ...nextData,
        [key]: { ...nextData[key], [innerKey]: value },
      };
    }
    setFilterCondition({
      ...nextData,
    });
  };

  const pickListChange = (key, value) => {
    setFilterCondition({
      ...filterCondition,
      [key]: value,
    });
  };
  const clearFilter = (code) => {
    if (code in filterCondition) {
      const nextData = filterCondition;

      delete nextData[code];
      setFilterCondition({ ...nextData });
    }
  };

  const onTabPickListDropdownClear = (key, value, innerKey) => {
    let nextData = { ...filterCondition };
    delete nextData[key];
    setFilterCondition({
      ...nextData
    });
  };

  const getParentChapter = (data) => {
    if (!data || data?.length <= 0) return [];
    return data?.filter(item => item?.parentCode === null);
  };
  const getChildChapter = (data) => {
    if (!data || data?.length <= 0) return [];
    return data?.filter(item => item?.parentCode !== null);
  };
  const onAllAreaClick = () => {
    setNowFilterChapter('all');
    let nextData = filterCondition;
    delete filterCondition.chapter;
    setFilterCondition({
      ...filterCondition,
      ...nextData,
    });
    setPageState({
      ...pageState,
      currentPage: 1,
    });
  };
  const onBookChapterClick = (data) => {
    const nextData = data.chapters;
    setFilterCondition({
      ...filterCondition,
      chapter: nextData
    });
    setNowFilterChapter(data.bookID);
    setPageState({
      ...pageState,
      currentPage: 1,
    });
  };
  const onParentChapterClick = (chapters, parentChapter) => {
    const conditionCode = parentChapter.code;
    let nextData = [];
    if (parentChapter.hasKnowledge) {
      nextData = [].concat(parentChapter);
    } else {
      nextData = getChildChapter(chapters).filter(child => child.parentCode === conditionCode );
    }

    setFilterCondition({
      ...filterCondition,
      chapter: nextData
    });
    setNowFilterChapter(parentChapter);
    setPageState({
      ...pageState,
      currentPage: 1,
    });
  };
  const onChildChapterClick = (data) => {
    let nextData = [data];
    setFilterCondition({
      ...filterCondition,
      chapter: nextData
    });
    setNowFilterChapter(data);
    setPageState({
      ...pageState,
      currentPage: 1,
    });
  };

  const formateDifficulty = (diff) => {
    let result = '';
    Object.entries(filterDifficulty).forEach(([key, value]) => {
      if (value.includes(diff)) {
        result = key;
      }
    });
    return result;
  };

  const selectedQuesForEditCopy = (Array) => {
    let nextData = { ...tableData };
    Array.forEach((id) => {
      Object.entries(nextData).forEach(([key, value]) => {
        const found = value.question.find(ele => ele.uid === id);
        if (found) {
          const newDiff = formateDifficulty(found.difficulty);
          if (nextData[found.quesTypeName]?.pickUpQuestions) {//已有題組
            nextData[found.quesTypeName].pickUpQuestions[newDiff].push(found);
            nextData[found.quesTypeName].inputValue = {
              ...nextData[found.quesTypeName].inputValue,
              answer: nextData[found.quesTypeName].inputValue.answer + found.answerAmount,
              difficulty: {
                ...nextData[found.quesTypeName].inputValue.difficulty,
                [newDiff]: nextData[found.quesTypeName].inputValue.difficulty[newDiff] + 1
              },
              question: nextData[found.quesTypeName].inputValue.question + 1,
              score: {
                ...nextData[found.quesTypeName].inputValue.score,
                allScore: nextData[found.quesTypeName].inputValue.score.perQues ? nextData[found.quesTypeName].inputValue.score.perQues * (nextData[found.quesTypeName].inputValue.question + 1) :
                  nextData[found.quesTypeName].inputValue.score.perAns * (nextData[found.quesTypeName].inputValue.answer + found.answerAmount)
              }
            };
          } else {
            const quesType = found.quesTypeName;
            //編輯時因為沒有第2步，沒有全部題組，取編輯前的題組
            const questionGroup = examPaperInfo?.questionGroup || examPaperData;
            let pickUpQuestions = {
              [DIFFICULTY[0]]: [],
              [DIFFICULTY[1]]: [],
              [DIFFICULTY[2]]: [],
            };
            let newQuestionGroup = {
              [quesType]: {
                ...questionGroup[quesType],
                pickUpQuestions,
                inputValue: defaultCountInputValue
              }
            };
            newQuestionGroup[quesType].pickUpQuestions[newDiff].push(found);
            newQuestionGroup[quesType].inputValue = {
              ...newQuestionGroup[quesType].inputValue,
              answer: parseInt(found?.answerAmount),
              difficulty: {
                ...newQuestionGroup[quesType].inputValue.difficulty,
                [newDiff]: 1
              },
              question: 1,
              score: {
                ...newQuestionGroup[quesType].inputValue.score,
                allScore: newQuestionGroup[quesType].inputValue.score.perQues ? newQuestionGroup[quesType].inputValue.score.perQues * 1 :
                  newQuestionGroup[quesType].inputValue.score.perAns * newQuestionGroup[quesType].inputValue.answer ? parseInt(newQuestionGroup[quesType].inputValue.answer) : 0
              }
            };
            nextData = {
              ...nextData,
              ...newQuestionGroup,
            };
          }
        }
      });
    });
    setTableData({
      ...nextData
    });
    setSelected(Array);
  };

  const onQuestionCheck = (e, id) => {
    let nextData = tableData;
    if (e.target.checked) {
      Object.entries(tableData).forEach(([key, value]) => {
        const found = value.question.find(ele => ele.uid === id);
        if (found) {
          const newDiff = formateDifficulty(found.difficulty);
          if (tableData[found.quesTypeName]?.pickUpQuestions) {//已有題組
            nextData[found.quesTypeName].pickUpQuestions[newDiff].push(found);
            nextData[found.quesTypeName].inputValue = {
              ...nextData[found.quesTypeName].inputValue,
              answer: nextData[found.quesTypeName].inputValue.answer + found.answerAmount,
              difficulty: {
                ...nextData[found.quesTypeName].inputValue.difficulty,
                [newDiff]: nextData[found.quesTypeName].inputValue.difficulty[newDiff] + 1
              },
              question: nextData[found.quesTypeName].inputValue.question + 1,
              score: {
                ...nextData[found.quesTypeName].inputValue.score,
                allScore: nextData[found.quesTypeName].inputValue.score.perQues ? nextData[found.quesTypeName].inputValue.score.perQues * (nextData[found.quesTypeName].inputValue.question + 1) :
                  nextData[found.quesTypeName].inputValue.score.perAns * (nextData[found.quesTypeName].inputValue.answer + found.answerAmount)
              }
            };
          } else {
            //編輯時因為沒有第2步，沒有全部題組，取編輯前的題組
            const questionGroup = examPaperInfo?.questionGroup || examPaperData;
            let pickUpQuestions = {
              [DIFFICULTY[0]]: [],
              [DIFFICULTY[1]]: [],
              [DIFFICULTY[2]]: [],
            };
            let newQuestionGroup = {
              [found.quesTypeName]: {
                ...questionGroup[found.quesTypeName],
                pickUpQuestions,
                inputValue: defaultCountInputValue
              }
            };
            newQuestionGroup[found.quesTypeName].pickUpQuestions[newDiff].push(found);
            newQuestionGroup[found.quesTypeName].inputValue = {
              ...newQuestionGroup[found.quesTypeName].inputValue,
              answer: parseInt(found.answerAmount),
              difficulty: {
                ...newQuestionGroup[found.quesTypeName].inputValue.difficulty,
                [newDiff]: 1
              },
              question: 1,
              score: {
                ...newQuestionGroup[found.quesTypeName].inputValue.score,
                allScore: newQuestionGroup[found.quesTypeName].inputValue.score.perQues ? newQuestionGroup[found.quesTypeName].inputValue.score.perQues * 1 :
                  newQuestionGroup[found.quesTypeName].inputValue.score.perAns * newQuestionGroup[found.quesTypeName].inputValue.answer ? parseInt(newQuestionGroup[found.quesTypeName].inputValue.answer) : 0
              }
            };
            nextData = {
              ...nextData,
              ...newQuestionGroup,
            };
          }
        }
      });
      setTableData({
        ...nextData
      });
    } else {
      Object.entries(tableData).forEach(([key, value]) => {
        const found = value.question.find(ele => ele.uid === id);
        if (found) {
          const newDiff = formateDifficulty(found.difficulty);
          if (Object.keys(tableData).includes(found.quesTypeName)) {//已有題組
            nextData[found.quesTypeName].pickUpQuestions[formateDifficulty(newDiff)].pop(found);
            let pickUpQuestionsLength = 0;
            Object.entries(nextData[found.quesTypeName].pickUpQuestions).forEach(([key, value]) => {
              pickUpQuestionsLength += value.length;
            });
            if (pickUpQuestionsLength === 0) {
              delete nextData[found.quesTypeName].pickUpQuestions;
            }
            nextData[found.quesTypeName].inputValue = {
              ...nextData[found.quesTypeName].inputValue,
              answer: nextData[found.quesTypeName].inputValue.answer - found.answerAmount,
              difficulty: {
                ...nextData[found.quesTypeName].inputValue.difficulty,
                [newDiff]: nextData[found.quesTypeName].inputValue.difficulty[newDiff] - 1
              },
              question: nextData[found.quesTypeName].inputValue.question - 1,
              score: {
                ...nextData[found.quesTypeName].inputValue.score,
                allScore: nextData[found.quesTypeName].inputValue.score.perQues ? nextData[found.quesTypeName].inputValue.score.perQues * (nextData[found.quesTypeName].inputValue.question - 1) :
                  nextData[found.quesTypeName].inputValue.score.perAns * (nextData[found.quesTypeName].inputValue.answer - found.answerAmount)
              }
            };
          }
        }
      });
      setTableData({
        ...nextData
      });
    }
  };
  const onSearch = (val) => {
    setFilterCondition({
      ...filterCondition,
      name: val
    });
  };

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
    goTop();
  };

  const onQuestionDisplayTypeChange = (value) => {
    setDisplayQuestionsType(value);
  };

  const findQuestionsFromDisplayType = (type, data, checkedList) => {
    let result = [];

    switch (type) {
      case 'notCheck':
        result = result.concat(data.filter(item => !checkedList.includes(item.uid)));
        break;
      case 'checked':
        result = result.concat(data.filter(item => checkedList.includes(item.uid)));
        break;
      case 'all':
      default:
        result = data;
        break;
    }
    return result;
  };

  const showQuestionDetail = (src) => {
    setModalImageSrc(src);
    setModalVisibleToggle();
  };

  const totalQuesTypeData = useMemo(() => {
    let nextData = {
      score: 0,
      questionCount: 0
    };
    if (!tableData) return nextData;

    let score = 0;
    let questionCount = 0;
    Object.entries(tableData).forEach(([key, value]) => {
      score += value.inputValue.score.allScore;
      questionCount += value.inputValue.question ? parseInt(value.inputValue.question) : 0;
    });
    nextData = {
      score,
      questionCount: questionCount
    };
    return nextData;
  }, [tableData]);

  const allQuestionID = useMemo(() => {
    if (!questions) return [];
    return questions.map(item => item.uid);
  }, [questions]);
  const pickUpQuestionID = useMemo(() => {
    if (!tableData) return [];
    let nextData = [];
    Object.entries(tableData).forEach(([key, value]) => {
      if (value?.pickUpQuestions)
      {
        Object.entries(value.pickUpQuestions).forEach(([quesKey, quesValue]) => {
          quesValue.forEach(item => {
            nextData = [...nextData, item.uid];
          });

        });
      }

    });
    return nextData;
  }, [tableData]);

  const { renderQuestionAnalysisModal, doShowAnalysisModal } = useQuestionAnalysisModal(pickUpQuestionID, searchParams);

  const {
    selected,
    setSelected,
    isSelected,
    toggle,
  } = useSelections(allQuestionID, pickUpQuestionID);

  const filterQuestionData = useMemo(() => {
    if (!questions) return [];
    const filterQuestions = findQuestionsFromDisplayType(displayQuestionsType, questions, selected);
    let nextData = filterQuestions
      ?.filter((ques) => {
        if (!filterCondition?.name) return true;
        return search(filterCondition?.name, ques?.content);
      })
      ?.filter((ques) => {
        if (!filterCondition['DIFFICULTY']) return true;
        return filterCondition['DIFFICULTY'] === ques?.metaData?.difficulty?.name;
      })
      ?.filter((ques) => {
        if (!filterCondition['LEARN_CONTENT']) return true;
        return filterCondition['LEARN_CONTENT'] === ques?.metaData?.learnContent?.name;
      })
      ?.filter((ques) => {
        if (!filterCondition['KNOWLEDGE']) return true;
        return filterCondition['KNOWLEDGE'] === ques?.metaData?.knowledge?.name;
      })
      ?.filter((ques) => {
        if (!filterCondition['QUES_TYPE'] || JSON?.stringify(filterCondition['QUES_TYPE']) === '{}') return true;
        return filterCondition['QUES_TYPE'][ques?.questionGroup] && filterCondition['QUES_TYPE'][ques?.questionGroup]?.includes(ques?.metaData?.questionType?.name);
      })
      ?.filter((ques) => {
        if (!filterCondition['SOURCE'] || JSON?.stringify(filterCondition['SOURCE']) === '{}') return true;
        return filterCondition['SOURCE'][ques?.metaData?.source?.name] && filterCondition['SOURCE'][ques?.metaData?.source?.name]?.includes(ques?.metaData?.subSource?.name);
      })
      ?.filter((ques) => {
        if (!filterCondition['chapter']) return true;
        return filterCondition['chapter']?.filter((chapter) => {
          // return chapter?.code === ques?.chapter?.code && chapter?.name === ques?.chapter?.name;
          return chapter?.knowledgeList && chapter?.knowledgeList?.length === 1
            ? chapter?.knowledgeList[0]?.itemCode === ques?.metaData?.knowledge?.code
            : chapter?.knowledgeList && chapter?.knowledgeList.filter((item) => {
              return item.itemCode === ques?.metaData?.knowledge?.code;
            }).length > 0;
        })?.length > 0;
      });

    return nextData;
  }, [filterCondition, questions, displayQuestionsType, selected]);

  const chapterCheckCountList = useMemo(() => {
    if (!selected || !questions) return [];
    // const haveChapterData = questions[0]?.CHAPTER;

    const chapterData = questions.filter(item => selected.includes(item.uid)).map(data => {
      return data.metaData.knowledge;
    });
    // const chapterData = questions.filter(item => selected.includes(item.uid)).map(data => {
    //   return data.metadata.filter(i => bookChapters.length === 0 ? i.code === 'TOPIC' : haveChapterData ? i.code === 'CHAPTER' : i.code === 'LESSON');
    // });
    // let nextData = [];
    // chapterData.forEach(item => {
    //   nextData = nextData.concat(item[0].content);
    // });
    // return nextData;
    return chapterData;
  }, [selected, questions, bookChapters]);

  const formateChapters = useMemo(() => {
    if (!bookChapters) return;
    let result = null;
    const parentChapter = bookChapters?.map(item => getParentChapter(item?.chapters));
    const childChapter = bookChapters?.map(item => getChildChapter(item?.chapters));

    if (childChapter?.filter(item => item?.length === 0)?.length > 0) {
      // 語文科，只有兩階層時。
      let nextData = parentChapter;
      chapterCheckCountList?.forEach(item => {
        nextData = nextData?.map(i => {
          return i?.map(data => {
            const checked = data?.knowledgeList && data?.knowledgeList.filter((knowledge) => {
              return knowledge?.itemCode === item?.code;
            });
            if (checked?.length > 0) {
              return {
                ...data,
                count: data?.count ? data?.count + 1 : 1
              };
            } else {
              return data;
            }
          });
        });
      });
      result = bookChapters?.map((item, index) => {
        let count = 0;
        nextData[index]?.map(data => {
          if (data?.count)count += data?.count;
        });
        return {
          ...item,
          count,
          chapters: [...nextData[index]]
        };
      });
    } else {
      // 非語文科目，有三層。
      // let nextChildData = childChapter;
      // chapterCheckCountList?.forEach(item => {
      //   nextChildData = nextChildData?.map(i => {
      //     return i?.map(data => {
      //       const checked = data?.knowledgeList && data?.knowledgeList.filter((knowledge) => {
      //         return knowledge?.itemCode === item?.code;
      //       });

      //       if (checked?.length > 0) {
      //         return {
      //           ...data,
      //           count: data?.count ? data?.count + 1 : 1
      //         };
      //       } else {
      //         return data;
      //       }
      //     });
      //   });
      // });

      // let nextParentData = parentChapter;
      // nextParentData = nextParentData?.map((i, index) => {

      //   return i?.map(item => {
      //     let count = 0;

      //     nextChildData[index]?.filter(child => child?.parentCode === item?.code)?.forEach(data => {
      //       if (data?.count)count = count + data?.count;

      //     });
      //     return {
      //       ...item,
      //       count
      //     };
      //   });
      // });

      // result = bookChapters?.map((item, index) => {
      //   let count = 0;
      //   nextParentData[index]?.forEach(data => {
      //     if (data?.count)count += data?.count;
      //   });
      //   return {
      //     ...item,
      //     count,
      //     chapters: [...nextParentData[index], ...nextChildData[index]]
      //   };
      // });

      result = [...bookChapters];

      chapterCheckCountList.forEach((item) => {
        bookChapters.forEach((item_2, index) => {
          item_2?.chapters?.forEach((item_3, index_2) => {
            const checked = item_3?.knowledgeList?.filter((item_4) => {
              return item_4?.itemCode === item?.code;
            });
            if (checked?.length > 0) {
              const newArray = [...result[index]['chapters']];
              newArray[index_2] = {
                ...newArray[index_2],
                count: newArray[index_2]?.count ? newArray[index_2]?.count + 1 : 1,
              };
              result[index] = {
                ...result[index],
                count: result[index]?.count ? result[index]?.count + 1 : 1,
                chapters: newArray,
              };

              if (new RegExp(/-/g).test(item_3?.code)) {
                result[index].chapters = result[index]?.chapters.map((chapter) => {
                  if (chapter?.code === item_3?.code?.split('-')[0]) {
                    return {
                      ...chapter,
                      count: chapter?.count ? chapter?.count + 1 : 1,
                    };
                  } else {
                    return chapter;
                  }
                });
              }
            }
          });
        });
      });
    }
    return result;
  }, [chapterCheckCountList, bookChapters]);

  const chapterTotalCount = useMemo(() => {
    if (!formateChapters) return;
    let result = 0;
    formateChapters?.forEach(item => {
      result += (item?.count ? item?.count : 0);
    });
    return result;
  }, [formateChapters]);

  useEffect(() => {
    if (isEditCopy) {
      if (!reCreateSearchParams) return;
      sendPostGetQuestion(FETCH_DATA_EVENT.FETCH, {
        payload: {
          eduSubject: reCreateSearchParams?.eduSubject,
          books: reCreateSearchParams?.bookIDs,
          knowledges: reCreateSearchParams?.knowledges,
          sources: reCreateSearchParams?.sources,
          curriculum: reCreateSearchParams?.curriculum,
          year: reCreateSearchParams?.year,
          version: reCreateSearchParams?.version,
          questionTypes: reCreateSearchParams?.questionTypes,
        }
      });
    } else {
      if (!searchParams) return;
      sendPostGetQuestion(FETCH_DATA_EVENT.FETCH, {
        payload: searchParams
      });
    }
  }, [searchParams, reCreateSearchParams]);

  useEffect(() => {
    getTableData(tableData);
    getSaveData('tableData', tableData);
    const haveDataKey = Object.entries(tableData).filter(([key, value]) => value.pickUpQuestions).map(item => item[0]);
    let nextData = [];
    if (prevKeyList.length < haveDataKey.length) {//4->3
      const newKey = haveDataKey.filter(item => !prevKeyList.includes(item));
      nextData = prevKeyList.concat(newKey);
    } else {
      nextData = prevKeyList.filter(item => haveDataKey.includes(item));
    }
    getKeyList(nextData);
    setKeyList(nextData);
  }, [tableData]);

  useEffect(() => {
    getTotalQuesTypeData(totalQuesTypeData);
  }, [totalQuesTypeData]);

  useEffect(() => {
    if (!questions) return;
    getQuestions(questions);
  }, [questions]);

  // ---- 動作 - 問題回報  ----

  // 問題回報彈窗 顯示控制
  const [reportModalVisible, { toggle: setReportModalToggle, setFalse: setReportModalFalse }] = useBoolean(false);

  const showReportModal = (itemUID) => {
    setReportModalData({
      ...reportModalData,
      education: memberEduSubject.edu,
      subject: memberEduSubject.subject,
      uid: itemUID,
    });
    if (reportModalVisible === false) sendGetReportModalSelectOptionsState(FETCH_DATA_EVENT.FETCH);
    setReportModalToggle();
  };
  //  試題回報內容
  const [reportModalData, setReportModalData] = useState({
    education: '',
    subject: '',
    uid: '',
    errorType: '',
    description: '',
  });
  // 取得錯題回報相關選單
  const [getReportModalSelectOptionsState, sendGetReportModalSelectOptionsState] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context) => {
        const res = await getAnomalySelectOption();
        const { data, isSuccess, systemCode, message } = res;
        return {
          reportModalSelectOptions: setSelectOptions(data) || {}
          , isSuccess, systemCode, message
        };
      },
    },
  });
  const { reportModalSelectOptions } = getReportModalSelectOptionsState.context.result || {};
  // 更改回報類型
  const onSelectChange = (value) => {
    setReportModalData({
      ...reportModalData,
      errorType: value,
    });
  };
  // 更改回報內容
  const onTextAreaChange = (e) => {
    setReportModalData({
      ...reportModalData,
      description: e.target.value,
    });
  };

  // 試題回報API
  const [steatePostQuestionReport, sendPostQuestionReport] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postQuestionReport(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', '回報成功');
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  // 確定回報
  const doReport = () => {
    sendPostQuestionReport(FETCH_DATA_EVENT.FETCH, {
      payload: {
        questionUID: reportModalData.uid,
        errorType: reportModalData.errorType,
        description: reportModalData.description,
        eduSubject: reportModalData.education + reportModalData.subject,
      }
    });
    setReportModalToggle();
  };
  // 關閉彈窗時 清除彈窗內資料
  useEffect(() => {
    if (reportModalVisible === false) {
      setReportModalData({
        education: '',
        subject: '',
        uid: '',
        errorType: '',
        description: '',
      });
    }
  }, [reportModalVisible]);

  /// ---- 手機版 相關 ----

  const [questionDetailDrawerVisible, { toggle: questionDetailDrawerToggle, setFalse: questionDetailDrawerFalse }] = useBoolean(false);
  const [setupDrawerVisible, { toggle: setupDrawerToggle, setFalse: setupDrawerFalse }] = useBoolean(false);
  const [filterDrawerVisible, { toggle: filterDrawerToggle, setFalse: filterDrawerFalse }] = useBoolean(false);
  const [reportDrawerVisible, { toggle: reportDrawerToggle, setFalse: reportDrawerFalse }] = useBoolean(false);
  const [filterSelectDrawerVisible, { toggle: filterSelectDrawerToggle, setFalse: filterSelectDrawerFalse }] = useBoolean(false);

  const [filterConditionMobile, setFilterConditionMobile] = useState({});

  const [filterSelectedTitle_QUES_TYPE, setFilterSelectedTitle_QUES_TYPE] = useState('');
  const [filterSelectedTitle_SOURCE, setFilterSelectedTitle_SOURCE] = useState('');

  useEffect(() => {
    if (!filterConditionMobile) return null;
    if (filterConditionMobile['QUES_TYPE']) {
      if (JSON.stringify(filterConditionMobile['QUES_TYPE']) !== '{}') {
        let selectArray = [];
        Object.values(filterConditionMobile['QUES_TYPE']).forEach((items) => {
          items.forEach((item) => {
            selectArray.push(item);
          });
        });
        let titleArray = [];
        transformTypeArray(typeOptions).forEach((type) => {
          if (selectArray.indexOf(type.value) !== -1) titleArray.push(type.name);
        });
        setFilterSelectedTitle_QUES_TYPE(titleArray);
      } else {
        setFilterSelectedTitle_QUES_TYPE([]);
      }
    } else {
      setFilterSelectedTitle_QUES_TYPE([]);
    }
    if (filterConditionMobile['SOURCE']) {
      if (JSON.stringify(filterConditionMobile['SOURCE']) !== '{}') {
        let selectArray = [];
        Object.values(filterConditionMobile['SOURCE']).forEach((items) => {
          items.forEach((item) => {
            selectArray.push(item);
          });
        });
        let titleArray = [];
        transformTypeArray(sourceOptions).forEach((type) => {
          if (selectArray.indexOf(type.value) !== -1) titleArray.push(type.name);
        });
        setFilterSelectedTitle_SOURCE(titleArray);
      } else {
        setFilterSelectedTitle_SOURCE([]);
      }
    } else {
      setFilterSelectedTitle_SOURCE([]);
    }
  }, [filterConditionMobile]);

  const [checkedFilterChangeBtn, setCheckedFilterChangeBtn] = useState(false);

  // 錯誤回報 抽屜
  const showReportDrawer = (uid) => {
    setReportModalData({
      ...reportModalData,
      education: memberEduSubject?.edu,
      subject: memberEduSubject?.subject,
      uid,
    });
    if (reportModalVisible === false) sendGetReportModalSelectOptionsState(FETCH_DATA_EVENT.FETCH);
    reportDrawerToggle();
  };

  const onReportTypeChange = e => {
    setReportModalData({
      ...reportModalData,
      errorType: e.target.value,
    });
  };

  const doMobileReport = () => {
    sendPostQuestionReport(FETCH_DATA_EVENT.FETCH, {
      payload: {
        questionUID: reportModalData.uid,
        errorType: reportModalData.errorType,
        description: reportModalData.description,
        eduSubject: reportModalData.education + reportModalData.subject,
      }
    });
    reportDrawerFalse();
  };

  // 關閉彈窗時 清除彈窗內資料
  useEffect(() => {
    if (reportDrawerVisible === false) {
      setReportModalData({
        education: '',
        subject: '',
        uid: '',
        errorType: '',
        description: '',
      });
    }
  }, [reportDrawerVisible]);

  // ---- 單一題目詳情頁面 -----
  const [questionDetailData, setQuestionDetailData] = useState({});
  const [detailIndex, setDetailIndex] = useState(0);

  // 上一題 下一題 控制
  const preQues = () => {
    setDetailIndex(detailIndex - 1);
    setQuestionDetailData(filterQuestionData[detailIndex - 1]);
  };

  const nextQues = () => {
    setDetailIndex(detailIndex + 1);
    setQuestionDetailData(filterQuestionData[detailIndex + 1]);
  };

  const isShowPreQuesBtn = detailIndex === 0 ? false : true;
  const isShowNextQuesBtn = detailIndex === (filterQuestionData.length - 1) ? false : true;

  // 關閉 背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (setupDrawerVisible | questionDetailDrawerVisible | filterDrawerVisible | reportModalVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [setupDrawerVisible, questionDetailDrawerVisible, filterDrawerVisible, reportModalVisible]);

  const mobileShowQuestionDetail = (item, index) => {
    setQuestionDetailData(item);
    setDetailIndex(index);
    questionDetailDrawerToggle();
  };

  //  判斷左右滑動事件
  const { onTouchStart, onTouchEnd } = useTouch({
    leftToRight: () => {
      if (detailIndex !== 0) setTimeout(preQues(), 100);
    },
    rightToLeft: () => {
      if (detailIndex !== (filterQuestionData.length - 1)) setTimeout(nextQues(), 100);
    },
    XGap: 50,
  });

  // ---- 題數與配分 抽屜 ----
  const [setupDrawerData, setSetupDrawerData] = useState(tableData);

  const totalSetupQuesTypeData = useMemo(() => {
    let nextData = {
      score: 0,
      questionCount: 0
    };
    if (!setupDrawerData) return nextData;

    let score = 0;
    let questionCount = 0;
    Object.entries(setupDrawerData).forEach(([key, value]) => {
      score += value.inputValue.score.allScore;
      questionCount += value.inputValue.question ? parseInt(value.inputValue.question) : 0;
    });
    nextData = {
      score,
      questionCount: questionCount
    };
    return nextData;
  }, [setupDrawerData]);

  useEffect(() => {
    if (setupDrawerVisible) setSetupDrawerData(tableData);
  }, [setupDrawerVisible]);

  // 篩選頁面
  const doShowFilterDrawer = () => {
    filterDrawerToggle();
    setFilterConditionMobile(filterCondition);
    // eslint-disable-next-line no-prototype-builtins
    if (filterCondition.hasOwnProperty('chapter')) {
      setCheckboxTreeData(objectArrayToJson(filterCondition['chapter']));
    } else {
      setCheckboxTreeData([]);
    }
    setDisplayQuestionsTypeMobile(displayQuestionsType);
    setCheckedFilterChangeBtn(false);
  };

  const clearAllFilter = () => {
    if (Object.keys(filterConditionMobile).length > 1 | displayQuestionsTypeMobile !== 'all') {
      setFilterConditionMobile({
        name: '',
      });
      setCheckboxTreeData([]);
      setDisplayQuestionsTypeMobile('all');
      setCheckedFilterChangeBtn(true);
    }
  };

  const [mobileFilterDrawerType, setMobileFilterDrawerType] = useState('');

  const activeClearBtn = useMemo(() => {
    if (filterConditionMobile) {
      switch (mobileFilterDrawerType) {
        case 'QUES_TYPE' :
          if (JSON.stringify(filterConditionMobile[mobileFilterDrawerType]) !== '{}' && filterConditionMobile[mobileFilterDrawerType] !== undefined) {
            return true;
          } else {
            return false;
          }
        case 'SOURCE' :
          if (JSON.stringify(filterConditionMobile[mobileFilterDrawerType]) !== '{}' && filterConditionMobile[mobileFilterDrawerType] !== undefined) {
            return true;
          } else {
            return false;
          }
        case 'chapter' :
          if (filterConditionMobile[mobileFilterDrawerType]?.length > 0) {
            return true;
          } else {
            return false;
          }
        default :
          if (filterConditionMobile[mobileFilterDrawerType]) {
            return true;
          } else {
            return false;
          }
      }
    }
  }, [filterConditionMobile, mobileFilterDrawerType]);

  const mobileFilterDrawerTypeTitle = useMemo(() => {
    switch (mobileFilterDrawerType) {
      case 'QUES_TYPE' :
        return <div>題型<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('QUES_TYPE')}>清除</div></div>;
      case 'SOURCE' :
        return <div>出處<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('SOURCE')}>清除</div></div>;
      case 'DIFFICULTY' :
        return <div>難易度<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('DIFFICULTY')}>清除</div></div>;
      case 'chapter' :
        return <div>範圍<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => {deleteFilterKeyMobile('chapter'); setCheckboxTreeData([]);}}>清除</div></div>;
      case 'KNOWLEDGE' :
        return <div>知識向度<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('KNOWLEDGE')}>清除</div></div>;
      case 'LEARN_CONTENT' :
        return <div>學習內容<div className={classNames('clearKeyBtn', activeClearBtn ? 'active' : '')} onClick={() => deleteFilterKeyMobile('LEARN_CONTENT')}>清除</div></div>;
      default :
        return '';
    }
  }, [mobileFilterDrawerType, filterConditionMobile]);

  const showFilterSelectDrawer = (type) => {
    setMobileFilterDrawerType(type);
    filterSelectDrawerToggle();
  };

  const onTreeChange = ({ valueArray, type }) => {
    if ( valueArray.length > 0) {
      const nextData = {
        ...filterConditionMobile,
        [mobileFilterDrawerType]: {
          ...filterConditionMobile[mobileFilterDrawerType],
          [type]: valueArray
        }
      };
      setFilterConditionMobile(nextData);
    } else {
      if (mobileFilterDrawerType in filterConditionMobile) {
        if (type in filterConditionMobile[mobileFilterDrawerType]) {
          let nextData = filterConditionMobile;
          delete nextData[mobileFilterDrawerType][type];
          setFilterConditionMobile({ ...nextData });
        }
      }
    }
    setCheckedFilterChangeBtn(true);
  };

  const transformToTreeData = (Array) => {
    if (!Array) return [];
    return Array.map((quesTypeArray) => {
      return {
        label: quesTypeArray.name,
        value: quesTypeArray.value,
        children: quesTypeArray.subMap.map((item) => {
          return {
            label: item.name,
            value: item.value,
          };
        })
      };
    });
  };

  const [displayQuestionsTypeMobile, setDisplayQuestionsTypeMobile] = useState('');


  const onQuestionDisplayTypeMobileChange = (val) => {
    setDisplayQuestionsTypeMobile(val);
    setCheckedFilterChangeBtn(true);
  };

  const reCheckedList = (type, subType) => {
    // eslint-disable-next-line no-prototype-builtins
    if (filterConditionMobile.hasOwnProperty(type) && filterConditionMobile[type].hasOwnProperty(subType)) {
      return filterConditionMobile[type][subType];
    } else {
      return [];
    }
  };

  const checkboxTreeOptions = useMemo(() => {
    if (bookChapters) {
      const haveChapterData = bookChapters[0]?.chapters[bookChapters[0]?.chapters.length - 1]?.parentCode;
      return bookChapters.map((firItem) => {
        let parentChapters = [];
        let childrenChapters = [];
        const makeChildren = () => {
          firItem.chapters.forEach((item) => {

            if ( item.parentCode === null ) {
              parentChapters.push({
                ...item,
                value: JSON.stringify(item),
              });
            } else {
              childrenChapters.push({
                ...item,
                label: item.desc,
                value: JSON.stringify(item),
              });
            }
          });

          return parentChapters.map((parentItem) => {
            if (haveChapterData) {
              return ({
                ...parentItem,
                label: `第${sectionToChinese(parseInt(parentItem.code))}章_${parentItem.name}`,
                children: childrenChapters.filter((childrenItem) =>
                  childrenItem.parentCode === parentItem.code
                )
              });
            } else {
              return ({
                ...parentItem,
                label: `第${sectionToChinese(parseInt(parentItem.code))}課_${parentItem.name}`,
                children: childrenChapters.filter((childrenItem) =>
                  childrenItem.parentCode === parentItem.code
                )
              });
            }
          });
        };
        return {
          ...firItem,
          label: firItem.bookDesc,
          value: firItem.bookID,
          children: makeChildren()
        };
      });
    }
  }, [bookChapters]);

  const [checkboxTreeData, setCheckboxTreeData] = useState([]);

  const jsonArrayToObject = (array) => {
    return array.map((item) => {
      return JSON.parse(item);
    });
  };

  const objectArrayToJson = (array) => {
    return array.map((item) => {
      return JSON.stringify(item);
    });
  };

  const [mobileFilterChapterTitle, setMobileFilterChapterTitle] = useState('');

  const onCheckboxTreeChange = ({ callBackArray, name }) => {
    setCheckboxTreeData(callBackArray);
    setFilterConditionMobile({
      ...filterConditionMobile,
      chapter: jsonArrayToObject(callBackArray)
    });
    setMobileFilterChapterTitle(name);
    setCheckedFilterChangeBtn(true);
  };

  const onRadioChange = (type, e) => {
    setFilterConditionMobile({
      ...filterConditionMobile,
      [type]: e.target.value,
    });
    setCheckedFilterChangeBtn(true);
  };

  const deleteFilterKeyMobile = (string) => {
    let data = { ...filterConditionMobile };
    delete data[string];
    setFilterConditionMobile(data);
    setCheckedFilterChangeBtn(true);
  };

  const doneFilterDrawerChange = () => {
    setFilterCondition(filterConditionMobile);
    setDisplayQuestionsType(displayQuestionsTypeMobile);
    filterDrawerFalse();
    setPageState({
      ...pageState,
      currentPage: 1,
    });
  };

  const transformTypeArray = (data) => {
    // if (!filter | !type) return '';
    if (!data) return [];
    let nameArray = [];
    data.forEach((typeItem) => {
      typeItem.subMap.forEach((item) => {
        nameArray.push(item);
      });
    });
    return nameArray;
  };

  // 篩選內容顯示 長度判斷 超過就換成...
  const filterSelectedTitleArray = (filterArray) => {
    if (clientWidth) {
      let useWidth = 0;
      let joinString = filterArray.join('、');

      const filterTitleBoxWidth = clientWidth - 200;

      const chineseString = new RegExp('[\u4E00-\u9FA5]+');
      const englishString = new RegExp('[A-Za-z]+');
      const numberString = new RegExp('[0-9]+');
      let showString = '';
      const stringLength = joinString.length;
      let index = 0;
      let addText = false;
      for (let i = 0 ; i <= stringLength ; i++ ) {
        index = i;
        if (useWidth < filterTitleBoxWidth) {
          if (chineseString.test(joinString.charAt(i))) {
            useWidth += 14;
          } else if (englishString.test(joinString.charAt(i))) {
            useWidth += 6.5;
          } else if (numberString.test(joinString.charAt(i))) {
            useWidth += 6.5;
          } else {
            useWidth += 14;
          }
        } else {
          addText = true;
          break;
        }
      }
      showString = joinString.slice(0, index);
      if (addText) showString += '...';
      return showString;
    }
  };

  const filterSelectedTitle = (string) => {
    if (clientWidth) {
      let useWidth = 0;

      const filterTitleBoxWidth = clientWidth - 200;

      const chineseString = new RegExp('[\u4E00-\u9FA5]+');
      const englishString = new RegExp('[A-Za-z]+');
      const numberString = new RegExp('[0-9]+');

      let showString = '';
      const stringLength = string.length;
      let index = 0;
      let addText = false;
      for (let i = 0 ; i <= stringLength ; i++ ) {
        index = i;
        if (useWidth < filterTitleBoxWidth) {
          if (chineseString.test(string.charAt(i))) {
            useWidth += 14;
          } else if (englishString.test(string.charAt(i))) {
            useWidth += 6.5;
          } else if (numberString.test(string.charAt(i))) {
            useWidth += 6.5;
          } else {
            useWidth += 14;
          }
        } else {
          addText = true;
          break;
        }
      }
      showString = string.slice(0, index);
      if (addText) showString += '...';
      return showString;
    }
  };

  // 動態新增 題型與配分Btn 到 header 上面
  const addSetupBtnClickEvent = () => {
    const setupBtn = document.getElementById('setupBtn');
    if (setupBtn) {
      setupBtn.addEventListener('click', function() {
        setupDrawerToggle();
      });
    }
  };

  // 難易度 前端隱藏顯示
  const difficultyHide = (item) => {
    if (item.code === 'ADVANCED' | item.code === 'BASIC') {
      return true;
    } else {
      return false;
    }
  };

  // 確認 是否有任何篩選條件
  const checkedFilter = useMemo(() => {
    let answer = false;
    if (filterCondition['QUES_TYPE'] || filterCondition['SOURCE'] || filterCondition['DIFFICULTY'] || filterCondition['KNOWLEDGE'] || filterCondition['LEARN_CONTENT'] || filterCondition['chapter']) {
      answer = true;
    }
    return answer;
  }, [filterCondition]);

  const clientWidth = useMemo(() => {
    return document.body.clientWidth;
  }, []);

  useEffect(() => {
    addSetupBtnClickEvent();
    goTop();
  }, []);

  return (
    isLoading ? renderLoadingContent()
      :
      <StyledMpQuestionSelectContent className={className} data-testid="MpQuestionSelectContent">
        {
          deviceData.isDesktop ?
            <>
              <Box mb={5}>
                <div className="pageTitleBox">
                  <div>
                    <div className="pageTitle">試題選擇</div>
                    <Select allowClear={false} options={questionDisplayOptions} value={displayQuestionsType} onChange={onQuestionDisplayTypeChange} />
                  </div>
                  {
                    pickUpQuestionID.length > 0 && renderQuestionAnalysisModal()
                  }
                </div>
              </Box>
              <Box mb={4}>
                <StyledFilterBar>
                  <Input.Search placeholder="搜尋試題內容" onSearch={onSearch} />
                  <PickListDropdown.TabPickListDropdown
                    key={'題型'}
                    defaultTitle={'題型'}
                    options={typeOptions || []}
                    onChange={(val, innerKey) => {
                      onTabPickListChange('QUES_TYPE', val, innerKey);
                    }}
                    onCheckAll={(val, innerKey) => {
                      onTabPickListChange('QUES_TYPE', val, innerKey);
                    }}
                    onClear={(val, innerKey) => {
                      onTabPickListDropdownClear('QUES_TYPE', val, innerKey);
                    }}
                    checkedList={filterCondition['QUES_TYPE'] || []}
                  />
                  <PickListDropdown.TabPickListDropdown
                    key={'出處'}
                    defaultTitle={'出處'}
                    options={sourceOptions || []}
                    onChange={(val, innerKey) => {
                      onTabPickListChange('SOURCE', val, innerKey);
                    }}
                    onCheckAll={(val, innerKey) => {
                      onTabPickListChange('SOURCE', val, innerKey);
                    }}
                    onClear={(val, innerKey) => {
                      onTabPickListDropdownClear('SOURCE', val, innerKey);
                    }}
                    checkedList={filterCondition['SOURCE'] || []}
                  />
                  <PickListDropdown.SinglePickListDropdown
                    key={'知識向度'}
                    defaultTitle={'知識向度'}
                    clearable={true}
                    options={knowledgeOptions || []}
                    onClick={(value) => pickListChange('KNOWLEDGE', value)}
                    onPickListClear={() => {
                      clearFilter('KNOWLEDGE');
                    }}
                    value={filterCondition['KNOWLEDGE'] || null}
                  />
                  <PickListDropdown.SinglePickListDropdown
                    key={'學習內容'}
                    defaultTitle={'學習內容'}
                    clearable={true}
                    options={learnContentOptions || []}
                    onClick={(value) => pickListChange('LEARN_CONTENT', value)}
                    onPickListClear={() => {
                      clearFilter('LEARN_CONTENT');
                    }}
                    value={filterCondition['LEARN_CONTENT'] || null}
                  />
                  <PickListDropdown.SinglePickListDropdown
                    key={'難易度'}
                    defaultTitle={'難易度'}
                    clearable={true}
                    options={difficultyOptions || []}
                    onClick={(value) => pickListChange('DIFFICULTY', value)}
                    onPickListClear={() => {
                      clearFilter('DIFFICULTY');
                    }}
                    value={filterCondition['DIFFICULTY'] || null}
                  />
                </StyledFilterBar>
              </Box>
              <Box>
                <div className="areaSelectTag" onClick={() => {setFilterChapterVisibleToggle();}}><ListAltSolid className="prevSvg" />
                  {filterChapterVisible ?
                    <div>
                    關閉範圍篩選選單<ChevronLeftOutline className="nextSvg" />
                    </div>
                    :
                    <div >
                    顯示範圍篩選選單<ChevronRightOutline className="nextSvg" />
                    </div>
                  }
                </div>
                <div className="tableZone">
                  {
                    filterChapterVisible &&
                    <StyledAreaSelectContainer className="selectedBox">
                      <div className="areaSelectHeader">
                        <div>範圍</div>
                        <div>已選題數</div>
                      </div>
                      <div className="areaSelectContent">
                        <div className="areaSelectContentTitle">
                          <StyledAreaSelectRow active={nowFilterChapter === 'all'} onClick={() => {onAllAreaClick();}}>
                            <div>所有範圍</div>
                            <div className="count count1">{chapterTotalCount}</div>
                          </StyledAreaSelectRow>
                        </div>
                        {
                          formateChapters && formateChapters.map((item, index) => {
                            return (
                              <StyledAreaSelectList key={item.bookID} onClick={(e) => {e.stopPropagation(); onBookChapterClick(item);}}>
                                <StyledAreaSelectRow className="bookRow" active={nowFilterChapter === item.bookID}>
                                  <div>
                                    <BookSolid1 />
                                    <div>{saveData?.bookOptions
                                      ? `${saveData?.bookOptions.find(book => book.value === item.bookID.substr(-3))
                                        ? saveData?.bookOptions.find(book => book.value === item.bookID.substr(-3)).name
                                        : ''}`
                                      : `第${sectionToChinese((index + 1))}冊`}
                                    </div>
                                  </div>
                                  <div className="count count2">{item.count || 0}</div>
                                </StyledAreaSelectRow>
                                {
                                  item.chapters && item.chapters.length > 0 &&
                                getParentChapter(item.chapters).map((parentChapter) => {
                                  return (
                                    <StyledAreaSelectList key={parentChapter.desc} onClick={(e) => { e.stopPropagation(); onParentChapterClick(item.chapters, parentChapter );}}>
                                      <StyledAreaSelectRow active={nowFilterChapter?.code === parentChapter?.code && nowFilterChapter?.desc === parentChapter?.desc}>
                                        <div>{`第${sectionToChinese(parseInt(parentChapter.code))}${LANGUAGE_LIST.includes(memberEduSubject?.subject) ? '課' : '章'}_${parentChapter.name}`}</div>
                                        <div className="count count3">{parentChapter.count || 0}</div>
                                      </StyledAreaSelectRow>
                                      {
                                        item.chapters && item.chapters.length > 0 &&
                                        getChildChapter(item.chapters).filter(child => child?.parentCode === parentChapter?.code ).map((childChapter) => {
                                          return (
                                            <StyledAreaSelectList key={childChapter.desc} onClick={(e) => { e.stopPropagation(); onChildChapterClick(childChapter);}}>
                                              <StyledAreaSelectRow active={nowFilterChapter?.code === childChapter?.code && nowFilterChapter?.desc === childChapter?.desc}>
                                                <div>{childChapter.desc}</div>
                                                <div className="count count4">{childChapter.count || 0}</div>
                                              </StyledAreaSelectRow>
                                            </StyledAreaSelectList>
                                          );
                                        })
                                      }

                                    </StyledAreaSelectList>
                                  );
                                })
                                }

                              </StyledAreaSelectList>
                            );
                          })
                        }
                      </div>
                    </StyledAreaSelectContainer>
                  }

                  <div className="tableContent">
                    <Table ref={tableRef}>
                      <Header>
                        <Row>
                          <Item flex="0.5" textalign='center'>選題</Item>
                          <Item flex={4.3}>題目內容</Item>
                          <Item flex="0.5">標籤</Item>
                          <Item flex={2}>題目資訊</Item>
                          <Item flex={0.7}>動作</Item>
                        </Row>
                      </Header>
                      <Body className="questionTableBody">
                        {
                          filterQuestionData &&
                      filterQuestionData.length > 0 ?
                            sliceTableData(filterQuestionData, pageState.currentPage, pageState.limit).map((item, index) => {
                              return (
                                <Row key={index} disabled={item.isImport}>
                                  <Item flex="0.5" textalign="center"><Checkbox disabled={item.isImport} checked={isSelected(item.uid)} onClick={(e) => {toggle(item.uid); onQuestionCheck(e, item.uid);} }/></Item>
                                  <Item className="questionItem" flex={4.3}>
                                    <div className="questionContent">
                                      {
                                        item.content ? <div className="questionContentText">{item.content}</div> : <img className="questionImage" src={item.image} alt='img'/>
                                      }
                                      <div className="contentDetail" onClick={() => {showQuestionDetail(item.image);}}><SearchPlusSolid/></div>
                                    </div>
                                  </Item>
                                  <Item flex="0.5"><DifferentTag mr={1} variant={diffStr[item.metaData.difficulty.code]} /></Item>
                                  <Item flex={2}>
                                    <div className="metaBlock">
                                      {
                                        item?.questionGroup &&
                                        <div className="meta">
                                          作答方式:
                                          <span className="metaContent">
                                            {item?.questionGroup}
                                          </span>
                                        </div>
                                      }
                                      {
                                        item?.metaData?.questionType?.name &&
                                        <div className="meta">
                                          題型:
                                          <span className="metaContent">
                                            {item?.metaData?.questionType?.name}
                                          </span>
                                        </div>
                                      }
                                      {
                                        item?.metaData?.source?.name &&
                                        <div className="meta">
                                          出處:
                                          <span className="metaContent">
                                            {item?.metaData?.source?.name}
                                          </span>
                                        </div>
                                      }
                                      {
                                        item?.metaData?.learnContent?.name &&
                                        <div className="meta">
                                          學習內容:
                                          <span className="metaContent">
                                            {item?.metaData?.learnContent?.name}
                                          </span>
                                        </div>
                                      }
                                      {
                                        item?.metaData?.knowledge?.name &&
                                        <div className="meta">
                                          知識向度:
                                          <span className="metaContent">
                                            {item?.metaData?.knowledge?.name}
                                          </span>
                                        </div>
                                      }
                                    </div>
                                  </Item>
                                  <Item className='controlBox' flex={0.7}>
                                    {
                                      env === 'dev' &&
                                        <StarSolid/>
                                    }
                                    <ReturnSolid onClick={() => {showReportModal(item.uid);}}/>
                                  </Item>
                                </Row>
                              );
                            }) : <div className="emptyMsg">沒有符合您搜尋的項目</div>
                        }
                      </Body>
                    </Table>
                  </div>
                </div>
                <div className="pageBlock">
                  <Pagination
                    total={filterQuestionData?.length || 0}
                    defaultCurrent={1}
                    current={pageState.currentPage}
                    onChange={pageChange}
                    defaultPageSize={pageState.limit}
                  />
                </div>
              </Box>
              {
                modalVisible &&
                <div className="questionImageModal">
                  <div className="questionImageModalContent">
                    <div className="questionImageModalClose" onClick={() => {setModalVisibleFalse();}}><XmarkOutline/></div>
                    <div className="questionImageModalContentImage">
                      <img src={modalImageSrc} alt="img" />
                    </div>
                  </div>
                </div>
              }
              <Modal
                visible={reportModalVisible}
                zIndex={1001}
                onCancel={setReportModalFalse}
                title={'錯誤回報'}
                width="576px"
                footer={
                  <>
                    <Button className='reportModalBtn' variant='ui01' onClick={setReportModalFalse}>取消</Button>
                    <Button className='reportModalBtn' variant={!reportModalData.errorType || !reportModalData.description ? 'ui02' : 'brand2'}
                      disabled={!reportModalData.errorType || !reportModalData.description}
                      onClick={doReport}>確定</Button>
                  </>
                }
              >
                <StyleReportModalContent>
                  <div>
                    <div>原因</div>
                    <Select className='select' options={reportModalSelectOptions}
                      value={reportModalSelectOptions?.value} onChange={onSelectChange}
                      allowClear={false} placeholder={'請選擇'}>
                    </Select>
                  </div>
                  <div>
                    <div>說明</div>
                    <Input.TextArea className='textArea' autoSize={true}
                      onChange={onTextAreaChange} placeholder={'必填'}></Input.TextArea>
                  </div>
                </StyleReportModalContent>
              </Modal>
            </>
            :
            //// ------ 手機版 -------
            <>
              <Box mb={2} className={'filterBox'}>
                <div>
                  所選試題範圍
                </div>
                <div className={checkedFilter ? 'selectedFilter' : ''} onClick={doShowFilterDrawer}>
                  <img src={checkedFilter ? '/assets/slidersCheckSolid.svg' : '/assets/slidersHSolid.svg'} alt={'filterImg'}/>
                  篩選
                </div>
              </Box>
              <Box>
                <div className="tableZone">
                  <div className="tableContent">
                    <Table ref={tableRef}>
                      <Body className="questionTableBody">
                        {
                          filterQuestionData &&
                      filterQuestionData.length > 0 ?
                            sliceTableData(filterQuestionData, pageState.currentPage, pageState.limit).map((item, index) => {
                              return (
                                <Row className={'rowStyle'} key={index} disabled={item.isImport}>
                                  <Item flex="0.5" textalign="center"><Checkbox disabled={item.isImport} checked={isSelected(item.uid)} onClick={(e) => {toggle(item.uid); onQuestionCheck(e, item.uid);} }/></Item>
                                  <Item flex={4.3} onClick={() => {mobileShowQuestionDetail(item, index);}}>
                                    <div className="questionContent">
                                      {
                                        item.content ?
                                          <div className="questionContentText">{item.content}</div>
                                          : <img className="questionImage" src={item.image} alt='img'/>
                                      }
                                    </div>
                                  </Item>
                                </Row>
                              );
                            }) : <div className="emptyMsg">找不到相符的結果，請變更篩選條件</div>
                        }
                      </Body>
                    </Table>
                  </div>
                </div>
                <div className="pageBlock">
                  <Pagination
                    total={filterQuestionData?.length || 0}
                    defaultCurrent={1}
                    current={pageState.currentPage}
                    onChange={pageChange}
                    defaultPageSize={pageState.limit}
                  />
                </div>
              </Box>
              {
                modalVisible &&
                <div className="questionImageModal">
                  <div className="questionImageModalContent">
                    <div className="questionImageModalClose" onClick={() => {setModalVisibleFalse();}}><XmarkOutline/></div>
                    <div className="questionImageModalContentImage">
                      <img src={modalImageSrc} alt="img" />
                    </div>
                  </div>
                </div>
              }
              {/* 試題詳情彈窗 */}
              {
                questionDetailData && JSON.stringify(questionDetailData) !== '{}' &&
                  <Modal
                    id={'questionDetailDrawer'}
                    visible={questionDetailDrawerVisible}
                    zIndex={1001}
                    onCancel={questionDetailDrawerFalse}
                    width="100%"
                    className={reportDrawerVisible ? 'isHidden' : ''}
                    closeIcon={<ChevronRightOutline className={'mobileCloseBtn'}/>}
                  >
                    <div
                      onTouchEnd={(e) => {onTouchEnd(e);}}
                      onTouchStart={(e) => {onTouchStart(e);}}
                    >
                      <div className='detailImgBox'>
                        <img src={questionDetailData?.image} alt={questionDetailData?.uid}></img>
                        {
                          isShowPreQuesBtn &&
                            <div className='preQuesBtn' onClick={preQues}><ChevronLeftOutline/></div>
                        }
                        {
                          isShowNextQuesBtn &&
                            <div className='nextQuesBtn' onClick={nextQues}><ChevronRightOutline/></div>
                        }
                      </div>
                      <div className='detailControlBox'>
                        <div>
                          {
                            env === 'dev' &&
                            <div><StarSolid/>收藏</div>
                          }
                          <div onClick={() => {showReportDrawer(questionDetailData.uid);}}><ReturnSolid/>回報</div>
                        </div>
                        <div className={isSelected(questionDetailData.uid) ? 'checked' : ''} onClick={(e) => {toggle(questionDetailData.uid); onQuestionCheck(e, questionDetailData.uid);} }>
                          <Checkbox disabled={questionDetailData.isImport} checked={isSelected(questionDetailData.uid)}/>
                          選擇此題
                        </div>
                      </div>
                      <div className='detailContent' id={'questionDetailDrawer'}>
                        <div>
                          <div>{questionDetailData.ANSWER_TYPE}</div>
                          <div>
                            <DifferentTag mr={1} variant={diffStr[questionDetailData?.metadata?.filter(i => i?.code === 'DIFFICULTY')[0]?.content[0]?.code]} />
                          </div>
                        </div>
                        <div>
                          <div>題型</div>
                          <div>{questionDetailData?.metaData?.questionType?.name}</div>
                        </div>
                        <div>
                          <div>出處</div>
                          <div>{questionDetailData?.metaData?.source?.name}</div>
                        </div>
                        <div>
                          <div>學習內容</div>
                          <div>{questionDetailData?.metaData?.learnContent?.name}</div>
                        </div>
                        <div>
                          <div>知識項度</div>
                          <div>{questionDetailData?.metaData?.knowledge?.name}</div>
                        </div>
                      </div>
                    </div>
                  </Modal>
              }
              <Drawer
                visible={reportDrawerVisible}
                title={'錯誤回報'}
                onClose={reportDrawerFalse}
                placement={'bottom'}
                isMobile={true}
                id={'reportDrawer'}
                className={'reportDrawer'}
                footer={
                  <>
                    <Button
                      variant={!reportModalData?.errorType || !reportModalData?.description ? 'ui02' : 'brand2'}
                      disabled={!reportModalData?.errorType || !reportModalData?.description}
                      onClick={doMobileReport}>送出</Button>
                    <Button variant='ui01' onClick={reportDrawerFalse}>取消</Button>
                  </>
                }
              >
                <div className='radioBox'>
                  <div>原因<span>*</span></div>
                  <Radio.Group onChange={onReportTypeChange} value={reportModalData.errorType}>
                    {
                      reportModalSelectOptions &&
                      reportModalSelectOptions.map((item) => {
                        return (<Radio key={item.value} value={item.value}>{item.name}</Radio>);
                      })
                    }
                  </Radio.Group>
                </div>
                <div className='explanationBox'>
                  <div>說明<span>*</span></div>
                  <Input.TextArea
                    className='textArea'
                    autoSize={true}
                    onChange={onTextAreaChange}
                    key={reportModalData.uid}
                    placeholder={'請輸入說明'}/>
                </div>
              </Drawer>

              <Drawer
                visible={setupDrawerVisible}
                title={'題數與配分'}
                onClose={setupDrawerFalse}
                placement={'bottom'}
                isMobile={true}
                id={'setupDrawer'}
                className={'setupDrawer'}
                key={tableData}
                destroyOnClose={true}
              >
                <div className='setupDrawerTotalScore'>
                  總題數 {totalSetupQuesTypeData.questionCount} 題
                </div>
                {
                  pickUpQuestionID.length > 0 && renderQuestionAnalysisModal()
                }
                {
                  keyList.length > 0
                    ?
                    keyList.map((key) => {
                      return (
                        <div className='questionTypeBox' key={key}>
                          <div className={'drawerTitle'}>
                            {setupDrawerData[key]?.name}
                          </div>
                          <div className={'drawerSection'}>
                            <div className={'drawerTable'}>
                              <div>
                                <div>已選題數</div>
                                <div>已答題數</div>
                              </div>
                              <div>
                                <div>
                                  {setupDrawerData[key].inputValue.question}
                                </div>
                                <div>
                                  {setupDrawerData[key].inputValue.answer}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    :
                    <div className='emptySelectedQuestion'>尚未選擇任何試題</div>
                }
              </Drawer>
              <Modal
                id={'filterDrawer'}
                visible={filterDrawerVisible}
                zIndex={1001}
                onCancel={filterDrawerFalse}
                width='100%'
                title={
                  <>
                    <span>篩選</span><div className={classNames('clearAllFilterBtn', Object.keys(filterConditionMobile).length > 1 | displayQuestionsTypeMobile !== 'all' ? 'active' : '' )} onClick={clearAllFilter}>全部清除</div>
                  </>
                }
                footer={
                  <>
                    <Button
                      className='reportModalBtn'
                      variant={!checkedFilterChangeBtn ? 'ui02' : 'brand2'}
                      disabled={!checkedFilterChangeBtn}
                      onClick={doneFilterDrawerChange}
                    >套用</Button>
                  </>
                }
              >
                <div className='filterDrawerContent'>
                  <div>
                    <div>試題</div>
                    <RadioGroup
                      key={displayQuestionsTypeMobile}
                      name={'mobileQuestionDisplayOptions'}
                      className='radioList' buttonStyle='solid'
                      optionType='button'
                      options={mobileQuestionDisplayOptions}
                      value={displayQuestionsTypeMobile}
                      onChange={(e) => {onQuestionDisplayTypeMobileChange(e.target.value);}}
                    />
                  </div>
                  <div>
                    <div>題型</div>
                    <div
                      className={JSON.stringify(filterConditionMobile?.QUES_TYPE) !== '{}' && filterConditionMobile?.QUES_TYPE !== undefined ? 'activeText' : 'defaultText'}
                      onClick={() => {showFilterSelectDrawer('QUES_TYPE');}}
                    >
                      {
                        filterSelectedTitle_QUES_TYPE?.length > 0
                          ?
                          <div>
                            <span>{filterSelectedTitleArray(filterSelectedTitle_QUES_TYPE)}</span>
                            <div className='totalCountIcon'>{filterSelectedTitle_QUES_TYPE.length}</div>
                          </div>
                          :
                          <div>選擇題型</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>出處</div>
                    <div
                      className={JSON.stringify(filterConditionMobile?.SOURCE) !== '{}' && filterConditionMobile?.SOURCE !== undefined ? 'activeText' : 'defaultText'}
                      onClick={() => {showFilterSelectDrawer('SOURCE');}}
                    >
                      {
                        filterSelectedTitle_SOURCE?.length > 0
                          ?
                          <div>
                            <span>{filterSelectedTitleArray(filterSelectedTitle_SOURCE)}</span>
                            <div className='totalCountIcon'>{filterSelectedTitle_SOURCE.length}</div>
                          </div>
                          :
                          <div>選擇出處</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>難易度</div>
                    <div key={filterConditionMobile?.DIFFICULTY} className={filterConditionMobile?.DIFFICULTY ? 'activeText' : 'defaultText'} onClick={() => {showFilterSelectDrawer('DIFFICULTY');}}>
                      {
                        filterConditionMobile?.DIFFICULTY
                          ?
                          <div>
                            <span>{filterConditionMobile?.DIFFICULTY}</span>
                            <XmarkCircleSolid className='clearBtn' onClick={(e) => {e.stopPropagation();deleteFilterKeyMobile('DIFFICULTY');}}/>
                          </div>
                          :
                          <div>選擇難易度</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>範圍</div>
                    <div className={filterConditionMobile?.chapter?.length > 0 ? 'activeText' : 'defaultText'} onClick={() => {showFilterSelectDrawer('chapter');}}>
                      {
                        filterConditionMobile?.chapter?.length > 0
                          ?
                          <div>
                            <span>{filterSelectedTitle(mobileFilterChapterTitle)}</span>
                            <XmarkCircleSolid className='clearBtn' onClick={(e) => {e.stopPropagation();deleteFilterKeyMobile('chapter'); setCheckboxTreeData([]);}}/>
                          </div>
                          :
                          <div>選擇範圍</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>知識向度</div>
                    <div key={filterConditionMobile?.KNOWLEDGE} className={filterConditionMobile?.KNOWLEDGE ? 'activeText' : 'defaultText'} onClick={() => {showFilterSelectDrawer('KNOWLEDGE');}}>
                      {
                        filterConditionMobile?.KNOWLEDGE
                          ?
                          <div>
                            <span>{filterSelectedTitle(filterConditionMobile?.KNOWLEDGE)}</span>
                            <XmarkCircleSolid className='clearBtn' onClick={(e) => {e.stopPropagation();deleteFilterKeyMobile('KNOWLEDGE');}}/>
                          </div>
                          :
                          <div>選擇知識向度</div>
                      }
                    </div>
                  </div>
                  <div>
                    <div>學習內容</div>
                    <div key={filterConditionMobile?.LEARN_CONTENT} className={filterConditionMobile?.LEARN_CONTENT ? 'activeText' : 'defaultText'} onClick={() => {showFilterSelectDrawer('LEARN_CONTENT');}}>
                      {
                        filterConditionMobile?.LEARN_CONTENT
                          ?
                          <div>
                            <span>{filterSelectedTitle(filterConditionMobile?.LEARN_CONTENT)}</span>
                            <XmarkCircleSolid className='clearBtn' onClick={(e) => {e.stopPropagation();deleteFilterKeyMobile('LEARN_CONTENT');}}/>
                          </div>
                          :
                          <div>選擇學習內容</div>
                      }
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                id={'filterSelectDrawer'}
                visible={filterSelectDrawerVisible}
                zIndex={1001}
                onCancel={filterSelectDrawerFalse}
                width='100%'
                title={mobileFilterDrawerTypeTitle}
                closeIcon={<ChevronRightOutline className={'mobileCloseBtn'}/>}
              >
                {/* ---- 題型 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'QUES_TYPE' && typeOptions && typeOptions.length > 0 &&
                      transformToTreeData(typeOptions).map((item) => {
                        return (
                          <CheckboxType
                            typeTitle={{ label: item.label, value: item.value }}
                            options={item.children}
                            checkedList={reCheckedList('QUES_TYPE', item.value)}
                            onChangeList={onTreeChange}
                            key={item.value}
                          />
                        );
                      })
                  }
                </StyledMobileFilterContent>

                {/* ---- 出處 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'SOURCE' && sourceOptions && sourceOptions.length > 0 &&
                    transformToTreeData(sourceOptions).map((item) => {
                      return (
                        <CheckboxType
                          typeTitle={{ label: item.label, value: item.value }}
                          options={item.children}
                          checkedList={reCheckedList('SOURCE', item.value)}
                          onChangeList={onTreeChange}
                          key={item.value}
                        />
                      );
                    })
                  }
                </StyledMobileFilterContent>

                {/* ---- 難易度 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'DIFFICULTY' &&
                    <Radio.Group
                      onChange={(e) => {onRadioChange('DIFFICULTY', e);}}
                      value={filterConditionMobile?.DIFFICULTY}
                    >
                      {
                        difficultyOptions && difficultyOptions.length > 0 &&
                          transformRadio(difficultyOptions).map((item) => {
                            return (
                              <Radio className={difficultyHide(item) ? 'hideRadio' : ''} key={item.code} value={item.code}>{item.name}</Radio>
                            );
                          })
                      }
                    </Radio.Group>
                  }
                </StyledMobileFilterContent>

                {/* ---- 範圍 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'chapter' && checkboxTreeOptions &&
                    checkboxTreeOptions.map((item) => {
                      return (
                        <CheckboxTree
                          grandparentOptions={item}
                          checkedList={checkboxTreeData}
                          onClickCallBack={onCheckboxTreeChange}
                        />
                      );
                    })
                  }
                </StyledMobileFilterContent>

                {/* ---- 知識向度 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'KNOWLEDGE' &&
                    <Radio.Group
                      onChange={(e) => {onRadioChange('KNOWLEDGE', e);}}
                      value={filterConditionMobile?.KNOWLEDGE}
                    >
                      {
                        knowledgeOptions && knowledgeOptions.length > 0 &&
                          transformRadio(knowledgeOptions).map((item) => {
                            return (
                              <Radio key={item.code} value={item.code}>{item.name}</Radio>
                            );
                          })
                      }
                    </Radio.Group>
                  }
                </StyledMobileFilterContent>

                {/* ---- 學習內容 篩選 ---- */}
                <StyledMobileFilterContent>
                  {
                    mobileFilterDrawerType === 'LEARN_CONTENT' &&
                    <Radio.Group
                      onChange={(e) => {onRadioChange('LEARN_CONTENT', e);}}
                      value={filterConditionMobile?.LEARN_CONTENT}
                    >
                      {
                        learnContentOptions && learnContentOptions.length > 0 &&
                          transformRadio(learnContentOptions).map((item) => {
                            return (
                              <Radio key={item.code} value={item.code}>{item.name}</Radio>
                            );
                          })
                      }
                    </Radio.Group>
                  }
                </StyledMobileFilterContent>

              </Modal>
            </>
        }
      </StyledMpQuestionSelectContent>
  );
};