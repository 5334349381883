import styled from 'styled-components';
import { space } from 'styled-system';

export const StyledHomeAreaSelectContent = styled.div`
display: flex;
flex:1;
	.checkBoxRow{
		&:not(:last-child){
			margin-bottom: 8px;
		}
	}
	.stepHint{
			color:${({ theme }) => theme.colors.text.text_06}
		}
	.areaBorder{
		background-color: ${({ theme }) => theme.colors.ui.ui_03};
		width: 100%;
		height: 1px;
	}
	.ant-radio-button-wrapper{
		padding: 8px 16px;
	}
`;

export const UiSelectList = styled.div`
`;
