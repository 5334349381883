import styled from 'styled-components';

export const StyledStudentIntelligentPropositionPage = styled.div.attrs(props => ({
  className: props.className
}))`
    position: relative;
	.ant-steps{
		max-width: 50%;
		margin: auto;
		margin-bottom: 26px;
	}
	.footerBtn{
		display: flex;
		&> *:first-child{
			margin-right: 8px;
		}
	}
	.scrollBtn{
		cursor: pointer;
		display:flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		background: ${({ theme }) => theme.colors.ui.ui_03};
		padding: 12px;
		border-radius: 50%;
		color: ${({ theme }) => theme.colors.ui.white};
		z-index: 300;
		bottom: 44px;
		right: 44px;
		svg{
			margin-right: 0;
    		font-size: 24px;
		}
	}
`;

export const StyledPageContent = styled.div`
    position: relative;
	display: flex;
	overflow: ${({ notLimitHeight }) => notLimitHeight ? 'visible' : 'auto'};
	overflow-y: ${({ notLimitHeight }) => notLimitHeight ? 'visible' : 'overlay'};
	margin: 12px 8px;
	border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
    box-sizing: border-box;
    border-radius: 8px;
	height:${({ notLimitHeight }) => notLimitHeight ? 'auto' : '70vh'};

	> div {
		height: 100%;
		width: 960px;
	}
	.tempDone{
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
		font-weight: 500;
	}
	@media (max-width: 1024px) {
		border: 0px;
		margin: 0 0;
	}
	
`;
export const UiBtnZone = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	& > div:not(:first-child){
		margin-left: 8px;
	}
	@media (max-width: 1024px) {
		width: 100%;
		background-color: #fff;
		padding: 10px 24px 34px 24px;
        position: fixed;
		bottom: 0;
		box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
		margin: 0 0;
		z-index:999;
		>div{
			width: 100%;
		}
		.prevStepBtn{
			display: none;
		}
		.disabledText{
			color:${({ theme }) => theme.colors.text.text_05} !important;
			>svg{
				color:${({ theme }) => theme.colors.text.text_05} !important;
			}
		}
		.goToSteps5Btn{
			border: 1px solid #EC7963 !important;
		}
		.ant-modal-content{
			width: 100vw;
			height: calc(var(--vh, 1vh) * 100);
			border-radius: 0px;
			
		}
 	}
`;