import { Api } from '@oneclass/ui-components';
// import { stringify } from 'query-string';

export const getDrawUpAutoSelections = async (pattern, payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/${pattern}/Selection`, payload);
  return response;
};


export const getDrawUpAutoCacheQuery = async (pattern, payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/${pattern}/CacheQuery`, payload);
  return response;
};