import React, { useEffect, useState, useMemo, useContext } from 'react';
import {
  fetchDataMachine,
  FETCH_DATA_EVENT,
  AuthProvider,
  sectionToChinese,
} from '@oneclass/ui-components';
import { TestListDesktop, TestListMobile } from 'components';
import { getExamPaperEditCopy } from 'api/examPaper';
import { useMachine } from '@xstate/react';
import { getTime } from 'date-fns';
import { getExamPaperPreview } from 'api/examPaper';
import { postQuizList, postQuizResult } from 'api/quiz';
import { getCookie, transformTime } from 'utils/common';
import { useLocation } from 'react-router-dom';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useCreateQuizExamModal } from 'utils/hooks/useCreateQuizExamModal';
import { StyledTestListContent } from './TestListContent.style';
import { useBoolean } from 'ahooks';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';

const eduOptions = [
  {
    name: '國小',
    value: 'E',
  },
  {
    name: '國中',
    value: 'J',
  },
  {
    name: '高中',
    value: 'H',
  },
];

export const TestListContent = ({ className, setEduSubjectModalToggle, tabType }) => {
  const env = process.env.REACT_APP_NODE_ENV;
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const location = useLocation();
  const [filterParams, setFilterParams] = useState({});
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();

  const [statePostQuizList, sendPostQuizList] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        setIsLoading(true);
        const res = await postQuizList(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) setIsLoading(false);
        // const { yearMap = [], eduSubjectMap = [], typeMap = [], patternMap = [], examPaper = [] } = data || {};
        let eduSubjectOptions = [];
        data.forEach(item => {
          if (eduSubjectOptions.findIndex(data => data.name === item.EduSubjectName) === -1) {
            eduSubjectOptions = eduSubjectOptions.concat({
              name: item.EduSubjectName,
              value: `${item.Education}${item.Subject}`
            });
          }
        });
        return {
          isSuccess, systemCode, message,
          quizListData: data,
          eduSubjectOptions: eduSubjectOptions || [],
        };
      },
    },
  });

  const [statePostQuizResult, sendPostQuizResult] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postQuizResult(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        // const { yearMap = [], eduSubjectMap = [], typeMap = [], patternMap = [], examPaper = [] } = data || {};
        const { isUpdate, csvFileUrl, completedNumber } = data;
        if (csvFileUrl) {
          const link = document.createElement('a');
          link.href = csvFileUrl;
          link.setAttribute('download', csvFileUrl);
          document.body.appendChild(link);
          link.click();
        }

        return {
          isSuccess, systemCode, message,
          csvFileUrl
        };
      },
    },
  });

  const {
    quizListData,
    eduSubjectOptions
  } = statePostQuizList.context.result || {};

  const [_, sendGetExamPaperPreview] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await getExamPaperPreview(event.examUID);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) window.open(data);

        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  const [stateGetExamPaperEditCopy, sendGetExamPaperEditCopy] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await getExamPaperEditCopy(event.examUID, event.action);
        const { data, isSuccess, systemCode, message } = res;

        return {
          isSuccess, systemCode, message, examPaperData: data, actionType: event.action
        };
      },
    },
  });

  const eduGradeOptions = useMemo(() => {
    if (!quizListData) return [];
    let array = [];
    let objectArray = [];
    quizListData.forEach((item) => {
      if (array.indexOf(item.Education + item.Grade) === -1) {
        array.push(item.Education + item.Grade);
        objectArray.push({
          name: (eduOptions.find(edu => edu.value === item.Education).name + sectionToChinese(item.Grade.substring(1)) + '年級'),
          value: item.Education + item.Grade,
        });
      }
    });
    const eduValue = {
      E: 1,
      J: 2,
      H: 3
    };
    return (objectArray.sort(function(a, b) {
      return a.value.substring(3, 4) - b.value.substring(3, 4);
    }).sort(function(a, b) {
      return (eduValue[a.value.substring(0, 1)] - eduValue[b.value.substring(0, 1)]);
    }));
  }, [quizListData]);

  const filterData = useMemo(() => {
    if (!quizListData) return [];
    const nextData = quizListData.filter((item) => {
      const checkResult = Object.entries(filterParams).map(([key, value]) => {
        if (value && value.length === 0) return true;
        if (!value) return true;
        if (key === 'eduSubject') {
          const listEduSubject = `${item.Education}${item.Subject}`;
          return listEduSubject === value;
        } else if (key === 'quizName') {
          return item.QuizName.includes(value);
        } else if (key === 'date') {
          if (value[0] === '' || value[1] === '') return true;
          const filterStartTime = new Date(`${value[0]} 00:00:00`);
          const filterEndTime = new Date(`${value[1]} 23:59:59`);
          return (getTime(new Date(item.EndTime)) >= getTime(filterStartTime) && getTime(new Date(item.EndTime)) <= getTime(filterEndTime));
        } else if (key === 'eduGrade') {
          return (item.Education + item.Grade) === value;
        }

      });
      const checkResultSet = new Set(checkResult);
      if (!checkResultSet.has(false)) return item;

    });
    return nextData;
  }, [quizListData, filterParams]);

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  const setFilterCondition = (value, key) => {
    setFilterParams({
      ...filterParams,
      [key]: value
    });
  };

  const goToExamPage = (examUID) => {
    if (!examUID) return;
    sendGetExamPaperPreview(FETCH_DATA_EVENT.FETCH, {
      examUID
    });
  };

  const goToReviewPage = (SchoolYear, QuizCode, QuizUID) => {
    if (!QuizUID) return;
    window.open(`${examLink}/quiz/review/${SchoolYear}/${QuizCode}/${QuizUID}`);
  };

  const copyEditExamPaper = (uid, action) => {
    sendGetExamPaperEditCopy(FETCH_DATA_EVENT.FETCH, {
      examUID: uid,
      action
    });
  };

  const mobileChangeFilterCondition = (data) => {
    setFilterParams(data);
  };

  const fetchQuizExcel = async (quizUID) => {
    const oneclubToken = JSON.parse(getCookie('nani_oneclass_login_token')).jwt;
    sendPostQuizResult(FETCH_DATA_EVENT.FETCH, {
      payload: {
        jwtToken: oneclubToken,
        quizUID
      }
    });
  };

  const examLink = useMemo(() => {
    if (!env) return;
    return `https://oneexam${(env !== 'release' && env !== 'test') ? `-${env}` : ''}.oneclass.com.tw`;
  }, [env]);

  useEffect(() => {
    if (location.pathname === '/examPaperList' && tabType === 'examList') {
      const oneclubToken = JSON.parse(getCookie('nani_oneclass_login_token')).jwt;
      sendPostQuizList(FETCH_DATA_EVENT.FETCH, {
        payload: {
          jwt: oneclubToken,
        }
      });
    }
  }, [location.pathname, tabType]);

  useEffect(() => {
    setPageState({
      currentPage: 1,
      limit: 10,
    });
  }, [filterParams]);

  const closeEditModal = () => {
    setModalVisibleFalse();

    const oneclubToken = JSON.parse(getCookie('nani_oneclass_login_token')).jwt;
    sendPostQuizList(FETCH_DATA_EVENT.FETCH, {
      payload: {
        jwt: oneclubToken,
      }
    });
  };

  const { renderCreateQuizExamModal, createQuizExamModalToggle, setCreateQuizExamModalFalse } = useCreateQuizExamModal( closeEditModal, 'create');

  const canEditExam = (examItem) => {
    const nowTime = new Date().getTime();
    const examStartTime = new Date(new Date(examItem.StartTime).getTime());
    if (nowTime > examStartTime) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <StyledTestListContent className={className} data-testid="TestListContent">
      {
        deviceData.isDesktop ?
          <TestListDesktop
            examLink={examLink}
            filterParams={filterParams}
            eduSubjectOptions={eduSubjectOptions}
            filterData={filterData}
            pageState={pageState}
            eduOptions={eduOptions}
            authState={authState}
            pageChange={pageChange}
            setFilterCondition={setFilterCondition}
            goToReviewPage={goToReviewPage}
            copyEditExamPaper={copyEditExamPaper}
            setEduSubjectModalToggle={setEduSubjectModalToggle}
            fetchQuizExcel={fetchQuizExcel}
            transformTime={transformTime}
            eduGradeOptions={eduGradeOptions}
            renderCreateQuizExamModal={renderCreateQuizExamModal}
            createQuizExamModalToggle={createQuizExamModalToggle}
            setCreateQuizExamModalFalse={setCreateQuizExamModalFalse}
            modalVisible={modalVisible}
            setModalVisibleToggle={setModalVisibleToggle}
            setModalVisibleFalse={setModalVisibleFalse}
            canEditExam={canEditExam}
            goToExamPage={goToExamPage}
            renderLoadingContent={renderLoadingContent}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          :
          <TestListMobile
            examLink={examLink}
            filterParams={filterParams}
            eduSubjectOptions={eduSubjectOptions}
            filterData={filterData}
            pageState={pageState}
            eduOptions={eduOptions}
            authState={authState}
            pageChange={pageChange}
            mobileChangeFilterCondition={mobileChangeFilterCondition}
            goToReviewPage={goToReviewPage}
            setEduSubjectModalToggle={setEduSubjectModalToggle}
            fetchQuizExcel={fetchQuizExcel}
            transformTime={transformTime}
            eduGradeOptions={eduGradeOptions}
            renderCreateQuizExamModal={renderCreateQuizExamModal}
            createQuizExamModalToggle={createQuizExamModalToggle}
            setCreateQuizExamModalFalse={setCreateQuizExamModalFalse}
            modalVisible={modalVisible}
            setModalVisibleToggle={setModalVisibleToggle}
            setModalVisibleFalse={setModalVisibleFalse}
            canEditExam={canEditExam}
            goToExamPage={goToExamPage}
            renderLoadingContent={renderLoadingContent}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
      }
    </StyledTestListContent>
  );
};