import React, { useState, useEffect, useMemo } from 'react';
import { Box, TreeCheck, fetchDataMachine, FETCH_DATA_EVENT, FETCH_DATA_STATE, setRadioOptions, setSelectOptions, sectionToChinese } from '@oneclass/ui-components';
import { Checkbox, RadioGroup } from '@oneclass/onedesign';
import { Row, Col } from 'antd';
import { useMachine } from '@xstate/react';
import { useSelections } from 'ahooks';
import { getDrawUpAutoSelections } from 'api/drawUpAuto';
import { format } from 'date-fns';
import { LANGUAGE_LIST } from 'constants/index';
import { UiFlexBox } from 'styles/index';
import { StyledStudentIpAreaSelectContent, UiSelectList } from './StudentIpAreaSelectContent.style';

const nowSchoolYear = format(new Date(), 'yyyy') - 1912;

export const StudentIpAreaSelectContent = ({ className, saveData, examPaperType, memberEduSubject, getSendAreaParams, getSaveData, identity, isPro }) => {
  const [sendParams, setSendParams] = useState({
    ...saveData.areaParams,
    year: (saveData.areaParams.year || (nowSchoolYear)).toString()
  });
  const [sendYear, setYear] = useState(saveData?.areaParams?.year || (nowSchoolYear) );
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [treeCheckedList, setTreeCheckedList] = useState([]); //["0-0-0"]
  const [selectLoading, setSelectLoading] = useState(false);
  const [stateSelection, send] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        setSelectLoading(true);
        const res = await getDrawUpAutoSelections(event.pattern, event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { curriculumMap = {}, sourceMap = {}, yearMap = {}, textbookMap = {}, versionMap = {} } = data || {};
        setSelectLoading(false);
        return {
          isSuccess, systemCode, message,
          curriculumOptions: (memberEduSubject.edu === 'J' || memberEduSubject.edu === 'H') ? setRadioOptions(curriculumMap)?.filter(item => item.value !== '99') : setRadioOptions(curriculumMap) || [],
          yearOptions: setRadioOptions(yearMap)?.filter(item => item.value !== '109') || [],
          sourceOptions: setSelectOptions(sourceMap) || [],
          versionOptions: setRadioOptions(versionMap) || [],
          textbookMap
        };
      },
    },
  });

  const {
    curriculumOptions,
    yearOptions,
    sourceOptions,
    versionOptions,
    textbookMap
  } = stateSelection.context.result || {};



  const bookOptions = useMemo(() => {
    if (!sendParams?.curriculum || !sendParams?.version || !textbookMap || Object.keys(textbookMap).length === 0) {
      return [];
    }
    return setSelectOptions(textbookMap[sendParams.version].bookMap[sendParams.curriculum]);
  }, [textbookMap, sendParams?.curriculum, sendParams?.version]);

  const chapterOptions = useMemo(() => {
    if (!textbookMap || Object.keys(textbookMap).length === 0 || !sendParams?.books || !sendParams?.version) return [];
    const nextData = sendParams.books.map(item => {
      return {
        title: `${bookOptions.find(book => book.value === item).name}`,
        key: `${textbookMap[sendParams.version].chapterMap[item]?.bookID}| `,
        children: textbookMap[sendParams.version].chapterMap[item].chapters.filter(ele => ele.hierarchy === 1).map(ele => {
          return ({
            title: `第${sectionToChinese(parseInt(ele.code))}${LANGUAGE_LIST.includes(memberEduSubject?.subject) ? '課' : '章'}_${ele.desc.split(']')[1].substring(1)}`,
            key: ele.desc,
            children: ele.hasKnowledge ?
              ele.knowledgeList.map(knowledge => (
                {
                  title: knowledge.itemName,
                  key: knowledge.itemCode
                }))
              : textbookMap[sendParams.version].chapterMap[item].chapters.filter(childrenEle => childrenEle.hierarchy === 2 && childrenEle.parentCode === ele.code).map((childrenEle) => {
                if (childrenEle.hasKnowledge) {
                  return (
                    {
                      title: childrenEle.desc,
                      key: childrenEle.desc,
                      children: childrenEle.knowledgeList.map(knowledge => (
                        {
                          title: knowledge.itemName,
                          key: knowledge.itemCode
                        }
                      ))
                    }
                  );
                } else {
                  return (
                    {
                      title: childrenEle.desc,
                      key: childrenEle.desc
                    }
                  );
                }
              })
          });
        })
      };

    });
    return nextData;
  }, [textbookMap, sendParams?.books, sendParams?.version]);

  const {
    selected: bookSelected,
    allSelected,
    isSelected,
    toggle,
    toggleAll,
    unSelectAll,
    setSelected,
    partiallySelected,
  } = useSelections(bookOptions.map(item => item.value), saveData.areaParams?.books);

  const getAllTreeData = (data) => {
    if (!data || data.length === 0) return [];
    let nextData = [];
    data.forEach(item => {
      let children = [];
      if ('children' in item) {
        children = getAllTreeData(item.children);
        nextData = [...nextData, ...children];
      }
      nextData = [...nextData, item.key];
    });
    return nextData;
  };

  const treeDataLength = useMemo(() => {
    if (!chapterOptions) return 0;
    return getAllTreeData(chapterOptions).length;
  }, [chapterOptions]);

  const onTreeChange = (data) => {
    setTreeCheckedList(data);
  };

  const onCheckAllChange = e => {
    setTreeCheckedList(e.target.checked ? getAllTreeData(chapterOptions) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onRadioChange = (type, e) => {
    setSendParams({
      ...sendParams,
      [type]: e.target.value
    });
  };

  const onChangeYear = (type, e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    getSaveData('bookOptions', bookOptions);
  }, [bookOptions]);

  useEffect(() => {
    if (!memberEduSubject?.edu || !memberEduSubject?.subject || sendYear === '') return;
    send(FETCH_DATA_EVENT.FETCH, {
      pattern: examPaperType,
      payload: {
        education: memberEduSubject?.edu,
        subject: memberEduSubject?.subject,
        year: sendYear || ''
      }
      //ToDo 學制科目切換
    });
    setTreeCheckedList([]);
    unSelectAll();
    if (memberEduSubject?.edu === saveData.education && memberEduSubject?.subject === saveData.subject && sendYear === saveData?.areaParams?.year) return;
    setSendParams({
      curriculum: sendParams.curriculum,
      year: sendParams.year
    });

  }, [memberEduSubject.edu, memberEduSubject.subject, sendYear]);

  useEffect(() => {
    if (stateSelection.matches(FETCH_DATA_STATE.DONE)) {
      if (sendYear === '' || sendYear === saveData?.areaParams?.year) {
        setSendParams(saveData.areaParams);
        setSelected(saveData.areaParams?.books);
        setTreeCheckedList(saveData.areaParams?.treeCheckedList || []);
      } else {
        setTreeCheckedList([]);
        unSelectAll();
        setSelected([]);

      }
    }
  }, [stateSelection.value]);
  useEffect(() => {
    setSendParams({
      ...sendParams,
      books: bookSelected
    });
  }, [bookSelected]);

  // useEffect(() => {
  //   setSendParams({
  //     ...sendParams,
  //     sources: sourceSelected
  //   });
  // }, [sourceSelected]);

  useEffect(() => {
    if (treeCheckedList.length === 0) {
      setIndeterminate(false);
      setCheckAll(false);
    } else {
      setIndeterminate(treeCheckedList.length !== treeDataLength ? true : false);
      setCheckAll(treeCheckedList.length !== treeDataLength ? false : true);
    }
  }, [treeDataLength, treeCheckedList]);

  useEffect(() => {
    if (!sendParams) return;
    var inValid = /\s/;
    getSendAreaParams({
      ...sendParams,
      bookIDs: sendParams?.books ? sendParams.books.map(item => `${sendParams.year}${sendParams.version}-${memberEduSubject.edu}${memberEduSubject.subject}${item}`) : [],
      keys: treeCheckedList.filter(item => !inValid.test(item)),
      treeCheckedList,
      filterUsed: false
    });
  }, [sendParams, treeCheckedList, memberEduSubject]);
  useEffect(() => {
    if (sendParams.curriculum === saveData.areaParams.curriculum) return;
    if (sendParams.version === saveData.areaParams.version) return;
    unSelectAll();
    // sourceUnSelectAll();
    setSelected([]);
    // sourceSetSelected([]);
  }, [sendParams.curriculum, sendParams.version]);
  useEffect(() => {
    const nextData = treeCheckedList.filter(item => getAllTreeData(chapterOptions).includes(item));
    setTreeCheckedList(nextData);
  }, [sendParams.books]);

  useEffect(() => {
    if (!versionOptions || !curriculumOptions) return;
    setSendParams({
      ...sendParams,
      curriculum: '108',
      version: versionOptions[0]?.value || 'N'
    });
  }, [versionOptions, curriculumOptions]);

  return (
    <StyledStudentIpAreaSelectContent className={className} data-testid="StudentIpAreaSelectPage">
      <UiFlexBox>
        <Box mb={6}>
          <div className="label">課綱</div>
          <UiSelectList>
            <RadioGroup buttonStyle='solid' optionType='button' options={curriculumOptions} value={sendParams?.curriculum} onChange={(e) => {onRadioChange('curriculum', e);}} />
          </UiSelectList>
        </Box>
        <Box mb={6}>
          <div className="label">年度</div>
          <UiSelectList>
            <RadioGroup buttonStyle='solid' optionType='button' options={yearOptions} value={sendParams?.year} onChange={(e) => {onRadioChange('year', e); onChangeYear('year', e);} } />
          </UiSelectList>
        </Box>
        <Box mb={6}>
          <div className='areaBorder'></div>
        </Box>
        {
          !selectLoading &&
          <>
            {
              (versionOptions && versionOptions.length > 1 && isPro) &&
          <>
            <Box mb={6}>
              <div className="label">版本</div>
              <UiSelectList>
                <RadioGroup buttonStyle='solid' optionType='button' options={versionOptions} value={sendParams?.version} onChange={(e) => {onRadioChange('version', e);}} />
              </UiSelectList>
            </Box>
            <Box mb={6}>
              <div className='areaBorder'></div>
            </Box>
          </>
            }

            <Box mb={6}>
              <div className="label">
            冊次
                {
                  sendParams?.curriculum && sendParams?.year &&
              <div >
                <Checkbox checked={allSelected} onClick={toggleAll} indeterminate={partiallySelected}>全選</Checkbox>
              </div>
                }


              </div>
              <UiSelectList>
                {
                  sendParams?.curriculum && sendParams?.year ?
                    bookOptions.map(item => (
                      <>
                        <Row className='checkBoxRow'>
                          <Col><Checkbox checked={isSelected(item.value)} onClick={() => toggle(item.value)} >{item.name}</Checkbox></Col>
                        </Row>
                      </>

                    )) :
                    <div className="stepHint">
                請先選擇年度。
                    </div>
                }

              </UiSelectList>
            </Box>
            <Box mb={6}>
              <div className='areaBorder'></div>
            </Box>
            <Box>
              <div className="label">
              章節
                {
                  sendParams?.books && sendParams?.books.length > 0 &&
                <div>
                  <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>全選</Checkbox>
                </div>
                }

              </div>
              <UiSelectList>
                {
                  sendParams?.books && sendParams?.books.length > 0 ?
                    <TreeCheck treeData={chapterOptions} checkedList={treeCheckedList} onChange={onTreeChange} /> :
                    <div className="stepHint">
                請先選擇出處。
                    </div>
                }

              </UiSelectList>
            </Box>
          </>
        }

      </UiFlexBox>
    </StyledStudentIpAreaSelectContent>
  );
};