
import React, { createContext, useState, useEffect } from 'react';

export const firebaseRemoteConfigProviderContext = createContext(() => {});

export const Provider = ({ children }) => {

  const [remoteConfigData, setRemoteConfigData] = useState({});

  const RemoteConfigDataProviderChange = (value) => {
    setRemoteConfigData(value);
  };

  const value = { remoteConfigData, RemoteConfigDataProviderChange };

  return (
    <firebaseRemoteConfigProviderContext.Provider value={value}>
      {children}
    </firebaseRemoteConfigProviderContext.Provider>
  );
};

const firebaseRemoteConfigProvider = {};
firebaseRemoteConfigProvider.Provider = Provider;
firebaseRemoteConfigProvider.firebaseRemoteConfigProviderContext = firebaseRemoteConfigProviderContext;

export default firebaseRemoteConfigProvider;


