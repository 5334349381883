import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
export const useHistoryBlock = (history) => {
  const [pathName, setPathName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  //data沒變condition = true
  const conditionBlock = (condition) => {
    history.block(({ pathname }) => {
      if (condition) return true;
      setShowModal(location.pathname === pathname ? false : true);
      setPathName(pathname);
      return false;
    });
  };
  const leavePage = () => {
    history.push(`${pathName}`);
  };
  const renderBlockModal = (message, onOk, onCancel) => {
    return (
      <Modal
        message={message}
        visible={showModal}
        onCancel={() => {setShowModal(false);}}
        title='是否儲存設定變更？'
        footer={
          <>
            <Button
              variant='ui01'
              onClick={() => {
                onCancel();
                setShowModal(false);
                conditionBlock(true);
                leavePage();
              }}>放棄變更</Button>
            <Button
              variant='brand1'
              onClick={() => {
                onOk();
                conditionBlock(true);
                leavePage();
              }}>儲存變更</Button>
          </>

        }
      >
        <div className='modalContent'>儲存後將不可返回上一次設定，確認儲存請按「儲存變更」</div>
      </Modal>
    );
  };
  return { conditionBlock, renderBlockModal };
};
