import styled from 'styled-components';

export const StyledTestHeader = styled.div`
  width: 100%;
  height: 92px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px #E2E2EA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7rem;
  .logoBox{
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
`;

export const StyleLogoBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const StyleLogoName = styled.div`
  color: #EC7963;
  font-weight: 600;
  font-size: 14px;
  background: #FEF2F0;
  border-radius: 20px;
  padding:6px 20px;
`;

export const StyledTestHeaderLogo = styled.div`
  width: 132px;
  height: 48px;
  /* TODO: img tag */
  background: url("assets/oneTest.png");
  background-position: center;
  background-size: cover;
  cursor: pointer;
`;


export const StyledLinkZone = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 100%;
`;


export const StyledLink = styled.div`
  color:${({ active }) => active ? '#EC7963' : '#242C3F'};
  flex:1;
  position: relative;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  &:not(:first-child){
    margin-left:28px;
  }
  ::after{
    content: '';
    width: 100%;
    height: ${({ active }) => active ? '4px' : '0px'};
    background-color: #EC7963;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;


export const StyleUserName = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.text_07};
  margin-left: 20px;
  display:flex;
  align-items: center;
`;

export const StyledSlash = styled.div`
  height: 28px;
  width: 2px;
  background: #E4E7EC;
  border-radius: 1px;
  transform: rotate(15.7deg);
  margin: 0 30px
`;