import React, { useState, useEffect, useContext } from 'react';
import { Box, AuthProvider } from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { getCookie, deleteCookie } from 'utils/common';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { StyledHomePage } from './HomePage.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useHistory } from 'react-router-dom';
import { Announcement } from 'components';

export const HomePage = ({ className }) => {
  const history = useHistory();
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const { renderEduSubjectModal, setEduSubjectModalToggle } = useEduSubjectModal(authState.context.userDetails?.preference);
  const [userData, setUserData] = useState({
    identity: '',
    organizationType: ''
  });
  const [isShowHint, setIsShowHint] = useState(true);

  useEffect(() => {
    if ((authState.value === 'loggedIn' || authState.value === 'settingEduSubject')) {
      if (getCookie('createNewPaperBackHome') !== null) {
        setEduSubjectModalToggle();
        deleteCookie('createNewPaperBackHome');
        deleteCookie('createNewPaper');
      }
      if (authState.context.userDetails?.userStatus) {
        const { organization, identity } = authState.context.userDetails?.userStatus;
        setUserData({
          organizationType: organization.type,
          identity
        });
      }
    }
  }, [authState.value]);

  useEffect(() => {
    if (userData) {
      if (userData.organizationType === 'Tutoring') history.push('/examPaperList');
    }
  }, [userData]);

  return (
    <StyledHomePage className={className} data-testid="HomePage" >
      {
        isShowHint &&
          <Announcement setIsShowHint={setIsShowHint}/>
      }
      <Box>
        <div className='start'>
          <div className='startContent'>
            <div className='title'>雲端題庫</div>
            <div className='title'>無光碟綠校園</div>
            <div className='text'>輕鬆組卷 快速出題</div>
            <div className='btn'>
              <Button onClick={() => {setEduSubjectModalToggle();}} variant='primary2'>開始出卷</Button>
            </div>
          </div>
          <div className="startImage">
            {
              !deviceData.isDesktop ? <img src="assets/startImage.svg" alt="startImage" /> : <img src="assets/startImage_large.svg" alt="startImage" />
            }
          </div>
        </div>
      </Box>
      {renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)}
    </StyledHomePage>
  );
};