import React, { useContext } from 'react';
import {
  Box,
  fetchDataMachine,
  FETCH_DATA_EVENT,
} from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { getCookie } from 'utils/common';
import { ExternalLinkAltSolid } from '@onedesign/icon';
import { useMachine } from '@xstate/react';
import { postPracticeStart } from 'api/practice';
import { useHistory } from 'react-router-dom';
import { StyledStudentIpExamCompleteContent } from './StudentIpExamCompleteContent.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';

export const StudentIpExamCompleteContent = ({ uid }) => {
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const history = useHistory();
  const [statePostPracticeStart, sendPostPracticeStart] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postPracticeStart(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) window.open(data);

        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  const goToExamPage = () => {
    if (!uid) return;
    const oneclubToken = JSON.parse(getCookie('nani_oneclass_login_token')).jwt;
    const payload = {
      uid: uid,
      oneclubToken
    };
    sendPostPracticeStart(FETCH_DATA_EVENT.FETCH, {
      payload
    });
  };

  const goToExamList = () => {
    history.push('/studentExamPaperList');
  };

  return (
    <StyledStudentIpExamCompleteContent data-testid="StudentIpExamCompleteContent">
      <Box pb={10}>
        <img src='/assets/paperComplete.svg' alt='examPaper' />
      </Box>
      <Box pb={8}>
        <div className="pageTitle">組卷完成</div>
      </Box>
      <Box pb={6} className='controlBtnBox'>
        <Button isLoading={statePostPracticeStart.context.isLoading} variant='brand1' onClick={() => {goToExamPage();}}><ExternalLinkAltSolid />前往試卷</Button>
      </Box>
      <Box pb={6}>
        <div className="hint">線上試卷已組卷完成{deviceData.isDesktop ? '，' : <br/>}您也可至<span onClick={() => {goToExamList();}}>試卷列表</span>查看試卷並進行更多操作</div>
      </Box>
    </StyledStudentIpExamCompleteContent>
  );
};