import React, { useEffect, useState, useMemo, useContext } from 'react';
import { StyledSearchPaperListPage, StyledHeader, StyleContentBox, StyledMobileItem } from './SearchPaperListPage.style';
import {
  Table,
  Select,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  setSelectOptions,
  Pagination,
  sliceTableData,
  AuthProvider,
  Drawer,
} from '@oneclass/ui-components';
import { HeartOutline, ExternalLinkAltSolid, HeartSolid, SearchSolid, ChevronLeftOutline, SortDownSolid, SlidersHSolid } from '@onedesign/icon';
import { getExamPaperPreview, putExamPaperFavorite, getExamPaperPublic } from 'api/examPaper';
import { useMachine } from '@xstate/react';
import classNames from 'classnames';
import { Input, Radio } from 'antd';
import { getMemberEduSubject } from 'api/drawUpFast';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useBoolean } from 'ahooks';
import { RadioGroup, Button } from '@oneclass/onedesign';
import { useHistory } from 'react-router-dom';

export const SearchPaperListPage = ({ className }) => {
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const history = useHistory();

  const [searchParams, setSearchParams] = useState({
    field: 'Name',
    content: '',
    edu: '',
    subject: '',
  });

  const [filterParams, setFilterParams] = useState('');

  let searchIsLoading = false;

  // 翻頁功能
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });

  const onPageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  // 時間切割顯示
  const substringTime = (time) => {
    return time.substring(0, 10);
  };

  //出卷者 字串切割
  const substringName = (Name) => {
    return Name.split(']')[1];
  };

  // 試卷收藏 功能
  const [__, sendPutExamPaperFavorite] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await putExamPaperFavorite(event.payload);
        const { isSuccess, systemCode, message } = res;
        doGetExamPaperPublic();
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  const addFavorite = (boolean, record) => {
    if (!record) return;
    const { attributes } = record;
    sendPutExamPaperFavorite(FETCH_DATA_EVENT.FETCH, {
      payload: {
        examUID: record.uid,
        year: attributes.year,
        education: attributes.education,
        subject: attributes.subject,
        isAdd: !boolean,
      }
    });
  };

  // 搜尋試卷
  const [stateGetExamPaperPublic, sendGetExamPaperPublic] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        searchIsLoading = true;
        const res = await getExamPaperPublic(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { yearMap, examPaper, eduSubjectMap } = data;
        searchIsLoading = false;
        return {
          isSuccess, systemCode, message, eduSubjectMap,
          yearSelectOption: setSelectOptions(yearMap),
          searchExamPaperRecords: examPaper,
        };
      },
    },
  });

  const { searchExamPaperRecords, yearSelectOption } = stateGetExamPaperPublic.context.result || {};

  const doGetExamPaperPublic = () => {
    sendGetExamPaperPublic(FETCH_DATA_EVENT.FETCH, {
      payload: { ...searchParams }
    });
  };

  // 試卷篩選結果
  const filterRecords = useMemo(() => {
    if (!searchExamPaperRecords) return [];
    if (!filterParams) return searchExamPaperRecords;
    let nextData = searchExamPaperRecords.filter((record) => {
      const { attributes } = record;
      return filterParams === attributes.year;
    });
    return nextData;
  }, [filterParams, searchExamPaperRecords]);

  // 試卷預覽 功能
  const [_, sendGetExamPaperPreview] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await getExamPaperPreview(event.examUID);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) window.open(data);

        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  const goToExamPage = (examUID) => {
    if (!examUID) return;
    sendGetExamPaperPreview(FETCH_DATA_EVENT.FETCH, {
      examUID
    });

  };

  // 骨架屏 Loading 效果
  const skeletonArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const Skeleton = () => {
    return (
      <div className='ant-skeleton ant-skeleton-active skeletonWidth'>
        <div className="ant-skeleton-content">
          <ul className='ant-skeleton-paragraph'>
            <li></li>
          </ul>
        </div>
      </div>);
  };

  // const isPro = useMemo(() => {
  //   return userData.userType === 'Premium';//(userData.organizationType === 'Agency' || userData.organizationType === 'Tutoring') ? true : false;
  // }, [userData]);

  // useEffect(() => {
  //   doGetExamPaperPublic();
  // }, [searchType, searchValue]);

  useEffect(() => {
    sendGetMemberEduSubject(FETCH_DATA_EVENT.FETCH);

    //若為手機版本 直接先搜尋一次試卷
    if (!deviceData.isDesktop) {
      if (authState.context.userDetails.preference) {
        sendGetExamPaperPublic(FETCH_DATA_EVENT.FETCH, {
          payload: {
            field: 'Name',
            content: '',
            edu: authState.context.userDetails.preference.education,
            subject: authState.context.userDetails.preference.subject,
          }
        });
      } else {
        if (eduMap && eduSubjectMap) {
          sendGetExamPaperPublic(FETCH_DATA_EVENT.FETCH, {
            payload: {
              field: 'Name',
              content: '',
              edu: eduMap[0].value,
              subject: setSelectOptions(eduSubjectMap[eduMap[0].value])[0].value,
            }
          });
        }
      }
    }
  }, []);

  // searchBar 相關 -------------------------------------------------------------
  const [searchBarValue, setSearchBarValue] = useState('');

  const onSearch = () => {

  };

  const selectOptions = [
    { name: '搜試卷', value: 'Name' },
    { name: '搜命題教師', value: 'Author' }
  ];

  const onFilterParamsChange = (val) => {
    setFilterParams(val);
  };

  const onSearchParamsChange = (type, val) => {
    if (type === 'edu') {
      setSearchParams({
        ...searchParams,
        edu: val,
        subject: setSelectOptions(eduSubjectMap[val])[0].value
      });
    } else if (type === 'content') {
      setSearchParams({
        ...searchParams,
        [type]: val.target.value.trim(),
      });
    } else {
      setSearchParams({
        ...searchParams,
        [type]: val,
      });
    }
  };

  // 取得所有學制科目
  const [stateGetMemberEduSubject, sendGetMemberEduSubject] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await getMemberEduSubject(event.year);
        const { data, isSuccess, systemCode, message } = res;
        const { eduMap = [], eduSubject = {} } = data || {};

        return {
          isSuccess, systemCode, message,
          eduMap: setSelectOptions(eduMap),
          eduSubjectMap: eduSubject,
        };
      },
    },
  });
  const { eduMap, eduSubjectMap } = stateGetMemberEduSubject.context.result || {};

  // 預帶使用者上一次出卷的學制科目 如果沒有的話就預帶第一個
  useEffect(() => {
    if (authState.context.userDetails.preference) {
      setSearchParams({
        ...searchParams,
        edu: authState.context.userDetails.preference.education,
        subject: authState.context.userDetails.preference.subject,
      });
    } else {
      if (eduMap && eduSubjectMap) {
        onSearchParamsChange('edu', eduMap[0].value);
      }
    }
  }, [authState, eduMap, eduSubjectMap]);

  // 每次重新搜尋 將學年度篩選 清空
  useEffect(() => {
    setFilterParams('');
  }, [searchExamPaperRecords]);

  // --------- 手機版 相關 --------

  const goHonePage = () => {
    history.push('/');
  };

  const transformEduSubjectName = useMemo(() => {
    if (!eduMap && !eduSubjectMap) return '';
    if (searchParams.edu && searchParams.subject) {
      let eduName = '';
      let subjectName = '';
      eduMap.forEach((item) => {
        if (item.value === searchParams.edu) eduName = item.name;
      });
      eduSubjectMap[searchParams.edu].forEach((item) => {
        if (item.code === searchParams.subject) subjectName = item.name;
      });
      return eduName + subjectName;
    }
  }, [searchParams]);

  const transformRadioOption = (Array) => {
    if (!Array) return;
    return Array.map((item) => {
      return {
        label: item.name,
        value: item.value,
      };
    });
  };

  // 搜尋條件 相關

  const [searchParamsDrawerVisible, { toggle: searchParamsDrawerToggle, setFalse: searchParamsDrawerFalse }] = useBoolean(false);

  const [searchParamsDrawerData, setSearchParamsDrawerData] = useState({
    filed: '',
    edu: '',
    subject: '',
  });

  const onSearchParamsDrawerChange = (type, val) => {
    if (type === 'edu') {
      setSearchParamsDrawerData({
        ...searchParamsDrawerData,
        edu: val,
        subject: setSelectOptions(eduSubjectMap[val])[0].value
      });
    } else {
      setSearchParamsDrawerData({
        ...searchParamsDrawerData,
        [type]: val,
      });
    }
    setIsSearchParamsDrawerChange(true);
  };

  const [isSearchParamsDrawerChange, setIsSearchParamsDrawerChange] = useState(false);

  const showSearchParamsDrawer = () => {
    setSearchParamsDrawerData({
      field: searchParams.field,
      edu: searchParams.edu,
      subject: searchParams.subject,
    });
    searchParamsDrawerToggle();
    setIsSearchParamsDrawerChange(false);
  };

  const doneChangeSearchParamsDrawer = () => {
    searchParamsDrawerFalse();
    setSearchParams({
      ...searchParams,
      field: searchParamsDrawerData.field,
      edu: searchParamsDrawerData.edu,
      subject: searchParamsDrawerData.subject,
    });
  };

  // ----- 篩選 彈窗 相關 -----

  const [filterDrawerVisible, { toggle: filterDrawerToggle, setFalse: filterDrawerFalse }] = useBoolean(false);

  const [filterDrawerData, setFilterDrawerData] = useState('');
  const [isFilterDrawerChange, setIsFilterDrawerChange] = useState(false);
  const isEmptyFilterDrawer = useMemo(() => {
    return filterDrawerData === '';
  }, [filterDrawerData]);

  const onFilterDrawerChange = (val) => {
    setFilterDrawerData(val);
    setIsFilterDrawerChange(true);
  };

  const showFilterDrawer = () => {
    setFilterDrawerData(filterParams);
    filterDrawerToggle();
    setIsFilterDrawerChange(false);
  };

  const doneChangeFilterDrawer = () => {
    filterDrawerFalse();
    setFilterParams(filterDrawerData);
  };

  const filterDrawerClearAll = () => {
    if (!isEmptyFilterDrawer) {
      setFilterDrawerData('');
      setIsFilterDrawerChange(true);
    }
  };

  return (
    <StyledSearchPaperListPage className={className} data-testid="SearchPaperListPage">
      {
        deviceData.isDesktop ?
          <>
            {
              eduMap && eduSubjectMap &&
              <>
                <StyledHeader>
                  <div className='title'>搜尋</div>
                  <div className='searchBarBox'>
                    <Select defaultValue="Name" allowClear={false} options={selectOptions} value={selectOptions.value} onChange={(e) => onSearchParamsChange('field', e)}/>
                    <Select defaultValue={authState.context.userDetails.preference ? authState.context.userDetails.preference.education : eduMap[0].value} placeholder='學制' onChange={(e) => onSearchParamsChange('edu', e)} options={eduMap} value={eduMap?.value} allowClear={false}/>
                    <Select key={searchParams.edu} defaultValue={searchParams.subject} placeholder='科目' onChange={(e) => onSearchParamsChange('subject', e)} options={setSelectOptions(eduSubjectMap[searchParams.edu])} value={setSelectOptions(eduSubjectMap[searchParams.edu])?.value} allowClear={false}/>
                    <div className='searchBar'>
                      <Input
                        placeholder="請輸入搜尋的內容"
                        allowClear={true} onPressEnter={onSearch}
                        onChange={(e) => onSearchParamsChange('content', e)}
                        value={searchParams.content}
                        maxLength={30}/>
                      <Button className={'searchBtn'} variant={'brand1'} onClick={doGetExamPaperPublic}>
                        <SearchSolid/> 搜尋
                      </Button>
                      <div className={classNames('remindText', searchParams.content.length === 30 ? 'active' : '')}>({searchParams.content.length}/30)</div>
                    </div>
                  </div>
                </StyledHeader>
                <StyleContentBox>
                  {
                    searchExamPaperRecords && yearSelectOption ?
                      <>
                        <div className='amount'>
                          共 {filterRecords.length} 筆
                        </div>
                        <div className='selectBox'>
                          <Select key={searchExamPaperRecords} placeholder='學年度' onChange={onFilterParamsChange} options={yearSelectOption} value={yearSelectOption?.value} allowClear={false}/>
                        </div>
                        <Table className="tableZone">
                          <Table.Header>
                            <Table.Row isFixedWidth={true}>
                              <Table.Item width={'346px'}>試卷名稱</Table.Item>
                              <Table.Item width={'74px'}>學年度</Table.Item>
                              <Table.Item className='count' width={'86px'}>題數</Table.Item>
                              <Table.Item width={'114px'}>更新日期</Table.Item>
                              <Table.Item width={'148px'}>出卷者</Table.Item>
                              <Table.Item width={'132px'}>收藏</Table.Item>
                              <Table.Item width={'60px'}>預覽</Table.Item>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {
                              !searchIsLoading &&
                                filterRecords &&
                                filterRecords.length > 0 ?
                                sliceTableData(filterRecords, pageState.currentPage, pageState.limit).map(record => (
                                  <Table.Row key={record.uid} isFixedWidth={true}>
                                    <Table.Item width={'346px'} >{record.name}</Table.Item>
                                    <Table.Item width={'74px'} >{record.attributes.year}</Table.Item>
                                    <Table.Item className='count' width={'86px'}>{record.attributes.questionAmount}</Table.Item>
                                    <Table.Item width={'114px'}>{substringTime(record.updateTime)}</Table.Item>
                                    <Table.Item width={'148px'}>{substringName(record.maintainer)}</Table.Item>
                                    <Table.Item width={'132px'}>
                                      <Button className={classNames('likeBtn', record.isFavorite ? 'active' : '')} onClick={() => {addFavorite(record.isFavorite, record);}}>
                                        {record.isFavorite ? <HeartSolid className='heartIcon'/> : <HeartOutline className='heartIcon'/>}
                                        {record.favorites}
                                      </Button>
                                    </Table.Item>
                                    <Table.Item width={'60px'}>
                                      <ExternalLinkAltSolid className='previewBtn' onClick={() => {goToExamPage(record.uid);} }/>
                                    </Table.Item>
                                  </Table.Row>
                                )) : <div className='tableEmpty'>
                                  <div>
                                    <span>找不到相符的結果</span>
                                    <span>試試改變搜尋關鍵字或篩選條件吧！</span>
                                  </div>
                                  <div className='imageBox'>
                                    <img src='/assets/tableEmpty.png' alt='tableEmpty'></img>
                                  </div>
                                </div>
                            }
                            {
                              searchIsLoading &&
                              skeletonArray.map( item => (
                                <Table.Row key={'Skeleton'} isFixedWidth={true}>
                                  <Table.Item width={'346px'} ><Skeleton/></Table.Item>
                                  <Table.Item width={'74px'} ><Skeleton/></Table.Item>
                                  <Table.Item width={'86px'}><Skeleton/></Table.Item>
                                  <Table.Item width={'114px'}><Skeleton/></Table.Item>
                                  <Table.Item width={'148px'}><Skeleton/></Table.Item>
                                  <Table.Item width={'132px'}><Skeleton/></Table.Item>
                                  <Table.Item width={'60px'}><Skeleton/></Table.Item>
                                </Table.Row>
                              ))
                            }
                          </Table.Body>
                          {
                            !searchIsLoading &&
                            <div className="pageBlock">
                              <Pagination
                                total={filterRecords?.length || 0}
                                defaultCurrent={1}
                                current={pageState.currentPage}
                                onChange={onPageChange}
                                defaultPageSize={pageState.limit}
                              />
                            </div>
                          }
                        </Table>
                      </>
                      :
                      <div className='notYetSearchBox'>
                        <div>
                          <span>探索海量試卷與優質試題</span>
                          <span>獨家試卷，全科目皆支援</span>
                          <span>嚴格把關試題質量、名師審題解析</span>
                        </div>
                        <div>
                          <img src={'/assets/searchExam.svg'} alt={'noYetSearchImg'}/>
                        </div>
                      </div>
                  }
                </StyleContentBox>
              </>
            }
          </>
          // ------- 手機版 -------
          :
          <>
            {
              authState && transformEduSubjectName &&
              <>
                <div className='mobileSearchHeader'>
                  <ChevronLeftOutline onClick={goHonePage}/>
                  <div className='filterTitle'>
                    {searchParams.field === 'Name' ? '試卷' : '出卷者'}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {transformEduSubjectName}
                    <SortDownSolid onClick={showSearchParamsDrawer}/>
                  </div>
                </div>
                <div className='searchBarBox'>
                  <Input.Search
                    placeholder="請輸入搜尋的內容"
                    allowClear={false}
                    onPressEnter={onSearch}
                    onChange={(e) => onSearchParamsChange('content', e)}
                    value={searchParams.content}
                    maxLength={30}
                    onSearch={doGetExamPaperPublic}
                  />
                  <div className={classNames('remindText', searchParams.content.length === 30 ? 'active' : '')}>({searchParams.content.length}/30)</div>
                </div>
                <div className='filterBox'>
                  <div className='amount'>共 {filterRecords.length} 筆</div>
                  <SlidersHSolid onClick={showFilterDrawer}/>
                </div>
                <div className='tableData'>
                  {
                    !searchIsLoading &&
                      filterRecords &&
                      filterRecords.length > 0 ?
                      sliceTableData(filterRecords, pageState.currentPage, pageState.limit).map(record => (
                        <StyledMobileItem onClick={(e) => {e.stopPropagation();goToExamPage(record.uid);} }>
                          <div>{record.name}</div>
                          <div>{record.attributes.year}學年度．{record.attributes.questionAmount}題．{substringName(record.maintainer)}</div>
                          <div>
                            <div>收藏數  ({record.favorites})</div>
                            {
                              record.isFavorite ?
                                <HeartSolid className='heartIcon' onClick={(e) => {e.stopPropagation();addFavorite(record.isFavorite, record);}}/>
                                :
                                <HeartOutline className='heartIcon' onClick={(e) => {e.stopPropagation();addFavorite(record.isFavorite, record);}}/>
                            }
                          </div>
                        </StyledMobileItem>
                      )) : <div className='tableEmpty'>
                        <div>
                          <span>找不到相符的結果</span>
                          <span>試試改變搜尋關鍵字或篩選條件吧！</span>
                        </div>
                        <div className='imageBox'>
                          <img src='/assets/tableEmpty.png' alt='tableEmpty'></img>
                        </div>
                      </div>
                  }
                  <div className="pageBlock">
                    <Pagination
                      total={filterRecords?.length || 0}
                      defaultCurrent={1}
                      current={pageState.currentPage}
                      onChange={onPageChange}
                      defaultPageSize={pageState.limit}
                    />
                  </div>
                </div>
              </>
            }
            <Drawer
              visible={searchParamsDrawerVisible}
              title={'搜尋條件'}
              onClose={searchParamsDrawerFalse}
              placement={'bottom'}
              isMobile={true}
              id={'searchParamsDrawer'}
              className={'searchParamsDrawer'}
              footer={
                <>
                  <Button
                    // variant={'brand2'}
                    variant={!isSearchParamsDrawerChange ? 'ui02' : 'brand2'}
                    disabled={!isSearchParamsDrawerChange}
                    onClick={doneChangeSearchParamsDrawer}
                  >
                    使用
                  </Button>
                </>
              }
            >
              <div className='selectFieldBox'>
                <div className='title'>類別</div>
                <RadioGroup
                  key={'selectOptions'}
                  name={'selectOptions'}
                  className='radioList' buttonStyle='solid'
                  optionType='button'
                  options={transformRadioOption(selectOptions)}
                  value={searchParamsDrawerData.field}
                  onChange={(e) => {onSearchParamsDrawerChange('field', e.target.value);}}
                />
              </div>
              <div className='title'>學制科目</div>
              <div>
                <div className='subTitle'>學制</div>
                <RadioGroup
                  key={'eduOptions'}
                  name={'eduOptions'}
                  className='radioList' buttonStyle='solid'
                  optionType='button'
                  options={transformRadioOption(eduMap)}
                  value={searchParamsDrawerData.edu}
                  onChange={(e) => {onSearchParamsDrawerChange('edu', e.target.value);}}
                />
              </div>
              {
                searchParamsDrawerData.edu && eduSubjectMap &&
                <div>
                  <div className='subTitle'>科目</div>
                  <RadioGroup
                    className='radioList'
                    buttonStyle='solid'
                    optionType='button'
                    value={searchParamsDrawerData.subject}
                    onChange={(e) => {onSearchParamsDrawerChange('subject', e.target.value);}}
                  >
                    {
                      transformRadioOption(setSelectOptions(eduSubjectMap[searchParamsDrawerData.edu])) &&
                      transformRadioOption(setSelectOptions(eduSubjectMap[searchParamsDrawerData.edu])).map(item => (
                        <Radio.Button
                          key={item.value}
                          className={item.label.length > 5 ? 'longerRadio' : ''}
                          value={item.value}>{item.label}
                        </Radio.Button>
                      ))
                    }
                  </RadioGroup>
                </div>
              }
            </Drawer>
            <Drawer
              visible={filterDrawerVisible}
              title={
                <>
                  <span>篩選</span>
                  <div className={classNames('clearAllFilterBtn', !isEmptyFilterDrawer ? 'active' : '' )}
                    onClick={filterDrawerClearAll}>清除
                  </div>
                </>
              }
              onClose={filterDrawerFalse}
              placement={'bottom'}
              isMobile={true}
              id={'filterDrawer'}
              className={'filterDrawer'}
              footer={
                <>
                  <Button
                    variant={!isFilterDrawerChange ? 'ui02' : 'brand2'}
                    disabled={!isFilterDrawerChange}
                    onClick={doneChangeFilterDrawer}
                  >
                    使用
                  </Button>
                </>
              }
            >
              <div className='selectFieldBox'>
                <div className='title'>學年度</div>
                <RadioGroup
                  key={'yearSelectOption'}
                  name={'yearSelectOption'}
                  className='radioList' buttonStyle='solid'
                  optionType='button'
                  options={transformRadioOption(yearSelectOption)}
                  value={filterDrawerData}
                  onChange={(e) => {onFilterDrawerChange(e.target.value);}}
                />
              </div>
            </Drawer>
          </>
      }
    </StyledSearchPaperListPage>
  );
};