/* eslint-disable no-console */
import axios from 'axios';
import { checkLogin } from '../../utils/common';
import { COOKIE_LOGIN_TOKEN_NAME } from '../../constants/index';

const formatParams = (data) => {
  Object.entries(data).forEach(([key, value]) => {
    if (value === '' || value.length === 0) {
      delete data[key];
    }
  });
  return data;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN,
  headers: {
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    Authorization: checkLogin(COOKIE_LOGIN_TOKEN_NAME) || null,
    'Content-Type': 'application/json',
  },
  timeout: (1000 * 60)
});

instance.interceptors.request.use(
  (config) => {
    config.headers = {
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
      Authorization: checkLogin(COOKIE_LOGIN_TOKEN_NAME) || null,
      'Content-Type': 'application/json',
      ...config.headers,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    if (!response.state === 'success') Promise.reject(response.message);
    return response;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 401:
          err.response = 'Token 過期,請重新登入';
          console.log('token怪怪');
          break;
        case 404:
          err.response = `${err.response.status}:敏ＣＡ夢  995!!`;
          console.log('沒有這個拉！');
          break;
        case 500:
          err.response = `${err.response.status}:敏ＣＡ夢  995!!`;
          console.log('伺服器錯了哦！');
          break;
        case 503:
          err.response = `${err.response.status}:敏ＣＡ夢  995!!`;
          console.log('不想服務拉！');
          break;
        default:
          err.response = `${err.response.status}:敏ＣＡ夢  995!!`;
          console.log(`連結錯誤${err.response.status}`);
          break;
      }
    } else {
      // 如果 因為 timeout 將會導向去 維護頁面
      window.location.href = '/maintenancePage';
      err.response = '敏ＣＡ夢  995!!';
      console.log('連結錯誤', err.response);
    }
    return Promise.reject(err.response);
  },
);

export const post = (url, data = {}, config) => {
  return new Promise((resolve, reject) => {
    instance.post(url, formatParams(data), config).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      },
    );
  });
};

const api = {};

api.create = () => ({
  post: (url, data, config) => post(url, data, config),
});

export const loginApi = api.create();