import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useBoolean, useClickAway } from 'ahooks';
import { FrontLayout, Dropdown, AuthProvider, ThemeProvider, Modal, CreateExamPaperParamsProvider } from '@oneclass/ui-components';
import { OneTestHeader } from 'containers';
import { Button } from '@oneclass/onedesign';
import RenderRoutes from 'routes/RenderRoutes';
import { UserSolid, UserCogSolid, BackstageManagementSolid, SignOutSolid, XmarkOutline, ChevronDownToBottomSolid, ChevronUpToTopSolid, SearchSolid, FolderOpenSolid } from '@onedesign/icon';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { useSize } from 'ahooks';
import { StyledMainLayout, UiUserBox, StyledFooterModal, StyledMenuBox, StyledPageHeader } from './MainLayout.style';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { getAllRemoteConfig } from 'utils/firebaseRemoteConfig.js';
import { MaintenancePage } from 'views';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { default as firebaseRemoteConfigProvider } from 'providers/firebaseRemoteConfigProvider.js';

const version = process.env.REACT_APP_VERSION;

const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};

const goBottom = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = main.scrollHeight;
};

export const MainLayout = ({ className, routes = [] }) => {
  // const { isMobile } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { remoteConfigData, RemoteConfigDataProviderChange } = useContext(firebaseRemoteConfigProvider.firebaseRemoteConfigProviderContext);
  const history = useHistory();
  const env = process.env.REACT_APP_NODE_ENV;
  const { useAuthDispatch, useAuthState } = AuthProvider;
  const {
    logout,
    settingEduSubject
  } = useAuthDispatch();
  const authState = useAuthState();
  const location = useLocation();
  const [state, { toggle, setFalse }] = useBoolean(false);
  const [modalVisible, { toggle: setModalToggle, setFalse: setImportModalFalse }] = useBoolean(false);
  const [copyrightModalVisible, { toggle: copyrightModalToggle, setFalse: copyrightModalFalse }] = useBoolean(false);
  const drawRef = useRef();
  const modalRef = useRef();
  const [eduSubject, setEduSubject] = useState({});
  const [scrollTop, setScrollTop] = useState(0);
  const [userData, setUserData] = useState({
    identity: '',
    organizationType: ''
  });
  const { renderEduSubjectModal, setEduSubjectModalToggle, setEduSubjectModalFalse } = useEduSubjectModal(authState.context.userDetails?.preference, 'mainLayout', location.pathname);
  const layoutRef = useRef();
  const size = useSize(layoutRef);
  useClickAway(() => {
    setFalse(); }, drawRef);
  useClickAway(() => {
    setImportModalFalse(); }, modalRef);

  const generalThemeArray = ['SchoolTeacher', 'LinkSchoolTeacher', 'NaniEditor'];
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [configData, setConfigData] = useState({});
  // const [isRepairing, setIsRepairing] = useState(true);

  useEffect(() => {
    if ((authState.value === 'loggedIn' || authState.value === 'settingEduSubject')) {
      if (authState.context.userDetails?.userStatus) {
        const { onePaperIdentity } = authState.context.userDetails;
        const { organization, identity, usetype, name } = authState.context.userDetails?.userStatus;
        setUserData({
          organizationType: organization.type,
          userType: generalThemeArray.indexOf(onePaperIdentity) !== -1 ? 'General' : 'Premium',
          identity,
          name
        });
      }
    }
  }, [authState.value]);

  const scrollPosition = useMemo(() => {
    const main = document.getElementById('mainLayout');
    if (scrollTop === 0) return 'top';
    if (main.offsetHeight + scrollTop >= main.scrollHeight) return 'bottom';
    return 'middle';
  }, [scrollTop]);

  const isPro = useMemo(() => {
    return userData.userType === 'Premium';//(userData.organizationType === 'Agency' || userData.organizationType === 'Tutoring') ? true : false;
  }, [userData]);

  const isTest = useMemo(() => {
    return userData.organizationType === 'Tutoring';
  }, [userData]);

  const goToSearchPage = () => {
    history.push('/SearchPaperListPage');
  };

  const addNowPageClassName = useMemo(() => {
    switch (location.pathname) {
      case '/login' :
        return 'loginPage';
      case '/computerQuestion' :
      case '/manualProposition' :
      case '/knowledgeProposition' :
      case '/studentIntelligentProposition' :
        return 'questioningProcess';
      case '/examPaperList' :
      case '/studentExamPaperList' :
        return 'examPaperList';
      case '/' :
        return 'homePage';
      case '/SearchPaperListPage' :
        return 'SearchPaperListPage';
      default :
        return '';
    }
  }, [location]);

  useEffect(() => {
    const main = document.getElementById('mainLayout');
    main.addEventListener('scroll', (e) => {
      setScrollTop(e.target.scrollTop);
    });
  }, []);

  // 針對手機 瀏覽器 上下方工具列 導致vh變化 做調整
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);

  // 取得 firebase RemoteConfig 資料
  const getConfigData = async() => {
    const data = await getAllRemoteConfig();
    setIsLoading(false);
    setConfigData(data);
  };

  const isRepairing = useMemo(() => {
    if (!configData || JSON.stringify(configData) === '{}') return;
    return JSON.parse(configData?.maintenancePage?._value)?.isRepairing;
  }, [configData]);

  useEffect(() => {
    if (!configData || JSON.stringify(configData) === '{}') return;
    RemoteConfigDataProviderChange(configData);
  }, [configData]);

  // 隨時監控 螢幕寬度並改變 全域參數
  const getScreenWidth = () => {
    const width = document.body.clientWidth;
    deviceDataProviderChange('screenWidth', width);
  };

  useEffect(() => {
    getConfigData();

    window.addEventListener('resize', getScreenWidth);
    return () => {
      window.removeEventListener('resize', getScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (!isRepairing) return;
    if (isRepairing) {
      history.push('/maintenancePage');
    }
  }, [isRepairing]);

  return (
    <DndProvider backend={!deviceData.isDesktop ? TouchBackend : HTML5Backend}>
      <ThemeProvider isPro={isPro}>
        <StyledMainLayout
          className={className}
          id='mainLayout'
          data-testid="MainLayout"
          ref={layoutRef}
          isTest={isTest}
          pathname={location.pathname}
        >
          {
            isLoading
              ?
              renderLoadingContent()
              :
              <>
                <div className='scrollBtnZone'>
                  {
                    (scrollPosition === 'bottom' || scrollPosition === 'middle') &&
                  <div
                    className="scrollBtn"
                    onClick={() => {
                      goTop();
                    }}>
                    <ChevronUpToTopSolid />
                  </div>
                  }
                  {
                    (scrollPosition === 'top' || scrollPosition === 'middle') &&
                  <div
                    className="scrollBtn"
                    onClick={() => {
                      goBottom();
                    }}>
                    <ChevronDownToBottomSolid />
                  </div>
                  }
                </div>
                <FrontLayout
                  className={addNowPageClassName}
                  header={

                    <>
                      {
                        userData?.organizationType ?
                          <>
                            {
                              isTest ?
                                <>
                                  <OneTestHeader
                                    logout={logout}
                                    userData={userData}
                                    authStateValue={authState.value}
                                    env={env}
                                    history={history}
                                    openEduSubjectModalToggle={setEduSubjectModalToggle}
                                  />
                                  {renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)}
                                </>
                                :
                                <>
                                  {
                                    location.pathname === '/login' ? '' :
                                      <StyledPageHeader isPro={isPro} nowheight={size.height || null}>
                                        {
                                          isTest ?
                                            <div></div>
                                            :
                                            <div className="logo" onClick={() => {history.push('./');}}>
                                              <img src={`/assets/${isPro ? 'logoPro.png' : 'logoNani.svg'}`} alt='logo' />
                                              {
                                                isPro &&
                                                <div className='logoProText'>專業版</div>
                                              }
                                            </div>
                                        }
                                        <div className="headerRightBox">
                                          <div className='pcHeader'>
                                            {
                                              env === 'dev' &&
                                              <div className="searchBtn" onClick={goToSearchPage}>
                                                <SearchSolid></SearchSolid>搜尋
                                              </div>
                                            }
                                            {
                                              (userData.identity !== 'Student' && location.pathname !== '/' && location.pathname !== '/computerQuestion' && location.pathname !== '/manualProposition' && location.pathname !== '/knowledgeProposition') &&
                                              <>
                                                <Button borderRadius='24px' variant={isPro ? 'linearGradient' : 'brand1'} className="makeATextPaperBtn" onClick={setEduSubjectModalToggle}>
                                                    我要出卷
                                                </Button>
                                                {renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)}
                                              </>
                                            }
                                            {
                                              (location.pathname === '/login' || isTest) ? '' :
                                                <Dropdown className="userAvatar"
                                                  list={
                                                    [
                                                      (userData.identity === 'Admin' || userData.identity === 'Editor') &&
                                              { name: '後台管理', icon: <BackstageManagementSolid />, onClick: () => {window.location.href = `https://onepaper-cms${env !== 'release' ? `-${env}` : ''}.oneclass.com.tw/`;} },
                                                      userData.identity === 'Student' ?
                                                        { name: '我的練習卷', icon: <FolderOpenSolid/>, onClick: () => {history.push('studentExamPaperList');} } :
                                                        { name: '試卷列表', icon: <FolderOpenSolid/>, onClick: () => {history.push('examPaperList');} },
                                                      { name: '個人設定', icon: <UserCogSolid />, onClick: () => {history.push('memberCenter');} },
                                                      { name: '登出', icon: <SignOutSolid />, onClick: () => {logout();} }
                                                    ]}>
                                                  <UiUserBox>
                                                    <UserSolid />
                                                    {authState.context.userDetails?.userStatus.name || ''}
                                                  </UiUserBox>
                                                </Dropdown>
                                            }
                                          </div>
                                        </div>
                                        <div className='mobileHeader'>
                                          <input id="nav-toggle" type="checkbox" ></input>
                                          <label htmlFor="nav-toggle" className="ham">
                                            <div className="ham-origin">
                                              <div className="ham-bar ham-bar--top"></div>
                                              <div className="ham-bar ham-bar--middle"></div>
                                              <div className="ham-bar ham-bar--bottom"></div>
                                            </div>
                                          </label>
                                          <StyledMenuBox>
                                            <div className='menuBtnBox'>
                                              {
                                                (userData.identity !== 'Student' && location.pathname !== '/' && location.pathname !== '/computerQuestion' && location.pathname !== '/manualProposition' && location.pathname !== '/knowledgeProposition') &&
                                                <>
                                                  <Button borderRadius='24px' variant={isPro ? 'linearGradient' : 'brand1'} className="makeATextPaperBtn" onClick={setEduSubjectModalToggle}>
                                                    我要出卷
                                                  </Button>
                                                  {renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)}
                                                </>
                                              }
                                              {
                                                env === 'dev' &&
                                                <Button textcolor={isPro ? '#fff' : '#000'} type='solid' borderRadius='24px' className="makeATextPaperBtn searchBtn" onClick={goToSearchPage}>
                                                  <SearchSolid></SearchSolid>搜尋
                                                </Button>
                                              }
                                            </div>
                                            <ul className="menu">
                                              <li className='mobileAvatar'><UserSolid />{authState.context.userDetails?.userStatus?.name || ''}</li>
                                              {
                                                (userData.identity === 'Admin' || userData.identity === 'Editor') &&
                                          <li>
                                            <Link to={`https://onepaper-cms${env !== 'release' ? `-${env}` : ''}.oneclass.com.tw/`}>
                                              <BackstageManagementSolid/>後台管理
                                            </Link>
                                          </li>
                                              }
                                              {
                                                userData.identity === 'Student' ?
                                                  <li>
                                                    <Link to={'studentExamPaperList'}>
                                                      <FolderOpenSolid/>我的練習卷
                                                    </Link>
                                                  </li>
                                                  :
                                                  <li>
                                                    <Link to={'examPaperList'}>
                                                      <FolderOpenSolid/>試卷列表
                                                    </Link>
                                                  </li>
                                              }
                                              <li>
                                                <Link to={'memberCenter'}>
                                                  <UserCogSolid/>個人設定
                                                </Link>
                                              </li>
                                              <li>
                                                <div onClick={() => {logout(userData?.organizationType, `https://onetest${env !== 'release' ? `-${env}` : ''}.oneclass.com.tw/`);}}>
                                                  <SignOutSolid/>登出
                                                </div>
                                              </li>
                                            </ul>
                                            <div className='menuBackground'></div>
                                          </StyledMenuBox>

                                        </div>

                                      </StyledPageHeader>
                                  }
                                </>
                            }
                          </>
                          :
                          <></>
                      }
                    </>
                  }
                  footer={
                    <>
                      <div className='footerLeft'>
                        {
                          !isTest &&
                          <div>
                            <div><a href="https://trans.nani.com.tw/NaniTeacher/">南一教師網</a></div>
                            <div><a href="https://www.oneclass.com.tw/digi/index.html">OneClass</a></div>
                            <div><a href="https://tks.nani.com.tw/">TKS 雲端出題系統（舊版）</a></div>
                          </div>
                        }
                        <div>© NANI BOOK ENTERPRISE CO.,LTD. All Rights Reserved. v{version || '1.1.1'}</div>
                      </div>
                      <div className="browserSuggestAll" ref={modalRef}>
                        <div className="browserSuggest" onClick={() => {copyrightModalToggle();}}>版權聲明</div>
                        <div className="browserSuggest" onClick={() => {setModalToggle();}}>瀏覽器建議</div>

                        <StyledFooterModal
                          isShow={modalVisible}
                        >
                          <div className="modalContent">
                            <div className="title"><span>瀏覽器建議</span> <div className="close" onClick={() => {setImportModalFalse();}}><XmarkOutline/></div></div>

                            <div>為了讓您獲得最佳瀏覽體驗，建議您使用的瀏覽器軟體為：IE Edge、Google Chrome 或 Firefox。</div>
                            <div>如使用IE瀏覽器瀏覽本網站，可能會發生部分網頁的呈現會有破圖或版面跑版的情況，亦可能導致某些網頁互動功能無法正確顯示與使用。</div>
                          </div>
                        </StyledFooterModal>

                        <Modal
                          id={'copyrightModal'}
                          visible={copyrightModalVisible}
                          zIndex={1000}
                          onCancel={copyrightModalFalse}
                          width={!deviceData.isDesktop ? '100%' : '600px'}
                          title={'版權聲明'}
                        >
                          <div className='modalContent'>
                            {
                              userData.organizationType !== 'Tutoring' ?
                                <>
                                  <div>南一雲端出題之內容包括但不限於內容、文字、圖片、聲音、影像、檔案、網站架構、軟體及程式或未來各種形式之利用及衍生著作均為南一書局企業股份有限公司所有或已獲得版權持有者授權使用，並受國際著作權公約以及中華民國法律保護。</div>
                                  <div>非經南一書局企業股份有限公司明示之授權或同意，任何人均不得做全部或一部之嵌入、重製、使用、修改、公開播送、公開傳輸、公開演出、改作、引用、散布、發行、公開發表、進行還原工程、解編、出售、出租、互易、出借、或反向組譯等透過網路及其他方式對外公佈。</div>
                                  <div>一切其他涉嫌商業利益之公開展示、公開傳輸、出租、出售、互易或任何部份內容之重製，均屬違法之侵權行為，南一書局企業股份有限公司基於維護著作權益之精神，必追究到底，絕無寬待。</div>
                                </>
                                :
                                <>
                                  <div>OnePaper之內容包括但不限於內容、文字、圖片、聲音、影像、檔案、網站架構、軟體及程式或未來各種形式之利用及衍生著作均為南一書局企業股份有限公司所有或已獲得版權持有者授權使用，並受國際著作權公約以及中華民國法律保護。</div>
                                  <div>非經南一書局企業股份有限公司明示之授權或同意，任何人均不得做全部或一部之嵌入、重製、使用、修改、公開播送、公開傳輸、公開演出、改作、引用、散布、發行、公開發表、進行還原工程、解編、出售、出租、互易、出借、或反向組譯等透過網路及其他方式對外公佈。</div>
                                  <div>一切其他涉嫌商業利益之公開展示、公開傳輸、出租、出售、互易或任何部份內容之重製，均屬違法之侵權行為，南一書局企業股份有限公司基於維護著作權益之精神，必追究到底，絕無寬待。</div>
                                </>
                            }
                          </div>
                        </Modal>
                      </div>
                    </>
                  }
                >
                  {
                    (((authState.value === 'loggedIn' || authState.value === 'settingEduSubject') ) || location.pathname === '/login' || location.pathname === '/maintenancePage') &&
                  <RenderRoutes routes={routes} eduSubject={{ edu: eduSubject?.edu?.code, subject: eduSubject?.subject?.code }}/>
                  }
                </FrontLayout>
              </>
          }
        </StyledMainLayout>

      </ThemeProvider>
    </DndProvider>
  );
};

MainLayout.propTypes = {
  className: PropTypes.string,
  routes: PropTypes.array,
};
