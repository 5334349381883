import { assign, createMachine } from 'xstate';
import { setCookie, deleteAllCookies, getCookie } from '../utils/common';
import { COOKIE_LOGIN_TOKEN_NAME } from '../constants';
import { logoutNaniOneClass, checkNaniLinkLogin } from 'services/oneClub';
import { loginByOneClub, loginFrontEndByOneClub } from '../api/login';
import { getMemberTypeSetting, getMemberInformation, getMemberParameter } from 'api/member';

export const AuthenticationMachineState = {
  checkingIfLoggedIn: 'checkingIfLoggedIn',
  checkIfLoggedIn: 'checkIfLoggedIn',
  loggedIn: 'loggedIn',
  loggedOut: 'loggedOut',
  loginingExamKeeper: 'loginingExamKeeper',
  loginExamKeeper: 'loginExamKeeper',
  settingEduSubject: 'settingEduSubject'

};

export const AuthenticationMachineEvent = {
  REPORT_IS_LOGGED_IN: 'REPORT_IS_LOGGED_IN',
  REPORT_IS_LOGGED_OUT: 'REPORT_IS_LOGGED_OUT',
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  SET_EDU_SUBJECT: 'SET_EDU_SUBJECT'
};

const authenticationMachine = createMachine(
  {
    id: 'authentication',
    initial: AuthenticationMachineState.checkingIfLoggedIn,
    context: { apiBaseUrl: '', navigatorAuth: () => {} },
    states: {
      [AuthenticationMachineState.checkingIfLoggedIn]: {
        invoke: {
          src: AuthenticationMachineState.checkIfLoggedIn,
          onError: {
            target: AuthenticationMachineState.loggedOut,
          },
        },
        on: {
          [AuthenticationMachineEvent.REPORT_IS_LOGGED_IN]: {
            target: AuthenticationMachineState.loginingExamKeeper,
          },
          [AuthenticationMachineEvent.REPORT_IS_LOGGED_OUT]: AuthenticationMachineState.loggedOut,
        },
      },
      [AuthenticationMachineState.loginingExamKeeper]: {
        invoke: {
          src: AuthenticationMachineState.loginExamKeeper,
          onError: {
            target: AuthenticationMachineState.loggedOut,
          },
        },
        on: {
          [AuthenticationMachineEvent.LOG_IN]: {
            target: AuthenticationMachineState.loggedIn,
            actions: 'assignUserDetailsToContext',
          },
          [AuthenticationMachineEvent.REPORT_IS_LOGGED_OUT]: AuthenticationMachineState.loggedOut,
        },
      },
      [AuthenticationMachineState.loggedIn]: {
        entry: ['clearErrorMsgFromContext'],
        on: {
          [AuthenticationMachineEvent.SET_EDU_SUBJECT]: {
            target: AuthenticationMachineState.settingEduSubject,
            // actions: 'changeEduSubject'
          },
          [AuthenticationMachineEvent.LOG_OUT]: {
            target: AuthenticationMachineState.loggedOut,
          },
        },
      },
      [AuthenticationMachineState.settingEduSubject]: {
        on: {
          [AuthenticationMachineEvent.SET_EDU_SUBJECT]: {
            target: AuthenticationMachineState.settingEduSubject,
            actions: 'changeEduSubject'
          },
          [AuthenticationMachineEvent.LOG_OUT]: {
            target: AuthenticationMachineState.loggedOut,
          },
        },
      },
      [AuthenticationMachineState.loggedOut]: {
        entry: ['navigateToAuthPage', 'clearUserDetailsFromContext', 'assignErrorMsgToContext'],
        on: {
          [AuthenticationMachineEvent.LOG_IN]: {
            target: AuthenticationMachineState.loginingExamKeeper
          },
        },
      },
    },
  },
  {
    services: {
      checkIfLoggedIn: () => async (
        send,
      ) => {
        const cookieGroup = checkNaniLinkLogin();
        if (cookieGroup) {
          send({
            type: 'REPORT_IS_LOGGED_IN'
          });
        } else {
          send({
            type: 'REPORT_IS_LOGGED_OUT',
          });
        }
      },
      loginExamKeeper: (context) => async(send) => {
        const naniToken = checkNaniLinkLogin() || '';
        let result = {};
        if (location.port === '3300' || context.apiBaseUrl.indexOf('cms') !== -1) {
          result = await loginByOneClub({
            url: context.apiBaseUrl,
            jwt: naniToken ? JSON.parse(naniToken).jwt : JSON.parse(event.jwt).jwt,
          });
        } else {
          result = await loginFrontEndByOneClub({
            url: context.apiBaseUrl,
            jwt: naniToken ? JSON.parse(naniToken).jwt : JSON.parse(event.jwt).jwt,
            serviceRoot: getCookie('nani_oneclass_serviceRoot') !== null ? getCookie('nani_oneclass_serviceRoot') : 'OnePaper',
            organizationId: getCookie('nani_oneclass_onelink_organizationId') !== null ? getCookie('nani_oneclass_onelink_organizationId') : null,
          });
        }

        const { data, isSuccess, message } = result;

        if (isSuccess) {
          if (location.port === '3300' || context.apiBaseUrl.indexOf('cms') !== -1) {
            // 後台
            const { token } = data;
            setCookie(COOKIE_LOGIN_TOKEN_NAME, token, 1);
            send({
              type: 'LOG_IN',
              userDetails: {
                ...data
              },
            });
          } else {
            // 前台
            if (getCookie('nani_oneclass_serviceRoot') === null) setCookie('nani_oneclass_serviceRoot', 'OnePaper', 1);
            const { onePaperToken } = data;
            setCookie(COOKIE_LOGIN_TOKEN_NAME, onePaperToken, 1);
            const res = await getMemberInformation();
            const { data: memberData, isSuccess: isSuccessMemberData, message: messageMemberData } = res;
            const { account, email, name, userStatus, uid, oneClubUID } = memberData;
            const parameter = await getMemberParameter();
            const { data: parameterData } = parameter;
            if (isSuccessMemberData) {
              send({
                type: 'LOG_IN',
                userDetails: {
                  ...data,
                  userStatus: {
                    ... userStatus,
                    account,
                    email,
                    name,
                    uid,
                    oneClubUID,
                  }
                },
                parameterData,
              });
            }
          }
        } else {
          send({
            type: 'REPORT_IS_LOGGED_OUT',
            errorMsg: message || '權限不足或未知的錯誤'
          });
        }
      },
    },
    actions: {

      navigateToAuthPage: (context, event) => {
        const { navigatorAuth } = context;
        logoutNaniOneClass();
        deleteAllCookies();
        if (event.organizationType === 'Tutoring') {
          window.location.href = event.redirectUrl;
        } else {
          navigatorAuth();
        }
      },
      assignUserDetailsToContext: assign((context, event) => {
        if (event.type !== AuthenticationMachineEvent.REPORT_IS_LOGGED_IN && event.type !== AuthenticationMachineEvent.LOG_IN) {
          return {};
        }
        return {
          userDetails: event.userDetails,
          parameterData: event.parameterData,
        };
      }),
      clearUserDetailsFromContext: assign({}),
      assignErrorMsgToContext: assign((context, event) => {
        // if (event.type !== AuthenticationMachineEvent.REPORT_IS_LOGGED_OUT && event.type !== AuthenticationMachineEvent.LOG_OUT) {
        //   return {};
        // }
        return {
          errorMsg: event.errorMsg,
        };
      }),
      clearErrorMsgFromContext: assign({
        errorMsg: undefined,
      }),
      changeEduSubject: assign((context, event) => {
        return {
          userDetails: {
            ...context.userDetails,
            ...context.parameterData,
            preference: {
              education: event.education ? event.education : '',
              subject: event.subject ? event.subject : ''
            }
          }
        };
      })
    },

  },
);

export default authenticationMachine;
