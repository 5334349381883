/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useContext } from 'react';
import { MpAreaSelectContent, MpQuestionSelectContent, MpQuestionReviewContent, MpLayoutOptionContent, HomeQuestionCompleteContent } from 'containers';
import {
  StepBox,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  FETCH_DATA_STATE,
  Modal,
  AuthProvider,
  CreateExamPaperParamsProvider,
  MobileStepBox,
  EditCopyExamPaperProvider,
  openNotificationWithIcon,
} from '@oneclass/ui-components';
import { ExamPaperHeader } from 'components';
import { Button } from '@oneclass/onedesign';
import { ExclamationCircleSolid } from '@onedesign/icon';
import { useMachine } from '@xstate/react';
import { putEditExamPaper, postExamCreate, postExamEdit } from 'api/examPaper';
import { postExportPaper } from 'api/exportPaper';
import { formateQuestionList, setCookie, getCookie } from 'utils/common';
import { useBoolean } from 'ahooks';
import { useHistory } from 'react-router-dom';
import { StyledManualPropositionPage, StyledManualPropositionPageContent, UiBtnZone } from './ManualPropositionPage.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { postMemberTypeSetting } from 'api/member';
import { postGetQuestionGroup } from 'api/query';
import { OUTPUT_TYPE_NAME } from 'constants/index';

const steps = [
  {
    title: '範圍選擇',
  },
  {
    title: '試題選擇',
  },
  {
    title: '出題預覽',
  },
  {
    title: '版面配置',
  },
  {
    title: '出卷完成',
  },
];

const defaultCountInputValue = {
  answer: '',
  question: '',
  score: {
    perQues: '',
    perAns: '',
    allScore: 0,
  },
  difficulty: {
    BEGIN: '',
    INTERMEDIATE: '',
    EXPERT: '',
  }
};
const formateTableData = (data) => {
  if (!data) return null;
  let nextData = {};
  Object.entries(data).forEach(([key, value], index) => {
    nextData = {
      ...nextData,
      [key]: {
        ...value,
        inputValue: defaultCountInputValue
      }
    };
  });
  return nextData;
};
const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};
const initSaveData = {
  education: '',
  subject: '',
  areaParams: {},
  quesReviewQuestionRenderList: {},
  createExamPaperParams: {},
  tableData: {},
  examUID: ''
};

export const ManualPropositionPage = ({ className }) => {
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { editCopyExamPaperData, resetCopyEditPaperParams } = useContext(EditCopyExamPaperProvider.EditCopyExamPaperContext);
  const history = useHistory();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const { createExamPaperParams: globalExamPaperData } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const [saveData, setSaveData] = useState({ ...initSaveData });
  const [areaParams, setAreaParams] = useState({});
  const [sendParams, setSendParams] = useState({});
  const [resetToggle, setResetToggle] = useState(false);
  const [keyList, setKeyList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [step, setStep] = useState(1);
  const [checkedSend, setCheckedSend] = useState([]);
  const [jumpStep, setJumpStep] = useState(null);
  const [createExamPaperParams, setCreateExamPaperParams] = useState({});
  const [questions, setQuestions] = useState([]);
  const [tableData, setTableData] = useState({});
  const [totalQuesTypeData, setTotalQuesTypeData] = useState({});
  const [scoreModalVisible, { toggle: setScoreModalToggle, setFalse: setScoreModalFalse }] = useBoolean(false);
  const [areaModalVisible, { toggle: setAreaModalToggle, setFalse: setAreaModalFalse }] = useBoolean(false);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();
  const [reCreateExam, setReCreateExam] = useState(false);
  const [reCreateSearchParams, setReCreateSearchParams] = useState({});
  const [isJumpToStep2, setIsJumpToStep2] = useState(false);
  const [emptyTextModalVisible, { toggle: setEmptyTextModalToggle, setFalse: setEmptyTextModalFalse }] = useBoolean(false);
  const [isGoBack, setIsGoBack] = useState(false);
  const [questionCountModalVisible, { toggle: setQuestionCountModalToggle, setFalse: setQuestionCountModalFalse }] = useBoolean(false);
  const [isShowQuestionLimitText, setIsShowQuestionLimitText] = useState(false);
  /// ------ 大更新 -----

  // 儲存使用者設定
  const [, sendUserSetting] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await postMemberTypeSetting(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          // 成功顯示
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  const getSendParams = (data) => {
    setSendParams(data);
  };

  // 取得試題類型
  const [stateGetQuestionType, sendGetQuestionType] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postGetQuestionGroup(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        // const { curriculumMap = {}, bookMap = {}, sourceMap = {}, chapterMap = {}, yearMap = {} } = data?.selection || {};
        if (isSuccess) {
          if (JSON.stringify(transformQuestionTypeData(data)) !== '{}') {
            goTop();
          }
        }
        return {
          isSuccess, systemCode, message,
          data: transformQuestionTypeData(data),
        };
      },
    },
  });

  const { data } = stateGetQuestionType.context.result || {};

  // 建立測驗
  const [statePostExamCreate, sendPostExamCreate] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        setIsLoading(true);
        const res = await postExamCreate(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const { examUID } = data;
        if (isSuccess) {
          doExportExam(examUID);
          setCreateExamPaperParams({
            ...createExamPaperParams,
            examUID: examUID,
          });
        }
        return {
          isSuccess, systemCode, message,
          data,
        };
      },
    },
  });

  // 編輯試卷
  const [statePostExamEdit, sendPostExamEdit] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postExamEdit(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          doExportExam(event.payload.examUID);
        }
        return {
          editIsSuccess: isSuccess, systemCode, message,
          data
        };
      },
    },
  });

  const transformDifficultObject = (array) => {
    if (!array) return {};
    let nextData = {};
    array.forEach((item) => {
      let newData = {
        ...nextData,
        [item.difficulty]: {
          answer: item.answer,
          question: item.question,
        }
      };
      nextData = newData;
    });
    return nextData;
  };

  const transformQuestion = (quesType, quesTypeName, array) => {
    if (!quesType || !quesTypeName || !array) return [];
    return array.map((item) => {
      return {
        ...item,
        quesType, quesTypeName
      };
    });
  };

  const transformQuestionTypeData = (data) => {
    if (!data) return {};
    let nextData = {};
    data.forEach((item) => {
      // if (globalExamPaperData.output === 'Quick') {
      //   if (!new RegExp(/SS|YN/g).test(item.questionType)) return null;
      // }
      let newData = {
        ...nextData,
        [item.questionTypeName]: {
          code: item.questionType,
          count: {
            answer: item.sum.answer,
            question: item.sum.question,
            difficulty: transformDifficultObject(item.diffcultyAggregate)
          },
          name: item.questionTypeName,
          question: transformQuestion(item.questionType, item.questionTypeName, item.questions)
        }
      };
      nextData = newData;
    });
    return nextData;
  };

  const searchParams = useMemo(() => {
    return {
      eduSubject: globalExamPaperData?.edu + globalExamPaperData?.subject,
      books: areaParams?.bookIDs,
      knowledges: areaParams?.keys,
      sources: areaParams?.source,
      curriculum: areaParams.curriculum,
      year: areaParams.year,
      version: areaParams.version,
      questionTypes: globalExamPaperData.output === 'Quick' ? ['SS', 'YN', 'GR'] : [],
    };
  }, [globalExamPaperData.edu, globalExamPaperData.subject, areaParams.bookIDs, areaParams.keys, areaParams.source, areaParams.year, areaParams.version, areaParams.curriculum, globalExamPaperData.output]);

  const [statePutEditExamPaper, sendPutEditExamPaper] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await putEditExamPaper(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        // if (isSuccess && event.outputType !== 'Files') {goTop();}
        return {
          isSuccess, systemCode, message,
          data
        };
      },
    },
  });

  const [statePostExportPaper, sendPostExportPaper] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postExportPaper(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {goTop();}
        return {
          isSuccess, systemCode, message,
          exportPaperData: data
        };
      },
    },
  });

  // const { data } = stateGetDrawUpFastCacheQuery.context.result || {};
  const { exportPaperData } = statePostExportPaper.context.result || {};

  const disabled = useMemo(() => {
    let result = false;
    const checkArray = ['paperName', 'teacherSign', 'schoolName'];
    const advancedCheckArray = ['amount', 'paperSize', 'wordSetting', 'paperContents'];
    let pickupQuestion = [];
    switch (step) {
      case 4:
        if (createExamPaperParams?.outputType !== 'Files') {
          const { paperName = {}, outputType = '', examType = '' } = createExamPaperParams || {};
          const checkParams = {
            paperName: paperName?.value || '',
            outputType,
            examType
          };
          Object.entries(checkParams).forEach(([key, value]) => {
            if (value === '') result = true;
          });
        } else {
          Object.entries(createExamPaperParams).forEach(([key, value]) => {
            if ((key === 'grade' || key === 'room') && checkedSend.findIndex(item => item === 'classInfo') !== -1) {
              if (value.value === '') result = true;
            }
            const needCheck = ((checkArray.findIndex(item => item === key) !== -1 && checkedSend.findIndex(item => item === key) !== -1));
            if (needCheck) {
              if (value.value === '') result = true;
            }
            if (advancedCheckArray.findIndex(item => item === key) !== -1) {
              if (!value || value === '' || value.length === 0) result = true;
            }
          });
        }

        break;
      case 3:
        Object.entries(tableData).forEach(([key, value]) => {
          if (value?.pickUpQuestions) {
            Object.entries(value?.pickUpQuestions).forEach(([pickKey, pickValue]) => {
              pickupQuestion = pickupQuestion.concat(pickValue);
            });
          }
        });
        if (pickupQuestion.length === 0) return true;
        break;
      case 2:
        if (!totalQuesTypeData?.questionCount || totalQuesTypeData.questionCount === 0) return true;
        break;
      case 1:
        Object.entries(areaParams).forEach(([key, value]) => {
          if (!value || value.length === 0) result = true;
        });
        break;
      default:
        break;
    }
    return result;
  }, [step, areaParams, tableData, createExamPaperParams, totalQuesTypeData]);

  const isChangeData = useMemo(() => {
    let result = false;
    if (step === 1) {
      if (Object.keys(saveData.areaParams).length === 0 || Object.keys(areaParams).length === 0) return false;
      Object.entries(areaParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (saveData.areaParams[key].length === value.length) {
            if (saveData.areaParams[key].filter(item => !value.includes(item)).length > 0)result = true;
          } else {
            result = true;
          }
        } else {
          if (saveData.areaParams[key] !== value) result = true;
        }
      });
    } else if (step === 2) {
      if (Object.keys(saveData.tableData).length === 0 || Object.keys(tableData).length === 0) return false;
      let questionList = [];
      let saveQuestionList = [];
      Object.entries(tableData).forEach(([key, value], index) => {
        const quesTypeData = formateQuestionList(value.pickUpQuestions, questions);
        if (quesTypeData.length > 0) {
          questionList = questionList.concat(quesTypeData);
        }
      });
      Object.entries(saveData.tableData).forEach(([key, value], index) => {
        const quesTypeData = formateQuestionList(value.pickUpQuestions, questions);
        if (quesTypeData.length > 0) {
          saveQuestionList = saveQuestionList.concat(quesTypeData);
        }
      });
      if (questionList.length === saveQuestionList.length) {
        if (questionList.filter(item => !saveQuestionList.includes(item)).length > 0) result = true;
      } else {
        result = true;
      }
    }

    return result;
  }, [areaParams, step, tableData]);

  const getSendAreaParams = (data) => {
    setAreaParams({ ...data });
  };
  const getTableData = (data) => {
    setTableData({ ...data });
  };
  const getKeyList = (data) => {
    setKeyList([...data]);
  };
  const getQuestions = (data) => {
    setQuestions([...data]);
  };
  const getTotalQuesTypeData = (data) => {
    setTotalQuesTypeData({ ...data });
  };
  const getCreateExamPaperParams = (data) => {
    setCreateExamPaperParams(data);
  };
  const getCheckedSend = (data) => {
    setCheckedSend(data);
  };
  const getSaveData = (key, data) => {
    setSaveData({ ...saveData, [key]: data });
  };
  const prevStep = () => {
    setStep(step - 1);
    goTop();
  };

  const onStepClick = (current) => {
    setIsGoBack(true);
    setStep(current + 1);
  };

  const resetData = () => {
    setSaveData({ ...initSaveData });
    setTableData({});
    setTotalQuesTypeData({});
    setQuestions([]);
    setCreateExamPaperParams({});
    setKeyList([]);
  };

  const questionGroups = useMemo(() => {
    if (!tableData) return;
    const nextData = keyList.map(key => {
      let questionList = [];
      if (JSON.stringify(saveData?.quesReviewQuestionRenderList) !== '{}' ) {
        saveData?.quesReviewQuestionRenderList[key].forEach((ques) => {
          questionList.push(ques.uid);
        });
      } else {
        if (tableData[key]?.pickUpQuestions) {
          Object.values(tableData[key].pickUpQuestions).forEach(questions => {
            questions.forEach(question => {
              questionList = [...questionList, question.uid];
            });
          });
        }
      }
      return ({
        typeCode: tableData[key].code,
        scoreType: tableData[key].inputValue.score.perQues ? 'PerQuestion' : 'PerAnswer',
        score: tableData[key].inputValue.score.perQues ? parseInt(tableData[key].inputValue.score.perQues) || 0 : parseInt(tableData[key].inputValue.score.perAns) || 0,
        questionList
      });
    }).filter(item => item.questionList.length > 0);
    return nextData;
  }, [tableData, keyList]);

  // 組成試卷
  const doExportExam = (examUID) => {
    if (!examUID) return;
    if (sendParams.outputType === 'Files') {
      // 紙本卷類
      let typesetting = {};

      const {
        schoolName = {},
        paperName,
        teacherSign = {},
        grade = {},
        room = {},
        paperSize,
        wordSetting,
        paperContents,
        analyzeContent,
        amount,
        advanced,
        studentSign,
        eduSubject,
        outputType,
      } = sendParams || {};

      typesetting = {
        schoolName: checkedSend.findIndex(item => item === 'schoolName') !== -1 ? schoolName.value : '',
        paperName: paperName.value,
        teacherSign: checkedSend.findIndex(item => item === 'teacherSign') !== -1 ? teacherSign.value : '',
        grade: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? grade.value : '',
        room: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? room.value : '',
        eduSubject: checkedSend.findIndex(item => item === 'eduSubject') !== -1 ? eduSubject : '',
        studentSign: checkedSend.findIndex(item => item === 'studentSign') !== -1 ? studentSign : '',
        paperSize,
        wordSetting,
        paperContents: paperContents || [],
        analyzeContent: analyzeContent || [],
        amount,
        advanced: advanced || []
      };

      // 儲存常用設定
      sendUserSetting(FETCH_DATA_EVENT.FETCH, {
        payload: typesetting
      });

      // 組成試卷
      sendPostExportPaper(FETCH_DATA_EVENT.FETCH, {
        payload: {
          examUID,
          outputType,
          typesetting,
        }
      });

    } else {
      // 線上測驗
      const {
        advanced,
        outputType,
      } = sendParams || {};

      sendPostExportPaper(FETCH_DATA_EVENT.FETCH, {
        payload: {
          examUID,
          outputType,
          onlineSetting: {
            advanced,
          }
        }
      });
    }
  };

  const isEditCopy = useMemo(() => {
    if (editCopyExamPaperData && JSON.stringify(editCopyExamPaperData) !== '{}') {
      setIsLoading(true);
      return true;
    } else {
      return false;
    }
  }, [editCopyExamPaperData]);

  const eduSubjectData = useMemo(() => {
    return isEditCopy ? editCopyExamPaperData : globalExamPaperData;
  }, [isEditCopy, editCopyExamPaperData, globalExamPaperData]);

  const sendStep4Api = () => {
    if (isEditCopy && editCopyExamPaperData?.actionType === 'Edit') {
      sendPostExamEdit(FETCH_DATA_EVENT.FETCH, {
        payload: {
          examUID: editCopyExamPaperData.paperData.uid,
          examName: sendParams.paperName.value,
          outputType: sendParams.outputType,
          drawUp: 'CustomPattern',
          examType: sendParams.examType,
          isPublic: false,
          questionGroups,
        }
      });
    } else {
      sendPostExamCreate(FETCH_DATA_EVENT.FETCH, {
        payload: {
          eduSubject: searchParams.eduSubject,
          examName: sendParams.paperName.value,
          searchCondition: {
            bookIDs: isEditCopy ? reCreateSearchParams.bookIDs : searchParams.books,
            knowledgeIDs: isEditCopy ? reCreateSearchParams.knowledges : searchParams.knowledges,
            sourceIDs: isEditCopy ? reCreateSearchParams.sources : searchParams.sources,
            curriculum: isEditCopy ? reCreateSearchParams.curriculum : searchParams.curriculum,
            year: isEditCopy ? reCreateSearchParams.year : searchParams.year,
            version: isEditCopy ? reCreateSearchParams.version : searchParams.version,
          },
          examType: sendParams.examType,
          outputType: sendParams.outputType,
          drawUp: 'CustomPattern',
          questionGroups,
        }
      });
    }
  };

  const nextStep = (jumpTo = null) => {
    setJumpStep(jumpTo);
    switch (step) {
      case 4:
        if (totalQuesTypeData.score !== 100) {
          setScoreModalToggle();
        } else {
          sendStep4Api();
        }
        break;
      case 1:
        if (isChangeData) {
          setSaveData({
            ...initSaveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'GE',
            bookOptions: saveData.bookOptions,
          });
          setTableData({});
          setTotalQuesTypeData({});
          setQuestions([]);
          setCreateExamPaperParams({});
          setKeyList([]);
        } else {
          setSaveData({
            ...saveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'GE',
          });
        }
        sendGetQuestionType(FETCH_DATA_EVENT.FETCH, {
          payload: {
            eduSubject: globalExamPaperData?.edu + globalExamPaperData?.subject,
            bookIDs: areaParams.bookIDs,
            knowledges: areaParams.keys,
            sources: areaParams.source,
            curriculum: areaParams.curriculum,
            year: areaParams.year,
            version: areaParams.version,
            questionType: searchParams.questionTypes,
          }
        });
        break;

      default:
        if (jumpTo !== null) {
          setStep(jumpTo);
        } else {
          setStep(step + 1);
        }

        goTop();
        break;
    }
  };

  useEffect(() => {
    if (!data) return;
    if (Object.keys(tableData).length > 0) return;
    const nextData = formateTableData(data);
    getTableData(nextData);
    setIsJumpToStep2(true);
  }, [data]);

  useEffect(() => {
    if (isEditCopy && isJumpToStep2) {
      setStep(2);
    }
  }, [isEditCopy, isJumpToStep2]);

  useEffect(() => {
    if (stateGetQuestionType.matches(FETCH_DATA_STATE.DONE) && step === 1) {
      if (!data || JSON.stringify(data) === '{}') {
        setEmptyTextModalToggle();
        return null;
      } else {
        if (!isEditCopy) setStep(step + 1);
      }
    }
  }, [stateGetQuestionType.value]);

  useEffect(() => {
    if (saveData.education === '' || saveData.subject === '') return;
    if (globalExamPaperData?.edu === saveData.education && globalExamPaperData?.subject === saveData.subject) return;
    resetData();
    setStep(1);
  }, [globalExamPaperData]);

  useEffect(() => {
    if (Object.keys(tableData).length > 0 && getCookie('createNewPaper') === null) {
      setCookie('createNewPaper', true, 1);
    }
  }, [tableData]);

  useEffect(() => {
    Object.entries(globalExamPaperData).forEach(([key, value]) => {
      if ((key === 'edu' || key === 'subject') && value === '') {
        if (!editCopyExamPaperData[key]) {
          history.push('./');
        }
      }
    });

    if (!editCopyExamPaperData || JSON.stringify(editCopyExamPaperData) === '{}') return;
    const {
      edu,
      subject,
      bookIDs,
      eduSubject,
      knowledgeIDs,
      selectedQuestion,
      sourceIDs,
      output,
      curriculum,
      year,
      version,
    } = editCopyExamPaperData;

    // 取得 題目類型
    setSendParams({ bookIDs, knowledges: knowledgeIDs, sourceIDs, eduSubject });
    sendGetQuestionType(FETCH_DATA_EVENT.FETCH, {
      payload: {
        eduSubject,
        bookIDs,
        knowledges: knowledgeIDs,
        sources: sourceIDs,
        curriculum,
        year,
        version,
        questionType: output === 'Quick' ? ['SS', 'YN', 'GR'] : [],
      }
    });

    setReCreateSearchParams({
      eduSubject,
      bookIDs,
      knowledges: knowledgeIDs,
      sources: sourceIDs,
      curriculum,
      year,
      version,
      questionTypes: output === 'Quick' ? ['SS', 'YN', 'GR'] : [],
    });

  }, []);

  useEffect(() => {
    if (statePostExportPaper.matches(FETCH_DATA_STATE.DONE)) {
      setIsLoading(false);
      setStep(step + 1);
    }
  }, [statePostExamEdit.value, statePostExportPaper.value]);

  useEffect(() => {
    if (authState.context.userDetails?.userStatus?.identity === 'Student') {
      history.push('/');
    }
  }, []);

  const identity = useMemo(() => {
    return authState?.context?.userDetails?.onePaperIdentity || '';
  }, [authState]);

  const prevStepDisabled = useMemo(() => {
    if (!editCopyExamPaperData?.actionType) return false;
    if ((editCopyExamPaperData?.actionType === 'Edit' || editCopyExamPaperData?.actionType === 'Copy') && step <= 2) return true;
  }, [editCopyExamPaperData?.actionType, step]);

  // 再次出題
  useEffect(() => {
    if (reCreateExam) {
      initAllData();
    }
  }, [reCreateExam]);

  const initAllData = () => {
    setSaveData({ ...initSaveData });
    setResetToggle(false);
    setAreaParams({});
    setSendParams({});
    setQuestions([]);
    setTableData({});
    setKeyList([]);
    setSearchKey('');
    setTotalQuesTypeData({});
    setCreateExamPaperParams({});
    setCheckedSend([]);
    setJumpStep(null);
  };

  const nextStepClickHandler = () => {
    // 確認是否有超過 出題上限。
    if (totalQuesTypeData.questionCount > questionNumLimit) {
      setQuestionCountModalToggle();
      setIsShowQuestionLimitText(true);
    } else {
      if (isChangeData) {
        if (step === 1 ) {
          setAreaModalToggle();
        }
      } else {
        nextStep();
      }
    }
  };

  // 題數 上限
  const questionNumLimit = useMemo(() => {
    const type = createExamPaperParams?.outputType || globalExamPaperData.output;
    let num = 300;
    if (type === 'Files') num = 500;
    return num;
  }, [globalExamPaperData.output, createExamPaperParams.outputType]);

  useEffect(() => {
    setIsShowQuestionLimitText(false);
  }, [totalQuesTypeData.questionCount]);

  return (
    <StyledManualPropositionPage className={className} data-testid="ManualPropositionPage">
      <ExamPaperHeader pageName='手動命題' globalExamPaperData={eduSubjectData} step={step} />
      {
        deviceData.isDesktop ?
          <StepBox isEditPaper={editCopyExamPaperData?.actionType} steps={steps} current={step - 1} onChange={onStepClick} stepdisabled={step === 5 ? true : false} type={'CustomPattern'}>
            {
              (step === 1 && Object.keys(tableData).length > 0) &&
              <div className='warningBar'><ExclamationCircleSolid/>若變更選擇的範圍，則已選題型與試題將會被清空，請小心操作！</div>
            }

            {
              (step === 2 && isShowQuestionLimitText) &&
              <div className='errorBar'><ExclamationCircleSolid/>每卷題數上限為{questionNumLimit}題，已選{totalQuesTypeData.questionCount}題，請減少題數。</div>
            }

            {/* {
              (step === 2 && saveData?.disabled && saveData?.disabled.length > 0) &&
              <div className='warningBar'>
                <div><ExclamationCircleSolid/>原試卷已選{editCopyQuestionCount}題試題，其中{disabledQuestionCount}題已被停用，將不會再顯示於試卷中：</div>
                <ul>
                  {
                    saveData?.disabled.map(item => (
                      <li>知識向度「{item.name}」 {item.amount} 題</li>
                    ))
                  }
                </ul>
              </div>
            } */}
            <StyledManualPropositionPageContent notLimitHeight={(step === 1 || step === 2 || step === 3 || step === 4) ? true : false}>
              {
                step === 1 &&

                <MpAreaSelectContent
                  examPaperType='CustomPattern' //CustomPattern
                  resetToggle={resetToggle}
                  memberEduSubject={globalExamPaperData}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  getSendAreaParams={getSendAreaParams}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  getTotalQuesTypeData={getTotalQuesTypeData}
                  identity={identity}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isEditCopy={isEditCopy}
                  isGoBack={isGoBack}
                  setIsGoBack={setIsGoBack}
                />

              }
              {
                step === 2 &&
              <MpQuestionSelectContent
                examPaperInfo={data}
                examPaperData={tableData}
                searchKey={searchKey}
                searchParams={searchParams}
                examPaperTotalScoreData={totalQuesTypeData}
                prevKeyList={keyList}
                memberEduSubject={eduSubjectData}
                saveData={saveData}
                getSaveData={getSaveData}
                getTableData={getTableData}
                getTotalQuesTypeData={getTotalQuesTypeData}
                getQuestions={getQuestions}
                getKeyList={getKeyList}
                globalExamPaperData={globalExamPaperData}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isEditCopy={isEditCopy}
                reCreateSearchParams={reCreateSearchParams}
                editCopyExamPaperData={editCopyExamPaperData}
              />
              }
              {
                step === 3 &&

                <MpQuestionReviewContent
                  examPaperInfo={data}
                  examPaperData={tableData}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  questions={questions}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  getTotalQuesTypeData={getTotalQuesTypeData}
                  memberEduSubject={eduSubjectData}
                  eduSubjectData={eduSubjectData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
              {
                step === 4 &&
                <MpLayoutOptionContent
                  searchKey={searchKey}
                  keyList={keyList}
                  examPaperData={tableData}
                  examPaperTotalScoreData={totalQuesTypeData}
                  saveData={saveData}
                  globalExamPaperData={globalExamPaperData}
                  eduSubjectData={eduSubjectData}
                  getCreateExamPaperParams={getCreateExamPaperParams}
                  getCheckedSend={getCheckedSend}
                  getTableData={getTableData}
                  getSaveData={getSaveData}
                  organizationType={authState.context.userDetails?.userStatus?.organization.type}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  areaParams={areaParams}
                  setOutSideSendParams={getSendParams}
                  getTotalQuesTypeData={getTotalQuesTypeData}
                  searchParams={searchParams}
                />
              }
              {
                step === 5 &&
                <HomeQuestionCompleteContent
                  onlineExamUID={createExamPaperParams?.examUID}
                  examUID={exportPaperData?.examUID}
                  UID={exportPaperData?.uid}
                  outputType={createExamPaperParams?.outputType}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  sendParams={sendParams}
                  setStep={setStep}
                  setReCreateExam={setReCreateExam}
                  examPaperTotalScoreData={totalQuesTypeData}
                  eduSubjectData={eduSubjectData}
                />
              }
            </StyledManualPropositionPageContent>

            <UiBtnZone>
              {
                step !== 1 && step !== 5 &&
                <Button
                  disabled={prevStepDisabled}
                  type={prevStepDisabled ? 'default' : 'solid'}
                  textcolor={prevStepDisabled ? '#D5D7DE' : '#A1A4B1'}
                  variant={prevStepDisabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => {prevStep();setIsGoBack(true);}}>
              上一步
                </Button>
              }
              {
                step !== 5 &&
                <Button
                  disabled={disabled}
                  variant={disabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => {nextStepClickHandler();setIsGoBack(false);}}
                >
                  {
                    step !== 4 ?
                      '下一步' :
                      '輸出試卷'
                  }
                </Button>
              }
            </UiBtnZone>
          </StepBox> :
          <MobileStepBox steps={steps} current={step - 1} onChange={onStepClick} stepdisabled={step === 5 ? true : false} type={'CustomPattern'}>
            {
              (step === 1 && Object.keys(tableData).length > 0) &&
              <div className='warningBar'><ExclamationCircleSolid/>若變更選擇的範圍，則已選題型與試題將會被清空，請小心操作！</div>
            }

            {
              (step === 2 && isShowQuestionLimitText) &&
              <div className='errorBar'><ExclamationCircleSolid/>每卷題數上限為{questionNumLimit}題，已選{totalQuesTypeData.questionCount}題，請減少題數。</div>
            }

            {/* {
              (step === 2 && saveData?.disabled && saveData?.disabled.length > 0) &&
              <div className='warningBar'>
                <div><ExclamationCircleSolid/>原試卷已選{editCopyQuestionCount}題試題，其中{disabledQuestionCount}題已被停用，將不會再顯示於試卷中：</div>
                <ul>
                  {
                    saveData?.disabled.map(item => (
                      <li>知識向度「{item.name}」 {item.amount} 題</li>
                    ))
                  }
                </ul>
              </div>
            } */}
            <StyledManualPropositionPageContent notLimitHeight={(step === 1 || step === 2 || step === 3 || step === 4) ? true : false}>
              {
                step === 1 &&

                <MpAreaSelectContent
                  examPaperType='CustomPattern' //CustomPattern
                  resetToggle={resetToggle}
                  memberEduSubject={globalExamPaperData}
                  saveData={saveData}
                  getSaveData={getSaveData}
                  getSendAreaParams={getSendAreaParams}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  getTotalQuesTypeData={getTotalQuesTypeData}
                  identity={identity}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isEditCopy={isEditCopy}
                  isGoBack={isGoBack}
                  setIsGoBack={setIsGoBack}
                />

              }
              {
                step === 2 &&
              <MpQuestionSelectContent
                examPaperInfo={data}
                examPaperData={tableData}
                searchKey={searchKey}
                searchParams={searchParams}
                examPaperTotalScoreData={totalQuesTypeData}
                prevKeyList={keyList}
                memberEduSubject={eduSubjectData}
                saveData={saveData}
                getSaveData={getSaveData}
                getTableData={getTableData}
                getTotalQuesTypeData={getTotalQuesTypeData}
                getQuestions={getQuestions}
                getKeyList={getKeyList}
                globalExamPaperData={globalExamPaperData}
                renderLoadingContent={renderLoadingContent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isEditCopy={isEditCopy}
                reCreateSearchParams={reCreateSearchParams}
                editCopyExamPaperData={editCopyExamPaperData}
              />
              }
              {
                step === 3 &&

                <MpQuestionReviewContent
                  examPaperInfo={data}
                  examPaperData={tableData}
                  searchKey={searchKey}
                  searchParams={searchParams}
                  examPaperTotalScoreData={totalQuesTypeData}
                  prevKeyList={keyList}
                  questions={questions}
                  saveData={saveData}
                  memberEduSubject={eduSubjectData}
                  eduSubjectData={eduSubjectData}
                  getSaveData={getSaveData}
                  getKeyList={getKeyList}
                  getTableData={getTableData}
                  getTotalQuesTypeData={getTotalQuesTypeData}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
              {
                step === 4 &&
                <MpLayoutOptionContent
                  searchKey={searchKey}
                  keyList={keyList}
                  examPaperData={tableData}
                  examPaperTotalScoreData={totalQuesTypeData}
                  saveData={saveData}
                  globalExamPaperData={globalExamPaperData}
                  eduSubjectData={eduSubjectData}
                  getCreateExamPaperParams={getCreateExamPaperParams}
                  getCheckedSend={getCheckedSend}
                  getTableData={getTableData}
                  getSaveData={getSaveData}
                  organizationType={authState.context.userDetails?.userStatus?.organization.type}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  areaParams={areaParams}
                  setOutSideSendParams={getSendParams}
                  getTotalQuesTypeData={getTotalQuesTypeData}
                  searchParams={searchParams}
                />
              }
              {
                step === 5 &&
                <HomeQuestionCompleteContent
                  onlineExamUID={createExamPaperParams?.examUID}
                  examUID={exportPaperData?.examUID}
                  UID={exportPaperData?.uid}
                  outputType={createExamPaperParams?.outputType}
                  renderLoadingContent={renderLoadingContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  sendParams={sendParams}
                  setStep={setStep}
                  setReCreateExam={setReCreateExam}
                  examPaperTotalScoreData={totalQuesTypeData}
                  eduSubjectData={eduSubjectData}
                />
              }
            </StyledManualPropositionPageContent>

            <UiBtnZone>
              {
                step !== 1 && step !== 5 &&
                <Button
                  className={'prevStepBtn'}
                  disabled={prevStepDisabled}
                  type={prevStepDisabled ? 'default' : 'solid'}
                  textcolor={prevStepDisabled ? '#D5D7DE' : '#A1A4B1'}
                  variant={prevStepDisabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => {prevStep();setIsGoBack(true);}}>
              上一步
                </Button>
              }
              {
                step !== 5 &&
                <Button
                  disabled={disabled}
                  variant={disabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => {nextStepClickHandler();setIsGoBack(false);}}
                >
                  {
                    step !== 4 ?
                      '下一步' :
                      '輸出試卷'
                  }
                </Button>
              }
            </UiBtnZone>
          </MobileStepBox>
      }
      <Modal
        visible={areaModalVisible}
        onCancel={setAreaModalFalse}
        className={'mobileModal'}
        title={
          <div>確定要變更範圍？</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant='ui01' onClick={() => {getSendAreaParams(saveData.areaParams);setAreaModalFalse(); setResetToggle(!resetToggle);}}>取消變更</Button>
            <Button variant={'brand1'} onClick={() => { nextStep(); setAreaModalFalse();setIsGoBack(false);}}>確定變更</Button>
          </div>
        }
      >
        <div className="modalContent">
          <div>變更後，原已選題型與試題將會被清空，需要重新操作後續步驟。</div>
          <div>確定變更請按「確定變更」</div>
          <div>使用原範圍請按「取消變更」</div>
        </div>
      </Modal>

      <Modal
        visible={scoreModalVisible}
        onCancel={setScoreModalFalse}
        className={'scoreModal mobileModal'}
        title={
          <div>總分不等於 100 分，是否要繼續？</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant='ui01' onClick={setScoreModalFalse}>取消</Button>
            <Button
              variant={'brand1'}
              onClick={() => {
                sendStep4Api();
                setScoreModalFalse();
              }}>繼續</Button>
          </div>
        }
      />

      <Modal
        visible={emptyTextModalVisible}
        onCancel={setEmptyTextModalFalse}
        className={'mobileModal'}
        id={'emptyTextModal'}
        title={
          <div>該範圍無可用試題</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button
              variant={'brand1'}
              onClick={setEmptyTextModalFalse}
            >
              確認
            </Button>
          </div>
        }
      >
        <div>該範圍試題還在陸續更新中，請重新選擇範圍！</div>
      </Modal>

      <Modal
        visible={questionCountModalVisible}
        onCancel={setQuestionCountModalFalse}
        className={'questionCountModal mobileModal'}
        title={
          <div>題數超過出題上限，請減少題數</div>
        }
        footer={
          <div className="footerBtn footerBtnBox">
            <Button variant={'brand1'} onClick={() => { setQuestionCountModalFalse();}}>確定</Button>
          </div>
        }
      >
        <div className="modalContent">
          <div>{OUTPUT_TYPE_NAME[globalExamPaperData.output]}出題上限為{questionNumLimit}題，您當前已選擇{totalQuesTypeData.questionCount}題，請減少題數。</div>
        </div>
      </Modal>

    </StyledManualPropositionPage>
  );
};