import { Api } from '@oneclass/ui-components';
// import { stringify } from 'query-string';

export const postMemberPreference = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Member/Preference`, payload);
  return response;
};

export const getMemberTypeSetting = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Member/Typesetting/NewRelated`, payload);
  return response;
};

export const postMemberTypeSetting = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Member/Typesetting`, payload);
  return response;
};

// 新API

export const getMemberInformation = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Member/Information`);
  return response;
};

// 取得與用戶相關的「動態參數」與「靜態參數」
export const getMemberParameter = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Member/Parameter`);
  return response;
};