import styled from 'styled-components';
export const UiSelectList = styled.div`
`;
export const StyledMpAreaSelectContent = styled.div.attrs(props => ({
  className: props.className
}))`
display: flex;
flex:1;
	.checkBoxRow{
		&:not(:last-child){
			margin-bottom: 8px;
		}
	}
	.stepHint{
			color:${({ theme }) => theme.colors.text.text_06}
		}
	.areaBorder{
		background-color: ${({ theme }) => theme.colors.ui.ui_03};
		width: 100%;
		height: 1px;
	}
`;