/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Tooltip } from 'antd';
import { Button } from '@oneclass/onedesign';
import {
  CopySolid,
  CheckCircleSolid
} from '@onedesign/icon';

import { StyledTestLinkModalContent } from './TestLinkModalContent.style';

export const TestLinkModalContent = ({ className, examLink, modalInfo, shareModalData }) => {
  const linkRef = useRef(null);
  const textRef = useRef(null);

  const copyLink = async(type, e) => {
    if (e) {
      const clipboardObj = navigator.clipboard;
      await clipboardObj.writeText(type.current.value);
    }
  };

  const textForCopy = `南一雲端出題速測操作說明：\n1.本次考試截止時間為 ${shareModalData?.endTime?.month}/${shareModalData?.endTime?.day} ${shareModalData?.endTime?.time}\n2.請同學使用電腦或平板進入測驗網站，不建議使用手機作答：${examLink}/quiz/${modalInfo.QuizCode}\n3.請先輸入姓名與座號，打 ✓ 確認資料無誤，依照網站指示完成測驗\n4.待老師公佈解答後，請同學至 ${examLink} 點擊右上角「查看測驗結果」，輸入測驗碼 ${shareModalData?.quizCode?.top4} ${shareModalData?.quizCode?.last4}、姓名與座號，點擊查看後複習錯題`;

  return (
    <StyledTestLinkModalContent className={className} data-testid="TestLinkModalContent">
      <div className='testModalContent'>
        <div className='testModalLinkBox'>
          <div className='testModalLinkTitle'>{modalInfo?.ExamName}</div>
          <div className='endTime'>
            <span>測驗結束時間</span>
            <div>{`${shareModalData?.endTime?.month}/${shareModalData?.endTime?.day} ${shareModalData?.endTime?.time}`}</div>
          </div>
          <div className='testModalLink' id="testModalLink">
            <input disabled className='testModalLinkData' ref={linkRef} value={`${examLink}/quiz/${modalInfo.QuizCode}`} />
            <div className='copyImgBox'>
              <Tooltip
                trigger='click'
                placement="top"
                getPopupContainer={() => document.getElementById('testModalLink') || document.body}
                title={
                  <div className='copyHint'>
                    <div className='copyImg'>
                      <CheckCircleSolid />
                    </div>
                    已複製
                  </div>
                }
                onVisibleChange={(visible) => {copyLink(linkRef, visible);}}>
                <CopySolid className='copyBtn'/>
              </Tooltip>
            </div>
          </div>
          <ul className='testModalLinkText'>
            <li>請同學前往網站完成測驗</li>
            <li>待老師公佈解答後，請同學至 {examLink} 點擊右上角「查看測驗結果」，輸入測驗碼 {shareModalData?.quizCode?.top4} {shareModalData?.quizCode?.last4}、姓名與座號，點擊查看後複習錯題</li>
          </ul>
        </div>
        <div className='testModalManualBox'>
          <div className='testModalManualTitle'>南一線上測驗說明：</div>
          <textarea disabled style={{ display: 'none' }} ref={textRef} value={textForCopy} />
          <ul className='testModalManualList'>
            <li>本次考試截止時間為 {shareModalData?.endTime?.month}/{shareModalData?.endTime?.day} {shareModalData?.endTime?.time}</li>
            <li>請同學使用電腦或平板進入測驗網站，不建議使用手機作答：<a href={`${examLink}/quiz/${modalInfo.QuizCode}`} target='_blank' rel='noreferrer'>{`${examLink}/quiz/${modalInfo.QuizCode}`}</a></li>
            <li>請先輸入姓名與座號，打 ✓ 確認資料無誤，依照網站指示完成測驗</li>
            <li>待老師公佈解答後，請同學至 <a href={examLink} target='_blank' rel='noreferrer'>{examLink}</a> 點擊右上角「查看測驗結果」，輸入測驗碼 {shareModalData?.quizCode?.top4} {shareModalData?.quizCode?.last4}、姓名與座號，點擊查看後複習錯題</li>
          </ul>
          <div className='testModalManualButton' id='testModalManualList'>
            <Tooltip
              trigger='click'
              placement="top"
              getPopupContainer={() => document.getElementById('testModalManualList') || document.body}
              title={
                <div className='copyHint'>
                  <div className='copyImg'>
                    <CheckCircleSolid />
                  </div>
                  已複製
                </div>
              }
              onVisibleChange={(visible) => {copyLink(textRef, visible);}}>
              <Button className='copyTextBtn' type='solid'><CopySolid />複製說明</Button>
            </Tooltip>

          </div>
        </div>
      </div>
    </StyledTestLinkModalContent>
  );
};