import React, { useEffect, useState, useMemo, useContext } from 'react';
import { getExportPaperRelated } from 'api/exportPaper';
import { postMemberTypeSetting, getMemberTypeSetting } from 'api/member';
import { useMachine } from '@xstate/react';
import { Box, Table, setRadioOptions, fetchDataMachine, FETCH_DATA_EVENT, FETCH_DATA_STATE, openNotificationWithIcon, Drawer } from '@oneclass/ui-components';
import { Radio } from 'antd';
import { RadioGroup } from '@oneclass/onedesign';
import { arrayUnique } from 'utils/common';
import { ExamPaperSetting } from 'components';
import PropTypes from 'prop-types';
import { StyledHomeLayoutOptionContent } from './HomeLayoutOptionContent.style';
import { useQuestionAnalysisModal } from 'utils/hooks/useQuestionAnalysisModal';
import { useBoolean } from 'ahooks';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
// eslint-disable-next-line react/prop-types
export const HomeLayoutOptionContent = ({
  className,
  searchKey,
  keyList,
  examPaperData,
  examPaperTotalScoreData,
  saveData,
  globalExamPaperData,
  getCreateExamPaperParams,
  getSaveData,
  getCheckedSend,
  organizationType,
  searchParams,
  eduSubjectData,
  areaParams,
  setOutSideSendParams,
  renderLoadingContent,
  isLoading,
  setIsLoading,
  isEditCopy,
  editCopyExamPaperData,
}) => {
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const rowData = ['schoolName', 'paperName', 'teacherSign', 'classInfo', 'eduSubject', 'studentSign'];
  const [sendParams, setSendParams] = useState({
    paperName: {
      value: '',
      message: '',
    },
    examType: '',
    outputType: globalExamPaperData?.output,
    amount: 2,
  });

  useEffect(() => {
    setOutSideSendParams(sendParams);
  }, [sendParams]);

  const [tableData, setTableData] = useState(examPaperData);

  //導入 試題分析 Hook
  const pickUpQuestionID = useMemo(() => {
    if (!tableData) return [];
    let nextData = [];
    Object.entries(tableData).forEach(([key, value]) => {
      if (value?.pickUpQuestions)
      {
        Object.entries(value.pickUpQuestions).forEach(([quesKey, quesValue]) => {
          quesValue.forEach(item => {
            nextData = [...nextData, item.uid];
          });

        });
      }

    });
    return nextData;
  }, [tableData]);

  const { renderQuestionAnalysisModal, doShowAnalysisModal } = useQuestionAnalysisModal(pickUpQuestionID, searchParams);

  const [checkedSend, setCheckedSend] = useState(['paperName']);

  // 取得 使用者 出卷輸出設定
  const [stateExportPaperRelated, sendExportPaperRelated] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (ctx, evt) => {
        setIsLoading(true);
        const res = await getExportPaperRelated(evt?.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) setIsLoading(false);
        const {
          outputMap = [],
          settings,
          examTypeMap
        } = data;
        return {
          isSuccess, systemCode, message,
          paperSizeMap: setRadioOptions(settings?.Files?.paperSizeMap) || [],
          wordSettingMap: setRadioOptions(settings?.Files?.wordSettingMap) || [],
          paperContent: settings?.Files?.paperContent,
          typesetting: settings?.Files?.typesetting,
          advancedSetting: settings?.Files?.advancedSetting,
          analyzeContent: settings?.Files?.analyzeContent,
          outputMap: sendParams.outputType === 'Quick' ? setRadioOptions(outputMap) || [] : setRadioOptions(outputMap.filter((item) => {return item.code !== 'Quick'; })) || [],
          onlineAdvanced: settings?.Online?.advanced,
          onlineAdvancedSetting: settings?.Online?.advancedSetting,
          examPaperTypeOptions: setRadioOptions(examTypeMap) || [],
        };
      }
    }
  });
  const {
    paperSizeMap,
    wordSettingMap,
    paperContent,
    advancedSetting,
    typesetting,
    analyzeContent,
    outputMap,
    onlineAdvanced,
    onlineAdvancedSetting,
    examPaperTypeOptions
  } = stateExportPaperRelated.context.result || {};

  const [state, send] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async() => {
        const res = await getMemberTypeSetting();
        const { data, isSuccess, systemCode, message } = res;
        const {
          uid = '',
          isTeacher = false,
          identityName = '',
          account = '',
          name = '',
          organizationName = '',
          paperSizeMap = [],
          wordSettingMap = [],
          paperContent = [],
          typesetting = {},
          analyzeContent = []
        } = data || {};
        return {
          isSuccess, systemCode, message,
          uid,
          isTeacher,
          identityName,
          account,
          name,
          organizationName,
          paperSizeMap: setRadioOptions(paperSizeMap) || [],
          wordSettingMap: setRadioOptions(wordSettingMap) || [],
          paperContent,
          typesetting,
          analyzeContent
        };
      }
    }
  });
  const { isTeacher } = state.context.result || {};
  const [, sendUserSetting] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await postMemberTypeSetting(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', '儲存成功');
          sendExportPaperRelated(FETCH_DATA_EVENT.FETCH);
        }
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  const onExamPaperInfoChange = (e, key) => {
    const value = e.target.value;
    setSendParams({
      ...sendParams,
      [key]: value
    });
  };

  const onSaveExamPaperInfo = () => {
    let nextData = {};

    const {
      schoolName = {},
      paperName,
      teacherSign = {},
      grade = {},
      room = {},
      paperSize,
      wordSetting,
      paperContents,
      analyzeContent,
      amount,
      advanced,
      studentSign,
      eduSubject
    } = sendParams || {};

    nextData = {
      schoolName: checkedSend.findIndex(item => item === 'schoolName') !== -1 ? schoolName.value : '',
      paperName: paperName.value,
      teacherSign: checkedSend.findIndex(item => item === 'teacherSign') !== -1 ? teacherSign.value : '',
      grade: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? grade.value : '',
      room: checkedSend.findIndex(item => item === 'classInfo') !== -1 ? room.value : '',
      eduSubject: checkedSend.findIndex(item => item === 'eduSubject') !== -1 ? eduSubject : '',
      studentSign: checkedSend.findIndex(item => item === 'studentSign') !== -1 ? studentSign : '',
      paperSize,
      wordSetting,
      paperContents: paperContents || [],
      analyzeContent: analyzeContent || [],
      amount,
      advanced: advanced || []
    };
    sendUserSetting(FETCH_DATA_EVENT.FETCH, {
      payload: nextData
    });

  };

  const getSendParams = (data) => {
    setSendParams({ ...sendParams, ...data });
  };

  const changeCheckedSend = (data) => {
    setCheckedSend(data);
  };

  ///// ------------------------------

  const questionGroup = useMemo(() => {
    if (!examPaperData) return;
    const nextData = keyList.map(key => {
      let questionList = [];
      if (examPaperData[key]?.pickUpQuestions) {
        Object.values(examPaperData[key].pickUpQuestions).forEach(questions => {
          questions.forEach(question => {
            questionList = [...questionList, question.uid];
          });
        });
      }
      return ({
        typeCode: examPaperData[key].code,
        scoreType: examPaperData[key].inputValue.score.perQues ? 'PerQuestion' : 'PerAnswer',
        score: examPaperData[key].inputValue.score.perQues ? parseInt(examPaperData[key].inputValue.score.perQues) || 0 : parseInt(examPaperData[key].inputValue.score.perAns) || 0,
        questionList
      });
    }).filter(item => item.questionList.length > 0);
    return nextData;
  }, [examPaperData]);

  useEffect (() => {
    if (!saveData?.createExamPaperParams.examUID) {
      if (saveData?.actionType) {
        sendExportPaperRelated(FETCH_DATA_EVENT.FETCH, {
          payload: {
            examUID: saveData.rootExamUID
          }
        });
      } else {
        sendExportPaperRelated(FETCH_DATA_EVENT.FETCH);
      }
    } else {
      sendExportPaperRelated(FETCH_DATA_EVENT.FETCH, {
        payload: {
          examUID: saveData.rootExamUID
        }
      });
    }
  }, []);


  const paperDefaultName = useMemo(() => {
    if (!areaParams || !eduSubjectData) return '';
    return `${areaParams.year}學年度${eduSubjectData.eduSubjectName.edu + eduSubjectData.eduSubjectName.subject}測驗`;
  }, [areaParams, eduSubjectData]);

  useEffect(() => {
    getCreateExamPaperParams(sendParams);
    getSaveData('createExamPaperParams', sendParams);
  }, [sendParams]);


  useEffect(() => {
    getCheckedSend(checkedSend);
  }, [checkedSend]);

  useEffect(() => {
    if (saveData?.createExamPaperParams) {
      setSendParams({ ...saveData?.createExamPaperParams });
    }
  }, []);

  useEffect(() => {
    if (!stateExportPaperRelated.matches(FETCH_DATA_STATE.DONE)) return;
    if (!typesetting) return;
    const {
      room = '',
      grade = '',
    } = typesetting || {};
    let nextCheckSend = [];
    rowData.forEach(item => {
      if ((item === 'classInfo') && room && grade) {
        nextCheckSend = [...nextCheckSend, item];
      } else {
        if (typesetting[item]) {
          nextCheckSend = [...nextCheckSend, item];
        }

      }

    });
    setCheckedSend(nextCheckSend);
  }, [typesetting, stateExportPaperRelated.value]);

  useEffect(() => {
    const {
      paperSize,
      //wordSetting,
      paperContents,
      // paperName = '',
      amount = 1,
      advanced = [],
      schoolName = '',
      room = '',
      grade = '',
      teacherSign = '',
      analyzeContent = []
    } = typesetting || {};

    setSendParams({
      ...sendParams,
      // examUID: data.examUID,
      paperSize: paperSize || (paperSizeMap && paperSizeMap[0].value),
      wordSetting: wordSetting || (wordSettingMap && wordSettingMap[0].value),
      paperContents: paperContents?.length > 0 ? paperContents : paperContent && paperContent.filter(item => item.code === 'Question' || item.code === 'Analyze').map(item => item.code),
      paperName: {
        value: paperDefaultName, //newPaperName,
        message: '',
      },
      schoolName: {
        value: schoolName,
        message: '',
      },
      grade: {
        value: grade,
        message: '',
      },
      room: {
        value: room,
        message: '',
      },
      teacherSign: {
        value: teacherSign,
        message: '',
      },
      outputType: sendParams?.outputType || 'Files',
      examType: sendParams.examType || 'General',
      amount,
      advanced,
      analyzeContent: arrayUnique(['Question', 'Answer', 'Analyze'].concat(analyzeContent)),
      studentSign: '座號：＿＿＿  姓名：＿＿＿＿＿＿',
      eduSubject: `${globalExamPaperData?.eduSubjectName.edu}${globalExamPaperData?.eduSubjectName.subject}`,
      onlineAdvanced
    });
  }, [typesetting, globalExamPaperData, onlineAdvanced]);

  // ---- 手機版 相關 ----

  // 題數與配分
  const [setupDrawerVisible, { toggle: setupDrawerToggle, setFalse: setupDrawerFalse }] = useBoolean(false);

  // 動態新增 題型與配分Btn 到 header 上面
  const addSetupBtnClickEvent = () => {
    const setupBtn = document.getElementById('setupBtn');
    if (setupBtn) {
      setupBtn.addEventListener('click', function() {
        setupDrawerToggle();
      });
    }
  };

  useEffect(() => {
    addSetupBtnClickEvent();
  }, []);

  // 關閉 背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (setupDrawerVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [setupDrawerVisible]);

  // 若為 編輯複製 將原本設定 帶入
  useEffect(() => {
    if (!isEditCopy) return;

  }, [isEditCopy]);

  // 依照學制科目 預先帶入對應的排版方式
  const wordSetting = useMemo(() => {
    if (!eduSubjectData) return;
    const edu = eduSubjectData.edu;
    const subject = eduSubjectData.subject;
    switch (edu) {
      case 'E':
        if (subject === 'CH') {
          return 'HSD';
        } else {
          return 'VHD';
        }
      case 'J':
        if (subject === 'PC') {
          return 'HSD';
        } else {
          return 'VHS';
        }
      case 'H':
        return 'VHS';
      default:
        return '';
    }
  }, [eduSubjectData]);

  return (
    isLoading ? renderLoadingContent()
      :
      <StyledHomeLayoutOptionContent className={className} data-testid="HomeLayoutOptionContent">
        {
          deviceData.isDesktop &&
          <>
            <Box mb={5} className='pageTitleBox'>
              <div className="pageTitle">題型檢視</div>
              {renderQuestionAnalysisModal()}
            </Box>
            <Box mb={11} pl={2}>
              <Table>
                <Table.Header>
                  <Table.Row textcenter={true} isFixedWidth={true}>
                    <Table.Item width={'72px'}>順序</Table.Item>
                    <Table.Item width={'416px'}>題型</Table.Item>
                    <Table.Item width={'72px'}>題數</Table.Item>
                    <Table.Item width={'72px'}>答數</Table.Item>
                    <Table.Item width={'72px'}>每題分數</Table.Item>
                    <Table.Item width={'72px'}>每答分數</Table.Item>
                    <Table.Item width={'120px'}>小計</Table.Item>
                  </Table.Row>
                </Table.Header>
                <Table.Body className="tableBody">
                  {
                    keyList && keyList.map((item, index) => (
                      <>
                        {
                          examPaperData[item]?.pickUpQuestions &&
                          <Table.Row isFixedWidth={true} textcenter={true} index={index} key={examPaperData[item].code}>
                            <Table.Item width={'72px'} textalign={'center'}>{index + 1}</Table.Item>
                            <Table.Item width={'416px'} textalign={'flex-start'}>{examPaperData[item].name}</Table.Item>
                            <Table.Item width={'72px'} textalign={'flex-end'}>{examPaperData[item].inputValue.question || 0}</Table.Item>
                            <Table.Item width={'72px'} textalign={'flex-end'}>
                              {examPaperData[item].inputValue.answer || 0}
                            </Table.Item>
                            <Table.Item width={'72px'} textalign={'flex-end'}>
                              {examPaperData[item].inputValue.score.perQues || 0}
                            </Table.Item>
                            <Table.Item width={'72px'} textalign={'flex-end'}>{examPaperData[item].inputValue.score.perAns}</Table.Item>
                            <Table.Item width={'120px'} textalign={'flex-end'}>{examPaperData[item].inputValue.score.allScore}</Table.Item>
                          </Table.Row>
                        }
                      </>
                    ))
                  }
                  <Table.Row>
                    <Table.Item className='tableFooter'>
                      總題數 {examPaperTotalScoreData.questionCount} 題，總分 {examPaperTotalScoreData.score} 分
                    </Table.Item>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Box>
          </>
        }
        <Box mb={5}>
          <div className="pageTitle">試卷類別</div>
        </Box>
        <Box mb={11} pl={2}>
          <div>
            <RadioGroup buttonStyle='solid' optionType='button' value={sendParams.examType} onChange={(e) => {onExamPaperInfoChange(e, 'examType');}} >
              {
                examPaperTypeOptions && examPaperTypeOptions.map(item => (
                  <Radio.Button value={item.value}>{item.label}</Radio.Button>
                ))
              }
            </RadioGroup>
          </div>
        </Box>
        <Box mb={5}>
          <div className="pageTitle">試卷類型</div>
        </Box>
        <Box mb={11} pl={2}>
          <div>
            <RadioGroup buttonStyle='solid' optionType='button' value={sendParams.outputType} onChange={(e) => {onExamPaperInfoChange(e, 'outputType');}} >
              {
                outputMap && outputMap.map(item => {
                  if (sendParams.outputType === 'Quick') {
                    if (item.value === 'Quick') {
                      return (
                        <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                      );
                    }
                  } else if (organizationType === 'Tutoring') {
                    if (item.value === 'Files') {
                      return (
                        <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                      );
                    }
                  } else if (organizationType === 'School') {
                    if (item.value === 'Files' || item.value === 'Quick') {
                      return (
                        <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                      );
                    }
                  } else {
                    return (
                      <Radio.Button key={item.value} value={item.value}>{item.label}</Radio.Button>
                    );
                  }
                })
              }
            </RadioGroup>
          </div>
        </Box>


        <ExamPaperSetting
          sendParams={sendParams}
          checkedSend={checkedSend}
          rowData={rowData}
          globalExamPaperData={globalExamPaperData}
          getSendParams={getSendParams}
          changeCheckedSend={changeCheckedSend}
          onSaveExamPaperInfo={onSaveExamPaperInfo}
          paperSizeMap={paperSizeMap}
          wordSettingMap={wordSettingMap}
          paperContent={paperContent}
          analyzeContent={analyzeContent}
          advancedSetting={advancedSetting}
          onlineAdvanced={onlineAdvanced}
          onlineAdvancedSetting={onlineAdvancedSetting}
        />

        {/* 題數配分 */}
        <Drawer
          visible={setupDrawerVisible}
          title={'題數與配分'}
          onClose={setupDrawerFalse}
          placement={'bottom'}
          isMobile={true}
          id={'setupDrawer'}
          className={'setupDrawer'}
          key={tableData}
          destroyOnClose={true}
        >
          <div className='setupDrawerTotalScore'>
            <span>總題數 {examPaperTotalScoreData.questionCount} 題，總分 {examPaperTotalScoreData.score} 分</span>
            <span className='tipText'>若要修改題數或配分，請回到第三步試題選擇調整。</span>
          </div>
          {
            pickUpQuestionID.length > 0 && renderQuestionAnalysisModal()
          }
          {
            keyList &&
              keyList.map((type) => {
                return (
                  <div className='questionTypeBox' key={type}>
                    <div className={'drawerTitle'}>
                      {examPaperData[type]?.name}
                    </div>
                    <div className={'drawerSection'}>
                      <div className={'drawerTable'}>
                        <div>
                          <div>已選題數</div>
                          <div>已答題數</div>
                        </div>
                        <div>
                          <div>
                            {examPaperData[type].inputValue.question}
                          </div>
                          <div>
                            {examPaperData[type].inputValue.answer}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={'drawerSection'}>
                      <div className={'drawerTable'}>
                        <div>
                          <div>每題分數</div>
                          <div>每答分數</div>
                        </div>
                        <div className={'setupDrawerScoreBox'}>
                          <div>{examPaperData[type].inputValue.score.perQues || '-'}</div>
                          <div>{examPaperData[type].inputValue.score.perAns || '-'}</div>
                        </div>
                      </div>
                    </div>
                    <div className={'drawerTotalScore'}>
                      小計 {examPaperData[type].inputValue.score.allScore} 分
                    </div>
                  </div>
                );
              })
          }
        </Drawer>

      </StyledHomeLayoutOptionContent>
  );
};

HomeLayoutOptionContent.propTypes = {
  className: PropTypes.string,
  examPaperData: PropTypes.array,
  keyList: PropTypes.array,
  searchKey: PropTypes.string,
  examPaperTotalScoreData: PropTypes.object,
  getCreateExamPaperParams: PropTypes.func,
  saveData: PropTypes.object,
  globalExamPaperData: PropTypes.object,
  getSaveData: PropTypes.func,
  getCheckedSend: PropTypes.func,
  organizationType: PropTypes.string
};
