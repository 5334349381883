import { Api } from '@oneclass/ui-components';
import { stringify } from 'query-string';

export const postCreateExamPaper = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/Create`, payload);
  return response;
};

export const putEditExamPaper = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Exam/Edit`, payload);
  return response;
};

export const putEditSpecifyExamPaper = async (payload, examUID) => {
  const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Exam/Edit/${examUID}`, payload);
  return response;
};


export const getQueryExamPaper = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Query`, payload);
  return response;
};

export const putExamPaperPublic = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Exam/Public`, payload);
  return response;
};

export const getExamPaperPreview = async (examUID) => {
  const queryString = stringify({ examUID });
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Preview?${queryString}`);
  return response;
};
// 查詢公開試卷列表
export const getExamPaperPublic = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Public`, payload);
  return response;
};
// 試卷收藏
export const putExamPaperFavorite = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Exam/Favorite`, payload);
  return response;
};

// 查詢組卷進度
export const getExamPaperExportProgress = async (UID) => {
  // const queryString = stringify({ UID });
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Export/${UID}`);
  return response;
};

// 查詢組卷進度
export const getExamPaperEditCopy = async (examUID, action) => {
  const queryString = stringify({ examUID, action });
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Question?${queryString}`);
  return response;
};

// -----  新ＡＰＩ -----

// 建立試卷
export const postExamCreate = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/CreateOne`, payload);
  return response;
};

// 編輯試卷
export const postExamEdit = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/EditOne`, payload);
  return response;
};

// 取得試卷資訊 （for 編輯 複製）
export const postExamGetData = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/Checkout`, payload);
  return response;
};