export const formateQuestionList = (data, allQuestion) => {
  let allDiffQuestion = [];
  if (!data) return [];
  Object.entries(data).forEach(([key, value]) => {
    allDiffQuestion = allDiffQuestion.concat(value.map(item => item?.uid));
  });
  return allQuestion.filter(item => allDiffQuestion.includes(item?.uid));
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    const hostname = window.location.hostname;
    if (hostname.indexOf('oneclass.com.tw') > 0) {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw';
    } else {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
    }
  }
};
export const deleteCookie = (name) => {
  const hostname = window.location.hostname;
  if (hostname.indexOf('oneclass.com.tw') > 0) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw';
  } else {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
  }
};
export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();

  const hostname = window.location.hostname;
  if (hostname.indexOf('oneclass.com.tw') > 0) {
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;domain=oneclass.com.tw';
  } else {
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }
};

export const getCookie = (name) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};


export const arrayUnique = (array) => {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }

  return a;
};
export const arrayEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};


export const blobDownload = (url, filename, type) => {
  const xhr = new XMLHttpRequest();
  xhr.open(type, url, true);
  xhr.responseType = 'blob';
  const requestOnload = () => {
    xhr.onload = function (e) {
      if (this.status === 200) {
        const blob = this.response;
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(a);
        }, 0);
      }
    };
  };
  if (type === 'POST') {
    xhr.setRequestHeader('Content-type', 'application/json');
    requestOnload();
  }
  if (type === 'GET') {
    requestOnload();
    xhr.send();
  }
};

export const transformTime = (timeString) => {
  let newString = new Date(+new Date(timeString) + 8 * 3600 * 1000).toISOString().replace('T', ' ');
  newString = newString.substring(0, 16);
  return newString;
};