import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useBoolean } from 'ahooks';
import update from 'immutability-helper';
import { Button, RadioGroup } from '@oneclass/onedesign';
import { Radio } from 'antd';
import { SearchPlusSolid, XmarkOutline, ChevronRightOutline, StarSolid, ReturnSolid, ChevronLeftOutline, UndoSolid, ExclamationCircleSolid } from '@onedesign/icon';
import { Box, Table, sectionToChinese, DifferentTag, diffStr, setSelectOptions, fetchDataMachine, FETCH_DATA_EVENT, Drawer, Input, Modal, openNotificationWithIcon, Select } from '@oneclass/ui-components';
import { formateQuestionList } from 'utils/common';
import { StyledKnowledgeQuestionReviewContent, StyledActionIcon, StyleReportModalContent } from './KnowledgeQuestionReviewContent.style';
import { useQuestionAnalysisModal } from 'utils/hooks/useQuestionAnalysisModal';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useTouch } from '../../utils/hooks/useTouch';
import { useMachine } from '@xstate/react';
import { getAnomalySelectOption } from 'api/drawUpFast';
import { Tooltip } from 'antd';
import { postQuestionReport } from 'api/question';

const DifficultyValue = {
  易: 1,
  中偏易: 2,
  中: 2,
  中偏難: 2,
  難: 3
};

export const KnowledgeQuestionReviewContent = ({
  className,
  examPaperData,
  prevKeyList,
  questions,
  saveData,
  examPaperTotalScoreData,
  getKeyList,
  getSaveData,
  searchKey,
  memberEduSubject,
  getTableData,
  eduSubjectData,
  searchParams,
}) => {

  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const [tableData, setTableData] = useState(examPaperData);
  const [sortQues, setSortQues] = useState('custom');
  const [modalImageSrc, setModalImageSrc] = useState(null);
  const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const [keyList, setKeyList] = useState(prevKeyList);
  const [questionList, setQuestionList] = useState({});
  const [changeArrayNum, setChangeArrayNum] = useState(0);
  const [targetPosition, setTargetPosition] = useState({
    first: '',
    second: '',
  });
  const [changeArray, setChangeArray] = useState([]);
  const [originalQues, setOriginalQues] = useState({});
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = keyList[dragIndex];
      setKeyList(
        update(keyList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [keyList],
  );

  //導入 試題分析 Hook
  const pickUpQuestionID = useMemo(() => {
    if (!tableData) return [];
    let nextData = [];
    Object.entries(tableData).forEach(([key, value]) => {
      if (value?.pickUpQuestions)
      {
        Object.entries(value.pickUpQuestions).forEach(([quesKey, quesValue]) => {
          quesValue.forEach(item => {
            nextData = [...nextData, item.uid];
          });

        });
      }

    });
    return nextData;
  }, [tableData]);

  const { renderQuestionAnalysisModal, doShowAnalysisModal } = useQuestionAnalysisModal(pickUpQuestionID, searchParams);

  useEffect(() => {
    if (Object.keys(questionList).length === 0 || !keyList) return;
    const nextData = keyList.filter(item => Object.keys(questionList).includes(item));
    setKeyList(nextData);
  }, [questionList]);

  const moveQuestion = useCallback(
    (key, value, dragIndex, hoverIndex) => {
      const dragCard = value[dragIndex];
      setQuestionList(
        {
          ...questionList,
          [key]: update(questionList[key], {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        }

      );
      setSortQues('custom');

      // 初始化 更換試題機制
      setTargetPosition({
        first: '',
        second: '',
      });
    },
    [questionList],
  );

  const showQuestionDetail = (src) => {
    setModalImageSrc(src);
    setModalVisibleToggle();
  };

  const shuffle = (array) => {
    let newArray = [].concat(array);
    for (let i = newArray.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const onSortQuesChange = (e) => {
    setSortQues(e.target.value);
  };

  useEffect(() => {
    if (Object.keys(tableData).length === 0) return [];
    let result = {};
    if (questionList && Object.keys(questionList).length > 0) {
      Object.entries(tableData).forEach(([key, value], index) => {
        const quesTypeData = formateQuestionList(value?.pickUpQuestions, questions);
        let nextQuesTypeData = [];
        if (quesTypeData.length > 0) {
          if (questionList[key]) {
            const prevData = questionList[key];
            const prevDataUid = prevData.map(item => item.uid);
            if (prevData.length < quesTypeData.length) {
              const diffData = quesTypeData.filter(item => !prevDataUid.includes(item.uid));
              nextQuesTypeData = prevData.concat(diffData);
            } else {
              const quesTypeDataUid = quesTypeData.map(item => item.uid);
              nextQuesTypeData = prevData.filter(item => quesTypeDataUid.includes(item.uid));
            }
            result = { ...result, [key]: nextQuesTypeData };
          } else {
            result = { ...result, [key]: quesTypeData };
          }

        }

      });
    } else {
      Object.entries(tableData).forEach(([key, value], index) => {
        const quesTypeData = formateQuestionList(value?.pickUpQuestions, questions);
        if (quesTypeData.length > 0) {
          result = { ...result, [key]: quesTypeData };
        }

      });
    }
    setQuestionList(result);
  }, [tableData, questions]);

  useEffect(() => {
    getKeyList(keyList);
  }, [keyList]);

  useEffect(() => {
    getSaveData('quesReviewQuestionRenderList', questionList);
  }, [questionList]);

  useEffect(() => {
    getSaveData('sortQues', sortQues);
  }, [sortQues]);

  useEffect(() => {
    if (saveData?.sortQues) {
      setSortQues(saveData?.sortQues);
    }
  }, []);

  useEffect(() => {
    let result = {};
    switch (sortQues) {
      case 'diff':
        Object.entries(questionList).forEach(([key, value]) => {
          const sortArray = value.sort((a, b) => DifficultyValue[a.DIFFICULTY] - DifficultyValue[b.DIFFICULTY]);
          result = {
            ...result,
            [key]: sortArray
          };
        });
        setQuestionList({
          ...result
        });
        break;
      case 'chapter':
        Object.entries(questionList).forEach(([key, value]) => {
          const sortArray = value.sort((a, b) => a?.chapter?.code.localeCompare(b?.chapter?.code));
          result = {
            ...result,
            [key]: sortArray
          };
        });
        setQuestionList({
          ...result
        });
        break;
      case 'random':
        Object.entries(questionList).forEach(([key, value]) => {
          result = {
            ...result,
            [key]: shuffle(value)
          };
        });
        setQuestionList({
          ...result
        });
        break;
      default:
        break;
    }
  }, [sortQues]);

  /// －－－－－ 手機版相關 －－－－－

  const [questionDetailDrawerVisible, { toggle: questionDetailDrawerToggle, setFalse: questionDetailDrawerFalse }] = useBoolean(false);
  const [setupDrawerVisible, { toggle: setupDrawerToggle, setFalse: setupDrawerFalse }] = useBoolean(false);

  // ---- 單一題目詳情頁面 -----
  const [questionDetailData, setQuestionDetailData] = useState({});
  const [detailIndex, setDetailIndex] = useState(0);
  const [detailType, setDetailType] = useState('');

  // 上一題 下一題 控制
  const preQues = () => {
    if (detailIndex === 0) {
      if (keyList.indexOf(detailType) !== 0) {
        const nextTypeIndex = keyList.indexOf(detailType) - 1;
        const nextType = keyList[nextTypeIndex];
        const nextQuesIndex = questionList[nextType].length - 1;
        setDetailType(nextType);
        setDetailIndex(nextQuesIndex);
        setQuestionDetailData(questionList[nextType][nextQuesIndex]);
      } else {
        return null;
      }
    } else {
      setDetailIndex(detailIndex - 1);
      setQuestionDetailData(questionList[detailType][detailIndex - 1]);
    }
  };

  const nextQues = () => {
    if (detailIndex === questionList[detailType].length - 1) {
      if (keyList.indexOf(detailType) !== keyList.length - 1) {
        const nextTypeIndex = keyList.indexOf(detailType) + 1;
        const nextType = keyList[nextTypeIndex];
        setDetailType(nextType);
        setDetailIndex(0);
        setQuestionDetailData(questionList[nextType][0]);
      } else {
        return null;
      }
    } else {
      setDetailIndex(detailIndex + 1);
      setQuestionDetailData(questionList[detailType][detailIndex + 1]);
    }
  };

  const isShowPreQuesBtn = useMemo(() => {
    if (keyList.indexOf(detailType) === 0 && detailIndex === 0) {
      return false;
    } else {
      return true;
    }
  }, [keyList, detailType, detailIndex]);

  const isShowNextQuesBtn = useMemo(() => {
    if (keyList.indexOf(detailType) === keyList.length - 1 && detailIndex === questionList[detailType].length - 1) {
      return false;
    } else {
      return true;
    }
  }, [keyList, detailType, detailIndex, questionList]);

  // 錯題回報
  const [reportDrawerVisible, { toggle: reportDrawerToggle, setFalse: reportDrawerFalse }] = useBoolean(false);

  // 關閉 背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (setupDrawerVisible | questionDetailDrawerVisible | reportDrawerVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [setupDrawerVisible, questionDetailDrawerVisible, reportDrawerVisible]);

  const mobileShowQuestionDetail = (item, index, type) => {
    setQuestionDetailData(item);
    setDetailIndex(index);
    setDetailType(type);
    questionDetailDrawerToggle();
  };

  //  判斷左右滑動事件
  const { onTouchStart, onTouchEnd } = useTouch({
    leftToRight: () => {
      if (!(keyList.indexOf(detailType) === 0 && detailIndex === 0)) setTimeout(preQues(), 100);
    },
    rightToLeft: () => {
      if (!(keyList.indexOf(detailType) === keyList.length - 1 && detailIndex === questionList[detailType].length - 1)) setTimeout(nextQues(), 100);
    },
    XGap: 50,
  });

  // ---- 動作 - 問題回報  ----

  // 問題回報彈窗 顯示控制
  const [reportModalVisible, { toggle: setReportModalToggle, setFalse: setReportModalFalse }] = useBoolean(false);

  const showReportModal = (itemUID) => {
    setReportModalData({
      ...reportModalData,
      education: eduSubjectData.edu,
      subject: eduSubjectData.subject,
      uid: itemUID,
    });
    if (reportModalVisible === false) sendGetReportModalSelectOptionsState(FETCH_DATA_EVENT.FETCH);
    setReportModalToggle();
  };

  //  試題回報內容
  const [reportModalData, setReportModalData] = useState({
    education: '',
    subject: '',
    uid: '',
    anomalyType: '',
    description: '',
  });

  // 取得錯題回報相關選單
  const [getReportModalSelectOptionsState, sendGetReportModalSelectOptionsState] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context) => {
        const res = await getAnomalySelectOption();
        const { data, isSuccess, systemCode, message } = res;
        return {
          reportModalSelectOptions: setSelectOptions(data) || {}
          , isSuccess, systemCode, message
        };
      },
    },
  });
  const { reportModalSelectOptions } = getReportModalSelectOptionsState.context.result || {};

  // 更改回報類型
  const onSelectChange = (value) => {
    setReportModalData({
      ...reportModalData,
      errorType: value,
    });
  };
  // 更改回報內容
  const onTextAreaChange = (e) => {
    setReportModalData({
      ...reportModalData,
      description: e.target.value,
    });
  };

  // 試題回報API
  const [steatePostQuestionReport, sendPostQuestionReport] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postQuestionReport(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', '回報成功');
        } else {
          openNotificationWithIcon('error', message);
        }
        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  // 關閉彈窗時 清除彈窗內資料
  useEffect(() => {
    if (reportModalVisible === false) {
      setReportModalData({
        education: '',
        subject: '',
        uid: '',
        anomalyType: '',
        description: '',
      });
    }
  }, [reportModalVisible]);

  // 錯誤回報 抽屜
  const showReportDrawer = (uid) => {
    setReportModalData({
      ...reportModalData,
      education: memberEduSubject.edu,
      subject: memberEduSubject.subject,
      uid,
    });
    if (reportModalVisible === false) sendGetReportModalSelectOptionsState(FETCH_DATA_EVENT.FETCH);
    reportDrawerToggle();
  };

  const onReportTypeChange = (e) => {
    setReportModalData({
      ...reportModalData,
      anomalyType: e.target.value,
    });
  };

  const doMobileReport = () => {
    sendPostQuestionReport(FETCH_DATA_EVENT.FETCH, {
      payload: {
        questionUID: reportModalData.uid,
        errorType: reportModalData.anomalyType,
        description: reportModalData.description,
        eduSubject: reportModalData.education + reportModalData.subject,
      }
    });
    reportDrawerFalse();
  };

  // 確定回報
  const doReport = () => {
    sendPostQuestionReport(FETCH_DATA_EVENT.FETCH, {
      payload: {
        questionUID: reportModalData.uid,
        errorType: reportModalData.anomalyType,
        description: reportModalData.description,
        eduSubject: reportModalData.education + reportModalData.subject,
      }
    });
    setReportModalToggle();
  };

  // 關閉彈窗時 清除彈窗內資料
  useEffect(() => {
    if (reportDrawerVisible === false) {
      setReportModalData({
        education: '',
        subject: '',
        uid: '',
        anomalyType: '',
        description: '',
      });
    }
  }, [reportDrawerVisible]);

  // 動態新增 題型與配分Btn 到 header 上面
  const addSetupBtnClickEvent = () => {
    const setupBtn = document.getElementById('setupBtn');
    if (setupBtn) {
      setupBtn.addEventListener('click', function() {
        setupDrawerToggle();
      });
    }
  };

  useEffect(() => {
    addSetupBtnClickEvent();
  }, []);

  useEffect(() => {
    setTableData(examPaperData);
  }, [examPaperData]);

  useEffect(() => {
    addSetupBtnClickEvent();
  }, []);

  // ---------------------------- 更換試題 ----------------------------

  const countBallPool = (ques) => {
    if (!ques) return [];
    const quesChapter = ques?.chapter?.code;
    const quesType = ques?.metaData?.questionType?.code;
    const selectedArray = questionList[ques?.metaData?.questionType?.name].map((ques) => {
      return ques?.uid;
    });
    const filterQuestions = questions.filter((ques) => {
      return (ques?.chapter?.code === quesChapter && ques?.metaData?.questionType?.code === quesType && selectedArray.indexOf(ques.uid) === -1);
    });
    // return (!filterQuestions || filterQuestions.length === 0) ? false : true;
    return filterQuestions || [];
  };

  const changeQuestionHandler = (item, index, i) => {
    if (!item) return;

    setTargetPosition({
      first: index,
      second: i,
    });
    let num = changeArrayNum;
    let nextQuestion = item;

    const quesChapter = item?.chapter?.code;
    const quesType = item?.metaData?.questionType?.code;
    const quesKnowledge = item?.metaData?.knowledge?.code;
    const quesDiff = item?.metaData?.difficulty?.code;

    const selectedArray = questionList[item.metaData.questionType.name].map((item) => {
      return item.uid;
    });

    //  符合更換條件的題目
    if (!questions) return;
    const filterQuestions = questions.filter((ques) => {
      return (ques?.chapter?.code === quesChapter && ques?.metaData?.questionType?.code === quesType && selectedArray.indexOf(ques.uid) === -1);
    });

    if (!filterQuestions || filterQuestions.length === 0) {
      return null;
    }

    // 當是不同位置之題目時
    if (targetPosition.first !== index || targetPosition.second !== i) {
      num = 0;
      setOriginalQues(item);

      // 設定 相同優先度 知識向度 > 難易度
      const reBuildArray = filterQuestions.map((item) => {
        let knowledgePriority = 0;
        let difficultyPriority = 0;
        if (item?.metaData?.knowledge?.code === quesKnowledge) knowledgePriority = 1;
        if (item?.metaData?.difficulty?.code === quesDiff) difficultyPriority = 1;
        return {
          ...item,
          knowledgePriority,
          difficultyPriority,
        };
      });

      // 排序 更換優先度
      let sortArray = reBuildArray.sort(function(a, b) {
        return b.knowledgePriority - a.knowledgePriority;
      }).sort(function(a, b) {
        return b.knowledgePriority - a.knowledgePriority;
      });

      setChangeArray(sortArray);
      nextQuestion = sortArray[num];
      num ++;
    } else {
      // 同一題目時，更換其他相似題目。
      if (num > changeArray.length - 1) {
        num = 0;
        nextQuestion = originalQues;
      } else {
        nextQuestion = changeArray[num];
        num ++;
      }
    }
    setChangeArrayNum(num);

    let array = [];
    array = array.concat(questionList[tableData[item?.metaData?.questionType?.name]?.name]);
    array[i] = nextQuestion;
    let newQuestionList = { ...questionList };
    newQuestionList = {
      ...newQuestionList,
      [tableData[item?.metaData?.questionType?.name]?.name]: array
    };
    setQuestionList(newQuestionList);

    // setTimeout(() => {
    changeTableData(item, nextQuestion);
    // }, 500);
  };

  const changeTableData = (oldQues, newQues) => {
    if (!oldQues || !newQues) return null;
    const typeName = oldQues?.metaData?.questionType?.name;
    const oldQues_difficulty = oldQues?.metaData?.difficulty?.code;
    const newQues_difficulty = newQues?.metaData?.difficulty?.code;

    let nextData = { ...tableData };
    let Array = [];
    const found = nextData[typeName]?.question.find(ele => ele.uid === newQues.uid);
    if (!found) return null;

    // delete
    const deleteArray = Array.concat(nextData[typeName].pickUpQuestions[oldQues_difficulty]).filter((item) => {
      return item.uid !== oldQues.uid;
    });
    nextData = {
      ...nextData,
      [typeName]: {
        ...nextData[typeName],
        pickUpQuestions: {
          ...nextData[typeName].pickUpQuestions,
          [oldQues_difficulty]: deleteArray
        }
      }
    };

    // add
    let addArray = Array.concat(nextData[typeName].pickUpQuestions[newQues_difficulty]);
    addArray.push(found);
    nextData = {
      ...nextData,
      [typeName]: {
        ...nextData[typeName],
        pickUpQuestions: {
          ...nextData[typeName].pickUpQuestions,
          [newQues_difficulty]: addArray
        }
      }
    };

    getTableData({
      ...nextData
    });
  };

  const isShowText = (question, firstIndex, secondIndex) => {
    let res = false;
    if (firstIndex === targetPosition.first && secondIndex === targetPosition.second && (changeArrayNum === countBallPool(question).length)) {
      res = true;
    }
    return res;
  };

  return (
    <StyledKnowledgeQuestionReviewContent className={className} data-testid="KnowledgeQuestionReviewContent" id={'KnowledgeQuestionReviewContent'}>
      <Box mb={5} className='pageTitleBox'>
        <div className="pageTitle">題型檢視</div>
        {deviceData.isDesktop && renderQuestionAnalysisModal()}
      </Box>
      <Box mb={deviceData.isDesktop ? 11 : 8} pl={deviceData.isDesktop ? 2 : 0}>
        <Table>
          <Table.Header>
            <Table.Row textcenter={true} className="tableHeader">
              <Table.Item flex={0.75} textalign={'center'} >順序</Table.Item>
              <Table.Item flex={10} textalign={'left'}>題型</Table.Item>
              {
                deviceData.isDesktop &&
                <>
                  <Table.Item flex={1}>題數</Table.Item>
                  <Table.Item flex={1}>答數</Table.Item>
                </>
              }
            </Table.Row>
          </Table.Header>
          <Table.Body className="tableBody">
            {
              keyList && keyList.map((item, index) => (
                <Table.TableDndRow textcenter={true} id={tableData[item].code} index={index} key={tableData[item].code} moveCard={moveCard}>
                  <Table.Item flex={10} textalign={'flex-start'}>{tableData[item].name}</Table.Item>
                  {
                    deviceData.isDesktop &&
                    <>
                      <Table.Item flex={1} textalign={'flex-end'}>{tableData[item].inputValue.question || 0}</Table.Item>
                      <Table.Item flex={1} textalign={'flex-end'}>{tableData[item].inputValue.answer || 0}</Table.Item>
                    </>
                  }
                </Table.TableDndRow>
              ))
            }
            <Table.Row>
              <Table.Item className='tableFooter'>
                總題數 {examPaperTotalScoreData.questionCount} 題
              </Table.Item>
            </Table.Row>
            { !deviceData.isDesktop && <div className='blackDiv'></div>}
          </Table.Body>
        </Table>
      </Box>
      <Box mb={5}>
        <div className="pageTitle">試題順序調整</div>
      </Box>
      <Box mb={deviceData.isDesktop ? 11 : 10} pl={deviceData.isDesktop ? 2 : 0}>
        <div className="radioSwitch">
          <RadioGroup buttonStyle='solid' optionType='button' value={sortQues} onChange={(e) => {onSortQuesChange(e);}} >
            <Radio.Button value='diff'>依難度排序</Radio.Button>
            <Radio.Button value='chapter'>依章節排序</Radio.Button>
            <Radio.Button value='random'>隨機排序</Radio.Button>
            <Radio.Button className="radioHaveSvg" value='custom'>自訂排序</Radio.Button>
          </RadioGroup>
        </div>
      </Box>
      {
        keyList && keyList.length > 0 && keyList.filter(item => Object.keys(questionList).includes(item)).map((key, index) => {
          if (questionList[key]) {
            return (
              questionList[key] &&
                <Box mb={deviceData.isDesktop ? 11 : 8} key={key}>
                  <Box mb={5}>
                    <div className="pageTitle">{`${sectionToChinese(index + 1)}、${key}`}</div>
                  </Box>
                  <Table>
                    <Table.Header className="questionTableHeader">
                      <Table.Row textcenter={true}>
                        <Table.Item flex={0.5} width='50px' textalign={'center'} >順序</Table.Item>
                        <Table.Item flex={0.5} textalign={'center'}>題號</Table.Item>
                        <Table.Item flex={3.5}>題目內容</Table.Item>
                        <Table.Item flex={0.5}>標籤</Table.Item>
                        <Table.Item flex={2}>題目資訊</Table.Item>
                        <Table.Item flex={0.75}>更換試題</Table.Item>
                        <Table.Item flex={0.75}>錯誤回報</Table.Item>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body className="questionTableBody">
                      {
                        questionList[key].map((question, i) => (
                          <Table.TableDndRow DndItemFlex={'0.5'} textcenter={true} id={question.uid} index={i} key={question.uid} moveCard={(dragIndex, hoverIndex) => {moveQuestion(key, questionList[key], dragIndex, hoverIndex);}} >
                            <Table.Item flex={0.5} textalign={'center'}>{i + 1}</Table.Item>
                            <Table.Item className="questionItem" flex={3.5} onClick={() => !deviceData.isDesktop && mobileShowQuestionDetail(question, i, key)}>
                              <div className="questionContent">
                                {
                                  question.content ? <div className="questionContentText">{question.content}</div> : <img className="questionImage" src={question.image} alt='img'/>
                                }
                                {
                                  deviceData.isDesktop && <div className="contentDetail" onClick={() => {showQuestionDetail(question.image);}}><SearchPlusSolid/></div>
                                }
                              </div>
                            </Table.Item>
                            {
                              deviceData.isDesktop &&
                              <>
                                <Table.Item flex="0.5" textalign={'center'}><DifferentTag mr={1} variant={diffStr[question.metaData.difficulty.code]} /></Table.Item>
                                <Table.Item flex={2}>
                                  <div className="metaBlock">
                                    {
                                      question?.questionGroup &&
                                      <div className="meta">
                                        作答方式:
                                        <span className="metaContent">
                                          {question?.questionGroup}
                                        </span>
                                      </div>
                                    }
                                    {
                                      question?.metaData?.questionType?.name &&
                                      <div className="meta">
                                        題型:
                                        <span className="metaContent">
                                          {question?.metaData?.questionType?.name}
                                        </span>
                                      </div>
                                    }
                                    {
                                      question?.metaData?.source?.name &&
                                      <div className="meta">
                                        出處:
                                        <span className="metaContent">
                                          {question?.metaData?.source?.name}
                                        </span>
                                      </div>
                                    }
                                    {
                                      question?.metaData?.learnContent?.name &&
                                      <div className="meta">
                                        學習內容:
                                        <span className="metaContent">
                                          {question?.metaData?.learnContent?.name}
                                        </span>
                                      </div>
                                    }
                                    {
                                      question?.metaData?.knowledge?.name &&
                                      <div className="meta">
                                        知識向度:
                                        <span className="metaContent">
                                          {question?.metaData?.knowledge?.name}
                                        </span>
                                      </div>
                                    }
                                  </div>
                                </Table.Item>
                              </>
                            }
                            <Table.Item flex={0.75} textalign={'center'}>
                              {
                                countBallPool(question).length > 0
                                  ?
                                  <Tooltip
                                    trigger={isShowText(question, index, i) ? 'hover' : ''}
                                    placement="top"
                                    getPopupContainer={() => document.getElementById('KnowledgeQuestionReviewContent') || document.body}
                                    title={
                                      <div className='tooltipText'>
                                        <ExclamationCircleSolid />可更換試題已全數顯示
                                      </div>
                                    }
                                    key={question?.uid}
                                  >
                                    <UndoSolid
                                      className='createQuizBtn iconBtn'
                                      onClick={(e) => {changeQuestionHandler(question, index, i);}}
                                    />
                                  </Tooltip>
                                  :
                                  <Tooltip
                                    trigger={deviceData.isDesktop ? 'hover' : 'click'}
                                    placement="top"
                                    title={
                                      <span className='tooltipText' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>無相似試題可更換</span>
                                    }
                                    zIndex={100}
                                    color='#4e4e5a'
                                    getPopupContainer={() => document.getElementById('KnowledgeQuestionReviewContent') || document.body}
                                  >
                                    <StyledActionIcon
                                      disabled={true}
                                      className='createQuizBtn'
                                      onClick={(e) => {e.stopPropagation();changeQuestionHandler(question, index, i);}}
                                    >
                                      <UndoSolid/>
                                    </StyledActionIcon>
                                  </Tooltip>
                              }
                            </Table.Item>
                            {
                              deviceData.isDesktop &&
                              <Table.Item flex={0.75} textalign={'center'}>
                                <ReturnSolid className={'iconBtn'} onClick={(e) => {e.stopPropagation();showReportModal(question?.uid);}}/>
                              </Table.Item>
                            }
                          </Table.TableDndRow>
                        ))
                      }
                    </Table.Body>
                  </Table>
                </Box>
            );
          }


        })
      }

      {
        modalVisible &&
        <div className="questionImageModal">
          <div className="questionImageModalContent">
            <div className="questionImageModalClose" onClick={() => {setModalVisibleFalse();}}><XmarkOutline/></div>
            <div className="questionImageModalContentImage">
              <img src={modalImageSrc} alt="img" />
            </div>
          </div>
        </div>
      }

      {/* 試題詳情彈窗 */}
      {
        questionDetailData && JSON.stringify(questionDetailData) !== '{}' &&
          <Modal
            id={'questionDetailDrawer'}
            visible={questionDetailDrawerVisible}
            zIndex={1000}
            onCancel={questionDetailDrawerFalse}
            width="100%"
            closeIcon={<ChevronRightOutline className={'mobileCloseBtn'}/>}
          >
            <div
              onTouchEnd={(e) => {onTouchEnd(e);}}
              onTouchStart={(e) => {onTouchStart(e);}}
            >
              <div className='detailImgBox'>
                <div className={'backBtnColorDiv'}></div>
                <img src={questionDetailData.image} alt={questionDetailData.uid}></img>
                {
                  isShowPreQuesBtn &&
                    <div className='preQuesBtn' onClick={preQues}><ChevronLeftOutline/></div>
                }
                {
                  isShowNextQuesBtn &&
                    <div className='nextQuesBtn' onClick={nextQues}><ChevronRightOutline/></div>
                }
              </div>
              <div className='detailControlBox'>
                <div>
                  <div><StarSolid/>收藏</div>
                  <div onClick={() => {showReportDrawer(questionDetailData.uid);}}><ReturnSolid/>回報</div>
                </div>
              </div>
              <div className='detailContent' id={'questionDetailDrawer'}>
                <div>
                  <div>{questionDetailData.ANSWER_TYPE}</div>
                  <div>
                    <DifferentTag mr={1} variant={diffStr[questionDetailData?.metaData?.difficulty?.code]} />
                  </div>
                </div>
                <div>
                  <div>題型</div>
                  <div>{questionDetailData?.metaData?.questionType?.name}</div>
                </div>
                <div>
                  <div>出處</div>
                  <div>{questionDetailData?.metaData?.source?.name}</div>
                </div>
                <div>
                  <div>學習內容</div>
                  <div>{questionDetailData?.metaData?.learnContent?.name}</div>
                </div>
                <div>
                  <div>知識項度</div>
                  <div>{questionDetailData?.metaData?.knowledge?.name}</div>
                </div>
              </div>
            </div>
          </Modal>
      }

      {/* 錯誤回報視窗 */}
      <Drawer
        visible={reportDrawerVisible}
        title={'錯誤回報'}
        onClose={reportDrawerFalse}
        placement={'bottom'}
        isMobile={true}
        id={'reportDrawer'}
        className={'reportDrawer'}
        footer={
          <>
            <Button
              variant={!reportModalData.anomalyType || !reportModalData.description ? 'ui02' : 'brand2'}
              disabled={!reportModalData.anomalyType || !reportModalData.description}
              onClick={doMobileReport}>送出</Button>
            <Button variant='ui01' onClick={reportDrawerFalse}>取消</Button>
          </>
        }
      >
        <div className='radioBox'>
          <div>原因<span>*</span></div>
          <Radio.Group onChange={onReportTypeChange} value={reportModalData.anomalyType}>
            {
              reportModalSelectOptions &&
              reportModalSelectOptions.map((item) => {
                return (<Radio key={item.value} value={item.value}>{item.name}</Radio>);
              })
            }
          </Radio.Group>
        </div>
        <div className='explanationBox'>
          <div>說明<span>*</span></div>
          <Input.TextArea
            className='textArea'
            autoSize={true}
            onChange={onTextAreaChange}
            key={reportModalData.uid}
            placeholder={'請輸入說明'}/>
        </div>
      </Drawer>

      {/* 題數配分 */}
      <Drawer
        visible={setupDrawerVisible}
        title={'題數與配分'}
        onClose={setupDrawerFalse}
        placement={'bottom'}
        isMobile={true}
        id={'setupDrawer'}
        className={'setupDrawer'}
        key={tableData}
        destroyOnClose={true}
      >
        <div className='setupDrawerTotalScore'>
          <span>總題數 {examPaperTotalScoreData.questionCount} 題</span>
        </div>
        {
          pickUpQuestionID.length > 0 && renderQuestionAnalysisModal()
        }
        {
          keyList &&
            keyList.map((type) => {
              return (
                <div className='questionTypeBox' key={type}>
                  <div className={'drawerTitle'}>
                    {tableData[type]?.name}
                  </div>
                  <div className={'drawerSection'}>
                    <div className={'drawerTable'}>
                      <div>
                        <div>已選題數</div>
                        <div>已答題數</div>
                      </div>
                      <div>
                        <div>
                          {tableData[type].inputValue.question}
                        </div>
                        <div>
                          {tableData[type].inputValue.answer}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
        }
      </Drawer>

      <Modal
        visible={reportModalVisible}
        zIndex={1001}
        onCancel={setReportModalFalse}
        title={'錯誤回報'}
        width="576px"
        footer={
          <>
            <Button className='reportModalBtn' variant='ui01' onClick={setReportModalFalse}>取消</Button>
            <Button className='reportModalBtn' variant={!reportModalData.errorType || !reportModalData.description ? 'ui02' : 'brand2'}
              disabled={!reportModalData.errorType || !reportModalData.description}
              onClick={doReport}>確定</Button>
          </>
        }
      >
        <StyleReportModalContent>
          <div>
            <div>原因</div>
            <Select className='select' options={reportModalSelectOptions}
              value={reportModalSelectOptions?.value} onChange={onSelectChange}
              allowClear={false} placeholder={'請選擇'}>
            </Select>
          </div>
          <div>
            <div>說明</div>
            <Input.TextArea className='textArea' autoSize={true}
              onChange={onTextAreaChange} placeholder={'必填'}></Input.TextArea>
          </div>
        </StyleReportModalContent>
      </Modal>

    </StyledKnowledgeQuestionReviewContent>
  );
};