import styled from 'styled-components';

export const StyledMobileFilterContent = styled.div`
    
`;

export const StyleReportModalContent = styled.div`
    >div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div:nth-child(1){
            width:40px;
        }
    }
    >div:nth-child(1){
        margin-bottom:10px;
    }
    >div:nth-child(2){
        align-items: flex-start;
    }
    .select{
        width: 130px;
    }
    .textArea{
        width: 90%;
    }
`;

export const StyledAreaSelectList = styled.div`
    background: #fff;
    margin-bottom: 8px;
`;

export const StyledAreaSelectContainer = styled.div`
    flex:1;
    border: 1px solid #E4E7EC;
    min-height:400px;
    max-height:${({ nowheight }) => `${nowheight}px` || 'auto'};
    overflow-y: auto;
    .areaSelectHeader{
        background: ${({ theme }) => theme.colors.scale.$brand01.$brand01_01};
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 12px;
        }
        .areaSelectContent{
            background: ${({ theme }) => theme.colors.ui.ui_02};
            .areaSelectContentTitle{
                background: #fff;
                margin-bottom: 8px;
            }
            ${StyledAreaSelectList}{
                border-bottom: 2px solid ${({ theme }) => theme.colors.ui.ui_02};
                :last-child{
                    border-bottom:none;
                }
                ${StyledAreaSelectList}{
                        padding-left: 8px;
                        ${StyledAreaSelectList}{
                            padding-left: 8px;
                            border-bottom:none;
                        }
                    }
                }
                
            }
`;

export const StyledAreaSelectRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    color:${({ theme, active }) => active ? theme.colors.text.white : theme.colors.text.text_07};
    background:${({ theme, active }) => active ? theme.colors.scale.$brand01.$brand01_10 : theme.colors.ui.white};
    &.bookRow{
        border-bottom: 2px solid ${({ theme }) => theme.colors.ui.ui_02};
    }
    :hover{
        background:${({ theme, active }) => active ? theme.colors.scale.$brand01.$brand01_10 : theme.colors.scale.$brand01.$brand01_01};
    }
    svg{
        font-size: 24px;
    }
    & >div{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .count{
        padding: 2px 4px;
        margin: 0px 0px 0 16px;
        border-radius: 4px;
        &.count1{
            background: ${({ theme, active }) => active ? theme.colors.ui.white : theme.colors.brand.brand02};
            color:${({ theme, active }) => active ? theme.colors.scale.$brand01.$brand01_10 : theme.colors.text.white};
        }
        &.count2{
            background: ${({ theme, active }) => active ? theme.colors.ui.white : theme.colors.scale.$brand01.$brand01_05};
            color:${({ theme, active }) => active ? theme.colors.scale.$brand01.$brand01_10 : theme.colors.text.white};
        }
        &.count3{
            color:${({ theme, active }) => active ? theme.colors.text.white : theme.colors.brand.brand02};
        }
        &.count4{
            font-size: 11px;
            color:${({ theme, active }) => active ? theme.colors.text.white : theme.colors.text.text_05};
        }
    }            
`;

export const StyledFilterBar = styled.div`
    position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
    svg{
            margin-right: 0 !important;
        }
	> *{
		margin-bottom: 8px;
		&:not(:last-child){
			margin-right: 8px;
		}
	}
`;
export const StyledHomeQuestionSelectContent = styled.div.attrs(props => ({
  className: props.className
}))`
    position: relative;
    padding: 32px 16px;
    width: 100%;

    .selectedBox{
        height: 1000px !important;
        width: 243px !important;
    }

    .flexTitle{
        display: flex;
        align-items: center;
        .pageTitle{
            margin-right: 16px;
        }
    }
    .pageTitle{
        font-size: 18px;
        padding-left: 16px;
        font-weight:500;
    }
    .areaSelectTag{
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        font-size:14px;
        padding: 4px 16px;
        border-radius: 8px 8px 0px 0px;
        background: ${({ theme }) => theme.colors.scale.$brand01.$brand01_01};
        div{
            display: flex;
            align-items: center;
        }
        .prevSvg{
            color:${({ theme }) => theme.colors.scale.$brand01.$brand01_10}
        }
        .nextSvg{
            margin-right: 0;
            margin-left:8px;
        }
    }
    .tableZone{
        display: flex;
        .tableContent{
            flex:3;
            > div{
                height:unset;
            }
            .metaBlock{
                display: flex;
                flex-direction: column;
                >div{
                    margin:4px 0;
                }
                .meta{
                    color:${({ theme }) => theme.colors.text.text_05};
                    .metaContent{
                        color:${({ theme }) => theme.colors.text.text_07};
                    }
                }
            }
        }
        
    }
    
    .tableFooter{
        display: flex;
        justify-content:flex-end;
    }
    .tableBody{
        min-height: auto;
        & >div{
            &> div{
                padding: 8px 16px;
            }
        }
    }
    .questionTableBody{
        min-height: auto;
        max-height: none;
        .ant-checkbox-inner{
            border: 1px solid black;
        }
        .questionImage {
            width: 100%;
            height: auto;
            @media (max-width: 1024px) {
                max-width: 100%;
            }
        }
        .emptyMsg{
            display: flex;
            justify-content: center;
            margin-top:16px;
        }
        .questionItem {
          position: relative;
          .questionContent{
            overflow: hidden;
            .questionContentText{
                width: 94%;
                margin: auto;
            }
            .contentDetail{
                position: absolute;
                cursor: pointer;
                bottom: 12px;
                right:0;
                padding: 4px;
                border-radius: 4px;
                opacity: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                background: ${({ theme }) => theme.colors.ui.ui_07};
                color:#fff;
                font-size: 24px;
                svg{
                    margin-right: 0;
                }
            }
          }
        }
    }

    .questionImageModal{
        position: fixed;
        top:0;
        left:0;
        z-index: 9999;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: rgba(0, 0, 0, 0.6);
        svg{
                    margin-right: 0;
                }
        .questionImageModalContent{
            width: 60%;
            background: ${({ theme }) => theme.colors.ui.white};
            opacity: 1;
            position: relative;
            top: 10%;
            border-radius: 4px;
            img{
                width: 100%;
            }
            .questionImageModalClose{
                position: absolute;
                top: 0;
                right: -54px;
                padding:4px;
                border: 1px solid ${({ theme }) => theme.colors.ui.white};
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                color:${({ theme }) => theme.colors.text.white};
            }
            .questionImageModalContentImage{
                overflow-y: auto;
                max-height:80vh
            }
        }
    }
    .pageBlock{
        margin:44px 0 12px;
    }
    .controlBox{
        font-size: 20px;
        flex-wrap: wrap;
        align-content: center;
        >svg{
            color: ${({ theme }) => theme.colors.text.text_05};
            cursor: pointer;
            margin: 6.75px 5px;
        }
        >svg:nth-child(1){
            /* margin-bottom: 12.5px; */
            &:hover{
                color: #F9C74F;
            }
        }
        >svg:nth-child(2):hover{
            color: ${({ theme }) => theme.colors.text.text_07};
        }
    }
    .reportModalBtn{
        min-width: 52px !important;
        min-height: 32px !important;
    }
    .pageTitleBox{
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    .pieChartBox{
        width: 40%;
        height: 378px;
    }
    .barChartBox{
        width: 100%;
    }
    .StyledPieChartTable{
        width: 55%;
        height: 378px;
        overflow: auto;
    }
    .knowledgeChartBox{
        width: 100%;
        height: 340px;
        overflow: auto;
    }
    .colorSquare{
        width: 16px;
        height: 16px;
    }
    .ant-tabs-top > .ant-tabs-nav{
        margin: 0 0 32px 0;
    }
    .knowledgeControlBox{
        width: 100%;
        display: flex;
        background-color:rgba(250, 250, 252, 1) ;
        border-bottom:1px solid rgba(228, 231, 236, 1);
        >div{
            padding: 8px 16px;
            color:rgba(69, 75, 92, 1);
        }
        >div:nth-child(1){
            text-align:right;
            width: 35%;
        }
        >div:nth-child(2){
            text-align:left;
            width: 65%;
        }
    }
    .toolTipIcon{
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		color: #fff;
		width: 15px;
		height: 15px;
		cursor: pointer;
		border-radius: 50%;
		background-color: #8B90A0;
		margin-left: 10px;
	}
    @media (max-width: 1024px) {
        .pageBlock{
            .ant-pagination{
                >li{
                    margin-right: 5px;
                }
            }
        }
        .selectedFilter{
            color: ${({ theme }) => theme.colors.brand.brand02};
        }
        .clearBtn{
            min-width: 25px;
            min-height: 25px;
            max-width: 25px;
            max-height: 25px;
        }
        .mobileCloseBtn{
            font-size: 16px;
            transform: rotate(180deg);
        }
        .tableContent{
            max-width:100%;
            .emptyMsg{
                margin-top: 44px;
                color: ${({ theme }) => theme.colors.text.text_06};
            }
        }
        .ant-pagination-options{
            display: flex;
            margin: 16px 0 0 0;
            justify-content: center;
            align-items: center;
        }
        .filterBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;
            color:${({ theme }) => theme.colors.text.text_06};
            >div:nth-child(2){
                >img{
                    margin-right: 9px;
                }
            }
        }
        .rowStyle{
            border: 1px solid ${({ theme }) => theme.colors.ui.ui_02};
            overflow: hidden;
            border-radius: 8px;
            margin-bottom: 8px;
            >div{
                border-bottom: 0px !important;
            }
            >div:nth-child(2){
                overflow: hidden;
            }
        }
        .triangleZone{
            display: none;
        }
        /// 單一題目詳情頁面
        .ant-modal-content{
			width: 100vw;
			height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
            height: calc(var(--vh, 1vh) * 100);
		}
        #questionDetailDrawer{
            .ant-modal-close{
                left: 0 !important;
                right: unset !important;
                color: #fff;
            }
            .ant-modal-content{
                width: 100vw;
                height: calc(var(--vh, 1vh) * 100);
                border-radius: 0px;
                background-color: #E5E5E5;
                .ant-modal-body{
                    padding: 0 0 0 0;
                }
            }
        }
        .isHidden .ant-modal-mask,.isHidden .ant-modal-wrap{
            z-index: 900 !important;
        }
        .detailImgBox{
            position: relative;
            width: 100%;
            height: 516px;
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            .backBtnColorDiv{
                position: absolute;
                top: 0px;
                width: 100vw;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
                height: 63px;
            }
            >img{
                width: 100%;
                max-height: 516px;
            }
            .preQuesBtn,.nextQuesBtn{
                position: absolute;
                bottom:20px;
                height: 44px;
                width: 44px;
                border-radius: 50%;
                overflow: hidden;
                background: rgba(69, 75, 92, 0.5);
                color:#fff;
                display: flex;
                align-items: center;
                justify-content: center;
                >svg{
                    margin: 0 0;
                    font-size: 20px;
                }
            }
            .preQuesBtn{
                left: 8px;
            }
            .nextQuesBtn{
                right:8px;
            }
        }
        .detailControlBox{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 16px;
            background-color: #fff;
            margin-bottom: 8px;
            >div:nth-child(1){
                display: flex;
                align-items: center;
                >div{
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                    >svg{
                        font-size: 24px;
                    }
                }
                >div:nth-child(1){
                    margin-right: 30px;
                    svg{
                        color: ${({ theme }) => theme.colors.ui.ui_04};
                    }
                }
                >div:nth-child(2){
                    svg{
                        color: ${({ theme }) => theme.colors.ui.ui_05};
                    }
                }
            }
            >div:nth-child(2){
                padding: 8px 20px;
                border-radius: 8px;
                border: 1px solid #D5D7DE;
                >label{
                    margin-right: 8px;
                }
            }
            .checked{
                border: 1px solid ${({ theme }) => theme.colors.brand.brand02} !important;
                color: #fff;
                background-color: ${({ theme }) => theme.colors.brand.brand02};
                .ant-checkbox-inner{
                    background-color: #fff !important;
                    border-color: #fff !important;
                }
                .ant-checkbox-inner:after{
                    border-color:${({ theme }) => theme.colors.brand.brand02} !important;
                }
            }
            .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
                border:1px solid #E4E7EC;
            }
        }
        .detailContent{
            background-color: #fff;
            padding: 16px 24px;
            >div{
                padding: 0 5px;
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                color: ${(props) => props.theme.colors.text.text_07};
                >div:nth-child(1){
                    width: 20%;
                    text-align: right;
                    width: 60px;
                    margin-right: 20px;
                    color: ${(props) => props.theme.colors.text.text_06};
                }
                >div:nth-child(2){
                    width: 80%;
                }
            }
            >div:nth-child(1){
                padding: 0 0;
                margin-bottom: 12px;
                >div:nth-child(1){
                    text-align: left;
                    width: auto;
                    font-size: 16px;
                    color: ${(props) => props.theme.colors.text.text_10};
                }
                >div:nth-child(2){
                    width: auto;
                    >div{
                        padding: 2px 8px;
                    }
                }
            }
        }
        
        .reportDrawer{
            .ant-drawer-content-wrapper{
                height: auto !important;
                max-height:calc(var(--vh, 1vh) * 100);
                overflow: auto !important;
            }
            .ant-drawer-footer{
                >div:nth-child(2){
                    margin-top: 12px;
                }
            }
            .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
                border-color: ${({ theme }) => theme.colors.brand.brand01};
            }
            .ant-radio-inner::after{
                background-color: ${({ theme }) => theme.colors.brand.brand01};
            }
            .ant-radio-checked .ant-radio-inner{
                border-color: ${({ theme }) => theme.colors.brand.brand01};
            }
            .radioBox{
                margin-bottom: 20px;
                >div:nth-child(1){
                    margin-bottom: 15px;
                    span{
                        color: red;
                    }
                }
                >div:nth-child(2){
                    padding: 0 5px;
                    display: flex;
                    flex-direction: column;
                    >label{
                        margin-bottom: 8px !important;
                    }
                }
            }
            .explanationBox{
                >div:nth-child(1){
                    margin-bottom: 15px;
                    span{
                        color: red;
                    }
                } 
                .textArea{
                    >textarea{
                        min-height: 130px !important;
                        max-height: 200px !important;
                    }
                }
            }
        }
        .setupDrawer{
            .ant-drawer-content-wrapper{
                height: unset !important;
            }
            .ant-drawer-content{
                overflow: hidden;
                border-radius:12px 12px 0 0;
                height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
                height: calc(var(--vh, 1vh) * 100);
            }
            .ant-drawer-body{
                padding: 16px 24px 16px !important;
                height: calc(calc(var(--vh, 1vh) * 100) - 54px - 84px) !important;
                overflow-y: auto;
            }
            // 配題與配分
            .setupDrawerTotalScore{
                color: ${({ theme }) => theme.colors.brand.brand03};
                padding: 0 0 8px 0;
                border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
                margin-bottom: 24px;
            }
            .questionTypeBox{
                margin-bottom: 24px;
            }
            .drawerTitle{
                font-size:16px;
                color:${({ theme }) => theme.colors.text.text_07};
                margin-bottom:24px;
            }
            .drawerSection{
                font-size:14px;
                color:${({ theme }) => theme.colors.text.text_07};
                margin-bottom:8px;
            }
            .sectionSubTitle{
                margin-bottom: 8px;
                font-size:14px;
                color:${({ theme }) => theme.colors.text.text_06};                        
            }
            .drawerTable{
                border-bottom: 1px solid #E4E7EC;
                >div{
                    display: flex;
                    >div{
                        width: 50%;
                        padding: 8px 16px;
                        >span{
                            color:${({ theme }) => theme.colors.text.text_06};                        
                        }
                    }
                }
                >div:nth-child(1){
                    background-color:${({ theme }) => theme.colors.ui.ui_01};
                }
                >div:nth-child(2){
                    display: flex;
                    >div{
                        align-items:flex-end;
                        display: flex;
                        >div{
                            width: 50%;
                            margin-right: 4px;
                        }
                    }
                }
            }
            .drawerTotalScore{
                display: flex;
                justify-content:flex-end;
                color: ${({ theme }) => theme.colors.brand.brand03};
                padding:8px 16px;
                width: 100%;
                border-bottom: 1px solid #E4E7EC;
            }
            .setupDrawerScoreBox{
                .ant-input{
                    width: 50%;
                }
                input{
                    text-align: right;
                }
            }
            .chartPieSolidIcon{
                position: absolute;
                top: 16px;
                right: 20px;
            }
            .ant-drawer-close{
                right: unset !important;
                left: 0px !important;
            }
        }
        #filterDrawer{
            .ant-modal-close{
                right: unset !important;
                left: 0px !important;
            }
            .clearAllFilterBtn{
                position: absolute;
                right: 20px;
                top: 21px;
                font-size: 14px;
                font-weight: normal;
                color:${({ theme }) => theme.colors.text.text_04};
            }
            .clearAllFilterBtn.active{
                color: ${({ theme }) => theme.colors.brand.brand03};
            }
            .totalCountIcon{
                padding: 2px 4px;
                color: #fff;
                background-color: ${({ theme }) => theme.colors.brand.brand02};
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .ant-modal-content{
                overflow: auto;
            }
            .ant-modal-body{
                padding: 12px 24px 12px;
            }
            .ant-modal-footer{
                padding: 10px 24px 25px;
                >div{
                    >div{
                        width: 100%;
                    }
                }
            }
            .filterDrawerContent{
                >div{
                    display: flex;
                    >div:nth-child(1){
                        width: 27%;
                        font-size: 16px;
                        color:${({ theme }) => theme.colors.text.text_10};
                    }
                    >div:nth-child(2){
                        width: 73%;
                    }
                    &:not(:first-child){
                        align-items: center;
                        border-top: 1px solid #E4E7EC;
                        >div{
                            padding: 15px 0;
                        }
                    }
                }
                >div:nth-child(1){
                    /* padding: 10px 0; */
                    >div:nth-child(2){
                        >label{
                            padding: 8px 16px;
                            margin: 0 8px 8px 0 ;
                        }
                    }
                }
                
            }
            .difficultyRadioOptions{
                >label{
                    padding: 6px 24px;
                    border-radius: 20px;
                }
            }
            .reportModalBtn{
                margin-right: 0px;
            }
            .defaultText{
                color:${({ theme }) => theme.colors.text.text_05};
            }
            .activeText{
                >div{
                    color:${({ theme }) => theme.colors.text.text_07};
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    >span{
                        padding-right: 13px;
                    }
                    >svg{
                        margin: 0 0;
                        font-size: 25px;
                        /* margin-left: 13px; */
                        color:${({ theme }) => theme.colors.ui.ui_04} ;
                    }
                }
            }
            .activeDifficulty{
                display: flex;
                align-items: center;
                justify-content: space-between;
                >svg{
                    font-size: 25px;
                    color:${({ theme }) => theme.colors.ui.ui_04} ;
                    margin-right: 0px;
                }
            }
        }
        #filterSelectDrawer{
            .ant-modal-close{
                left: 0px !important;
                right: unset !important;
            }
            .ant-tree-treenode {
                position: relative;
                width: 100%;
            }
            .ant-tree-switcher{
                position: absolute;
                right: 0;
            }
            .ant-tree-switcher > svg {
                font-size: 14px;
                transition: 0.3s;
                transform: rotate(90deg);
                color:${({ theme }) => theme.colors.ui.ui_04};
            }
            .ant-tree-switcher_open > svg {
                transform: rotate(-90deg);
            }
            .ant-modal-body{
                background-color: #fff;
            }
            .ant-radio-group{
                display: flex;
                justify-content: center;
                flex-direction: column;
                >label{
                    margin-bottom: 16px;
                }
            }
            .clearKeyBtn{
                position: absolute;
                height: 63px;
                display: flex;
                align-items: center;
                justify-content: center;
                right: 20px;
                top: 0;
                font-size: 14px;
                color:${({ theme }) => theme.colors.text.text_06};
            }
            .clearKeyBtn.active{
                color:${({ theme }) => theme.colors.brand.brand03};
            }
            .hideRadio{
                display: none;
            }
        }
    }
`;




