import styled from 'styled-components';
export const StyledFilterBar = styled.div`
    position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
    svg{
        margin-right: 0px;
    }
	> *{
		&:not(:last-child){
			margin-right: 8px;
		}
	}
`;

export const StyledActionIcon = styled.div`
>div{
    width: 20px;
    height:20px;
}
    svg {
        cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'} ;
        font-size: 24px;
        color:${({ theme, disabled }) => disabled ? theme.colors.ui.ui_03 : theme.colors.ui.ui_07};
        margin-right: 0 !important;
        margin: 0 4px !important;
        &:not(:last-child){
            margin-right: 19.5px;
        }
    }
`;
export const StyledTestListDesktop = styled.div.attrs(props => ({
  className: props.className
}))`
/* width: 872px; */
width: 960px;
margin:auto;
padding-top:32px;
.ant-tooltip-inner{
    border-radius: 4px;
}
.pageTitle{
    font-size: 18px;
    padding: 4px 0 0 8px;
    font-weight:500;
}
.tableZone{
    > div > div >div{
        padding:12px 0 12px 16px;
        box-sizing: border-box;
    }
}
.tableEmpty{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    >div{
        padding:4px 0 !important;
        color:${({ theme }) => theme.colors.text.text_05};
    }
    .makeATextPaperBtn{
        margin-top: 32px;
        color: #fff !important;
        padding: 8px 34px !important;
    }
}
.pageBlock{
    padding:34px 0 56px;
}
.tableBtn{
    padding: 9px 10px;
}
#detailModal{
    .modalContent{
        display: flex;
        justify-content: space-between;
        .editBtn{
            background: #FFFFFF;
            border: 1px solid #D5D7DE;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
            border-radius: 4px;
            &.disabled{
                color: #A1A4B1;
            }
            >span{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .modalRow{
            flex:1
        }
        .modalInnerTitle{
            font-weight: 500;
            font-size: 16px;
            color:${({ theme }) => theme.colors.text.text_07};
        }
        .editBox{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >div{
                margin-right: 24px;
                padding: 4px 12px;
                color:${({ theme }) => theme.colors.brand.brand02};
            }
        }
        .modalInnerContent{
            padding:16px 20px;
            .modalInfoRow{
                display: flex;
                color:${({ theme }) => theme.colors.text.text_06};
                &:not(:first-child){
                    margin-top: 16px;
                }
                > div{
                    width: 35%;
                }
                > span{
                    width: 80%;
                    color:${({ theme }) => theme.colors.text.text_07};
                    word-break: break-all;
                }
            }
            .buttonZone{
                display: flex;
                >div{
                    &:first-child{
                        padding:5px 12px;
                    }
                    &:not(:last-child){
                        margin-right: 8px;
                    }
                }
            }
            .modalPublicContent{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .modalPublicContentTitle{
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 20px;
                    }
                }
                .modalHint{
                    padding: 4px 28px;
                    font-size: 11px;
                    color:${({ theme }) => theme.colors.text.text_06};
                }
            }
        }
        .copyEditBtnBox{
            display: flex;
            align-items: center;
            >div{
                padding:5px 12px;
                &:not(:last-child){
                        margin-right: 8px;
                    }
                >span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .countPer{
            padding: 0 12px;
        }
        .exportTip{
            padding: 0 12px;
            font-size: 11px;
        }
    }
}
#testModal{
    .ant-modal-title{
        justify-content: center;
    }
    .ant-tabs-tab-btn{
        color:${({ theme }) => theme.colors.text.text_05};
    }
}
.copyBtn{
    cursor: pointer;
}
.ant-tabs{
    padding-top:24px;
}
.ant-tabs-nav{
    margin-bottom: 48px !important;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 16px 32px !important;
}
`;