/* eslint-disable react/prop-types */
import React, { useRef, useContext } from 'react';
import QRCode from 'qrcode.react';
import { StyledTestQrCodeModalContent } from './TestQrCodeModalContent.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';

export const TestQrCodeModalContent = ({ className, examLink, modalInfo, shareModalData }) => {
  const linkRef = useRef(null);
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);

  return (
    <StyledTestQrCodeModalContent className={className} data-testid="TestQrCodeModalContent">
      <div className='testModalContent'>
        <div className='testModalLinkBox'>
          <div className='testModalLinkTitle'>{modalInfo?.ExamName}</div>
          <div className='endTime'>
            <span>測驗結束時間</span>
            <div>{`${shareModalData?.endTime?.month}/${shareModalData?.endTime?.day} ${shareModalData?.endTime?.time}`}</div>
          </div>
          <div className='testModalLink' id="testModalLink">
            <div>
              <div className='qrCodeBox'>
                <QRCode
                  id="bill_qr_code_url"
                  value={`${examLink}/quiz/${modalInfo.QuizCode}`} //value引數為生成二維碼的連結 我這裡是由後端返回
                  size={deviceData.isDesktop ? 200 : 144} //二維碼的寬高尺寸
                  fgColor="#000000"  //二維碼的顏色
                />
              </div>
            </div>
            <div>
              <input disabled className='testModalLinkData' ref={linkRef} value={`${examLink}/quiz/${modalInfo.QuizCode}`} />
            </div>
          </div>
        </div>

        <div className='testModalManualBox borderLine'>
          請同學掃描 QR code 或輸入網址，完成測驗
        </div>

        <div className='testModalLinkBox'>
          <div className='titleText'>查看測驗結果</div>
          <div className='testModalLink' id="testModalLink">
            <div>
              <div className='qrCodeBox'>
                <QRCode
                  id="bill_qr_code_url"
                  value={examLink} //value引數為生成二維碼的連結 我這裡是由後端返回
                  size={deviceData.isDesktop ? 200 : 144} //二維碼的寬高尺寸
                  fgColor="#000000"  //二維碼的顏色
                />
              </div>
            </div>
            <div>
              <input disabled className='testModalLinkData' ref={linkRef} value={examLink} />
            </div>
          </div>
        </div>

        <div className='testModalManualBox'>
          請同學掃描 QR code 或輸入網址，前往網站點擊右上角「查看測驗結果」，輸入測驗碼 {shareModalData.quizCode.top4} {shareModalData.quizCode.last4}、姓名與座號，點擊查看後複習錯題
        </div>

      </div>
    </StyledTestQrCodeModalContent>
  );
};