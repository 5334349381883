/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import {
  Box,
  Table,
  Select,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  FETCH_DATA_STATE,
  setSelectOptions,
  Pagination,
  Modal,
  Switch,
  sliceTableData,
  AuthProvider,
  EditCopyExamPaperProvider,
  CreateExamPaperParamsProvider,
} from '@oneclass/ui-components';
import { Tooltip } from 'antd';
import { getQueryExamPaper, putExamPaperPublic, postExamGetData } from 'api/examPaper';
import { FileDownloadSolid, CopySolid, FileEditSolid, GlobeAsiaSolid, ExternalLinkAltSolid, SlidersHSolid, RocketSolid } from '@onedesign/icon';
import { Button, RadioGroup } from '@oneclass/onedesign';
import { useMachine } from '@xstate/react';
import { getTime, format } from 'date-fns';
import { useBoolean } from 'ahooks';
import { getExamPaperPreview } from 'api/examPaper';
import { blobDownload, getCookie, deleteCookie } from 'utils/common';
import { useLocation, useHistory } from 'react-router-dom';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import classNames from 'classnames';
import { useCreateQuizExamModal } from 'utils/hooks/useCreateQuizExamModal';
import { StyledExamPaperListContent, StyledFilterBar, StyledActionIcon } from './ExamPaperListContent.style';
import { useLoadingContent } from 'utils/hooks/useLoadingContent';
import { default as deviceProvider } from 'providers/deviceProvider.js';

export const ExamPaperListContent = ({ className, setEduSubjectModalToggle, tabType, tabChange }) => {
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const location = useLocation();
  // const env = process.env.REACT_APP_NODE_ENV;
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const { editCopyExamPaperData, editCopyEditExamPaperChange } = useContext(EditCopyExamPaperProvider.EditCopyExamPaperContext);
  const { createExamPaperParams, createExamPaperParamsChange } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const history = useHistory();
  const [filterParams, setFilterParams] = useState({});
  const [modalInfo, setModalInfo] = useState(null);
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const identity = authState.context.userDetails.userStatus?.identity;
  const organization = authState.context.userDetails.userStatus?.organization?.type;
  const showCreateQuizBtn = organization !== 'Tutoring' && (identity === 'Editor' || identity === 'Teacher' || identity === 'Admin');
  const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const { renderLoadingContent, isLoading, setIsLoading } = useLoadingContent();

  const [stateGetQueryExamPaper, sendGetQueryExamPaper] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        setIsLoading(true);
        const res = await getQueryExamPaper();
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) setIsLoading(false);
        const { yearMap = [], eduSubjectMap = [], typeMap = [], patternMap = [], examPaper = [] } = data || {};
        return {
          isSuccess, systemCode, message,
          yearOptions: setSelectOptions(yearMap) || [],
          eduSubjectOptions: setSelectOptions(eduSubjectMap) || [],
          typeOptions: setSelectOptions(typeMap) || [],
          patternOptions: setSelectOptions(patternMap) || [],
          examPaper,
        };
      },
    },
  });

  useEffect(() => {
    if (authState.context.userDetails?.userStatus) {
      const { organization } = authState.context.userDetails?.userStatus;
      if (organization.type !== 'Tutoring') {
        return;
      } else {
        if ((authState.value === 'loggedIn' || authState.value === 'settingEduSubject')) {
          if (getCookie('createNewPaperBackHome') !== null) {
            setEduSubjectModalToggle();
            deleteCookie('createNewPaperBackHome');
            deleteCookie('createNewPaper');
          }
        }
      }
    }
  }, [authState.value]);

  const [statePutExamPaperPublic, sendPutExamPaperPublic] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await putExamPaperPublic(event.payload);
        const { isSuccess, systemCode, message } = res;

        return {
          isSuccess, systemCode, message,
        };
      },
    },
  });

  const [_, sendGetExamPaperPreview] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await getExamPaperPreview(event.examUID);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) window.open(data);

        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  // 取得 單一 試卷資料
  const [statePostExamGetData, sendPostExamGetData] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await postExamGetData(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        const itemData = event.itemData;
        const newData = {
          ...data,
          edu: itemData.attributes.education,
          subject: itemData.attributes.subject,
          output: itemData.attributes.outputType,
          eduSubjectName: {
            edu: itemData.attributes.eduName,
            subject: itemData.attributes.subjectName,
          }
        };

        createExamPaperParamsChange(
          itemData.attributes.education,
          itemData.attributes.subject,
          itemData.attributes.outputType,
          {
            edu: itemData.attributes.eduName,
            subject: itemData.attributes.subjectName,
          }
        );
        return {
          isSuccess, systemCode, message, examPaperData: { ...newData, paperData: itemData }, actionType: event.action
        };
      },
    },
  });

  const {
    eduSubjectOptions,
    typeOptions,
    patternOptions,
    examPaper
  } = stateGetQueryExamPaper.context.result || {};

  // const {
  //   examPaperData,
  //   actionType
  // } = stateGetExamPaperEditCopy.context.result || {};

  const {
    examPaperData,
    actionType
  } = statePostExamGetData.context.result || {};

  const filterData = useMemo(() => {
    if (!examPaper) return [];

    const nextData = examPaper.filter((item) => {
      const checkResult = Object.entries(filterParams).map(([key, value]) => {
        if (value && value.length === 0) return true;
        if (!value && value !== 0) return true;
        if (key === 'eduSubject') {
          const listEduSubject = `${item.attributes.education}${item.attributes.subject}`;
          return listEduSubject === value;
        } else {
          return item.attributes[key] === value;
        }

      });
      const checkResultSet = new Set(checkResult);
      if (!checkResultSet.has(false)) return item;

    });
    return nextData;
  }, [examPaper, filterParams]);

  const canOperateExamPaper = useMemo(() => {
    if (!authState.context.userDetails?.userStatus) return;
    const { identity } = authState.context.userDetails?.userStatus;
    return identity === 'Admin' ||
            identity === 'Editor' ||
            identity === 'Teacher';
  }, [authState.context.userDetails]);


  const goToExamPage = (examUID) => {
    if (!examUID) return;
    sendGetExamPaperPreview(FETCH_DATA_EVENT.FETCH, {
      examUID
    });

  };
  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  const setFilterCondition = (value, key) => {
    setFilterParams({
      ...filterParams,
      [key]: value
    });
  };
  const showDetailModal = (data) => {
    if (!data) return;
    setModalInfo(data,
      setModalVisibleToggle());

  };
  const changeExamPaperPublicStatus = (uid, checked) => {
    sendPutExamPaperPublic(FETCH_DATA_EVENT.FETCH, {
      payload: {
        examUID: uid,
        isPublic: checked
      }
    });
  };
  const copyEditExamPaper = (item, action) => {
    // sendGetExamPaperEditCopy(FETCH_DATA_EVENT.FETCH, {
    //   examUID: item.uid,
    //   action
    // });
    sendPostExamGetData(FETCH_DATA_EVENT.FETCH, {
      payload: {
        examUID: item.uid,
      },
      action,
      itemData: item,
    });
  };
  const redirectCreateExamPaper = (type) => {
    let url = '';
    switch (type) {
      case '手動命題':
        url = 'manualProposition';
        break;
      case '智能命題':
        url = 'studentIntelligentProposition';
        break;
      case '知識向度命題':
        url = 'knowledgeProposition';
        break;
      case '電腦命題':
      default:
        url = 'computerQuestion';
        break;
    }
    return url;
  };

  useEffect(() => {
    if (location.pathname === '/examPaperList' && tabType === 'paperList') {
      sendGetQueryExamPaper(FETCH_DATA_EVENT.FETCH);
    }
  }, [location.pathname, tabType]);

  useEffect(() => {
    if (statePutExamPaperPublic.matches(FETCH_DATA_STATE.DONE)) {
      sendGetQueryExamPaper(FETCH_DATA_EVENT.FETCH);
    }
  }, [statePutExamPaperPublic.value]);

  useEffect(() => {
    if (examPaperData) {
      editCopyEditExamPaperChange({
        ...examPaperData,
        ...examPaperData.result2.searchCondition,
      }, actionType);
      // history.push(redirectCreateExamPaper(examPaperData.pattern));
      history.push(redirectCreateExamPaper(examPaperData.paperData.drawUpName));
      // .attribute
    }
  }, [examPaperData]);

  useEffect(() => {
    setPageState({
      currentPage: 1,
      limit: 10,
    });
  }, [filterParams]);

  // 手機版相關

  const [filterDrawerVisible, { toggle: filterDrawerToggle, setFalse: filterDrawerFalse }] = useBoolean(false);

  // 詳情彈窗開啟時 關閉背景滑動
  useEffect(() => {
    document.body.classList.remove('mobileScrollingStop');
    if (!deviceData.isDesktop) {
      if (modalVisible | filterDrawerVisible) {
        document.body.classList.add('mobileScrollingStop');
      }
    }
  }, [modalVisible, filterDrawerVisible]);

  // 重新組成 學制 / 科目 列表
  const eduOptions = useMemo(() => {
    let eduArray = [];
    let checkArray = [];
    if (eduSubjectOptions) {
      eduSubjectOptions.forEach((item) => {
        if (checkArray.indexOf(item.value.slice(0, 1)) === -1) {
          let order = 0;
          if (item.value.slice(0, 1) === 'J') order = 1;
          if (item.value.slice(0, 1) === 'H') order = 2;
          eduArray.push({
            label: item.name.slice(0, 2),
            value: item.value.slice(0, 1),
            order
          });
          checkArray.push(item.value.slice(0, 1));
        }
      });
    }
    return eduArray.sort((a, b) => {
      return a.order - b.order;
    });
  }, [eduSubjectOptions]);

  const subjectOptions = useMemo(() => {
    let subjectArray = {};
    if (eduSubjectOptions) {
      eduSubjectOptions.forEach((item) => {
        if (subjectArray[item.value.slice(0, 1)]) {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)].push({
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 3)
          });
          subjectArray = next;
        } else {
          let next = { ...subjectArray };
          next[item.value.slice(0, 1)] = [{
            label: item.name.slice(2, 10),
            value: item.value.slice(1, 3)
          }];
          subjectArray = next;
        }
      });
    }
    return subjectArray;
  }, [eduSubjectOptions]);

  const [filterParamsMobile, setFilterParamsMobile] = useState({});
  const [eduSubjectMobile, setEduSubjectMobile] = useState({
    edu: '',
    subject: '',
  });

  useEffect(() => {
    if (eduSubjectMobile.edu && eduSubjectMobile.subject) {
      setFilterParamsMobile({
        ...filterParamsMobile,
        eduSubject: eduSubjectMobile.edu + eduSubjectMobile.subject,
      });
    }
  }, [eduSubjectMobile]);

  const transformMobileOptions = (array) => {
    let options = [];
    if (array && array.length > 0) {
      array.forEach((item) => {
        options.push({
          label: item.name,
          value: item.value
        });
      });
    }
    return options;
  };

  const changeEduSubjectMobile = (val, type) => {
    if (type === 'edu') {
      setEduSubjectMobile({
        edu: val,
        subject: '',
      });
    } else {
      setEduSubjectMobile({
        ...eduSubjectMobile,
        subject: val,
      });
    }
    setFilterIsChanged(true);
  };

  const changeFilterParamsMobile = (val, type) => {
    setFilterParamsMobile({
      ...filterParamsMobile,
      [type]: val,
    });
    setFilterIsChanged(true);
  };

  const showMobileFilter = () => {
    filterDrawerToggle();
    setFilterIsChanged(false);
    setEduSubjectMobile({
      edu: filterParams?.eduSubject ? filterParams?.eduSubject.slice(0, 1) : '',
      subject: filterParams?.eduSubject ? filterParams?.eduSubject.slice(1, 3) : '',
    });
    setFilterParamsMobile(filterParams);
  };

  const clearAllFilter = () => {
    if (Object.keys(filterParamsMobile).length !== 0 || eduSubjectMobile.edu) {
      setFilterParamsMobile({});
      setEduSubjectMobile({
        edu: '',
        subject: '',
      });
      setFilterIsChanged(true);
    }
  };

  const [filterIsChanged, setFilterIsChanged] = useState(false);

  const checkChangeFilterBtn = useMemo(() => {
    if (filterIsChanged) {
      if (eduSubjectMobile.edu) {
        if (eduSubjectMobile.subject) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [eduSubjectMobile, filterIsChanged]);

  const doneChangeFilter = () => {
    setFilterParams(filterParamsMobile);
    filterDrawerFalse();
  };

  const jumpToExamList = () => {
    setModalVisibleFalse();
    setTimeout(function () {
      tabChange('examList');
    }, 1000);
  };

  const { renderCreateQuizExamModal, createQuizExamModalToggle, setCreateQuizExamModalFalse } = useCreateQuizExamModal(jumpToExamList);

  return (
    <StyledExamPaperListContent className={className} data-testid="ExamPaperListContent">
      {
        deviceData.isDesktop ?
          <Box mb={4}>
            <StyledFilterBar>
              <Select placeholder='學制科目' allowClear={filterParams?.eduSubject !== null ? true : false} options={eduSubjectOptions} value={filterParams?.eduSubject !== null && filterParams?.eduSubject} onChange={(val) => {setFilterCondition(val, 'eduSubject');}} />
              <Select placeholder='考試別' allowClear={filterParams?.examType !== null ? true : false} options={typeOptions} value={filterParams?.examType !== null && filterParams?.examType} onChange={(val) => {setFilterCondition(val, 'examType');}} />
              <Select placeholder='命題方式' allowClear={filterParams?.drawUp !== null ? true : false} options={patternOptions} value={filterParams?.drawUp !== null && filterParams?.drawUp} onChange={(val) => {setFilterCondition(val, 'drawUp');}} />
            </StyledFilterBar>
          </Box>
          :
          <div className='filterBtnBox'>
            <div className={Object.keys(filterParams).length !== 0 ? 'active' : ''}>
              <SlidersHSolid onClick={showMobileFilter}/>
            </div>
          </div>
      }
      <Box>
        <Table className="tableZone">
          <Table.Header className='tableHeader'>
            <Table.Row isFixedWidth={true}>
              <Table.Item width={'213px'}>試卷名稱</Table.Item>
              <Table.Item width={'89px'}>學制科目</Table.Item>
              <Table.Item width={'89px'}>考試別</Table.Item>
              <Table.Item width={'117px'}>命題方式</Table.Item>
              <Table.Item width={'144px'}>出卷時間</Table.Item>
              <Table.Item width={'88px'}>編輯複製</Table.Item>
              <Table.Item width={'120px'}>操作</Table.Item>
              <Table.Item width={'100px'}>查看更多</Table.Item>
            </Table.Row>
          </Table.Header>
          {
            isLoading
              ?
              renderLoadingContent()
              :
              <Table.Body bodyMaxHeight={true}>
                {
                  filterData && filterData.length > 0 ? sliceTableData(filterData, pageState.currentPage, pageState.limit).map(item => (
                    <Table.Row key={item.uid} isFixedWidth={true} className='tableRow' onClick={() => !deviceData.isDesktop && showDetailModal(item)}>
                      {
                        deviceData.isDesktop ?
                          <>
                            <Table.Item width={'213px'} >{item.name}</Table.Item>
                            <Table.Item width={'89px'} >{item.eduSubjectName}</Table.Item>
                            <Table.Item width={'89px'}>{item.examTypeName}</Table.Item>
                            <Table.Item width={'117px'}>{item.drawUpName}</Table.Item>
                            <Table.Item width={'144px'}>{format(getTime(new Date(item.updateTime)), 'yyyy/MM/dd HH:mm')}</Table.Item>
                            <Table.Item width={'88px'}>
                              <Tooltip placement="top" title={'複製試卷'} zIndex={100} color='#4e4e5a'>
                                <StyledActionIcon disabled={!canOperateExamPaper} onClick={() => {copyEditExamPaper(item, 'Copy');}}><CopySolid /></StyledActionIcon>
                              </Tooltip>
                              <Tooltip placement="top" title={'編輯試卷'} zIndex={100} color='#4e4e5a'>
                                <StyledActionIcon disabled={!canOperateExamPaper} onClick={() => {copyEditExamPaper(item, 'Edit');}}><FileEditSolid /></StyledActionIcon>
                              </Tooltip>
                            </Table.Item>
                            <Table.Item width={'120px'}>
                              <Tooltip placement="top" title={'預覽試卷'} zIndex={100} color='#4e4e5a'>
                                <StyledActionIcon onClick={() => {goToExamPage(item.uid);}}><ExternalLinkAltSolid /></StyledActionIcon>
                              </Tooltip>
                              <Tooltip placement="top" title={'下載試卷'} zIndex={100} color='#4e4e5a'>
                                <StyledActionIcon
                                  disabled={!(item.downloadName && item.download)}
                                  onClick=
                                    {
                                      !(item.downloadName && item.download) ? null
                                        : () => {
                                          blobDownload(
                                            item.download,
                                            item.downloadName,
                                            'GET'
                                          );
                                        }
                                    }>
                                  <FileDownloadSolid />
                                </StyledActionIcon>
                              </Tooltip>
                              {
                                showCreateQuizBtn ?
                                  <Tooltip placement="top" title={<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>建立測驗<br/>（僅限速測卷使用）</span>} zIndex={100} color='#4e4e5a'>
                                    <StyledActionIcon disabled={item.attributes.outputType !== 'Quick'} className='createQuizBtn' onClick={item.attributes.outputType !== 'Quick' ? null : () => createQuizExamModalToggle(item, 'create')}><RocketSolid/></StyledActionIcon>
                                  </Tooltip> : null
                              }
                            </Table.Item>
                            <Table.Item className='moreDetail' width={'100px'}><Button variant="brand1" onClick={() => {showDetailModal(item);}}>查看更多</Button></Table.Item>
                          </>
                          :
                          <>
                            <Table.Item>{item.eduSubjectName}</Table.Item>
                            <Table.Item>{item.name}</Table.Item>
                            <Table.Item>{format(getTime(new Date(item.updateTime)), 'yyyy/MM/dd HH:mm')}</Table.Item>
                          </>
                      }
                    </Table.Row>
                  )) :
                    <div className="tableEmpty">
                      <div>
                        目前還未擁有任何試卷喔！
                      </div>
                      <div>
                        選擇上方命題方式出卷，或搜尋試卷並收藏吧！
                      </div>
                      {
                        authState?.context?.userDetails &&
                        <>
                          <Button borderRadius='24px' variant={'brand1'} className="makeATextPaperBtn" onClick={() => setEduSubjectModalToggle()}>
                              我要出卷
                          </Button>
                        </>
                      }
                    </div>
                }
              </Table.Body>
          }
        </Table>
        <div className="pageBlock">
          <Pagination
            total={filterData?.length || 0}
            defaultCurrent={1}
            current={pageState.currentPage}
            onChange={pageChange}
            defaultPageSize={pageState.limit}
          />
        </div>
      </Box>
      {
        modalInfo ?
          <Modal
            id={'detailModal'}
            visible={modalVisible}
            width={'860px'}
            onCancel={setModalVisibleFalse}
            title={
              deviceData.isDesktop ? <div>{modalInfo.name}</div> : '試卷詳情'
            }
          >
            <div className="modalContent">
              <div className="modalRow">
                <Box mb={4}>
                  <div className="modalInnerTitle">試卷資料</div>
                  <div className="modalInnerContent">
                    {
                      !deviceData.isDesktop ? <><div className="modalInfoRow"><div>試卷名稱</div><span>{modalInfo.name}</span></div></> : null
                    }
                    <div className="modalInfoRow"><div>學制科目</div><span>{modalInfo.eduSubjectName}</span></div>
                    <div className="modalInfoRow"><div>考試別</div><span>{modalInfo.examTypeName}</span></div>
                    <div className="modalInfoRow"><div>命題方式</div><span>{modalInfo.drawUpName}</span></div>
                    <div className="modalInfoRow"><div>試卷類型</div><span>{modalInfo.outputTypeName}</span></div>
                    <div className="modalInfoRow"><div>出卷時間</div><span>{format(getTime(new Date(modalInfo.updateTime)), 'yyyy/MM/dd HH:mm')}</span></div>
                    <div className="modalInfoRow"><div>卷號</div><span>{modalInfo.uid}</span></div>
                  </div>
                </Box>
                <Box>
                  <div className="modalInnerTitle">試卷內容</div>
                  <div className="modalInnerContent">
                    <div className="modalInfoRow"><div>試題範圍</div>
                      <span>
                        {modalInfo.attributes.bookNames.map((item, index, arr) => {
                          if (arr.length - 1 === index) {
                            return `${item}`;
                          } else {
                            return `${item}、`;
                          }
                        })}
                      </span>
                    </div>
                    <div className="modalInfoRow"><div>題數</div><span>{modalInfo.attributes.questionAmount}</span></div>
                  </div>
                </Box>
              </div>

              {!deviceData.isDesktop ? <div className='blockDiv'></div> : null}

              <div className="modalRow controlBox">
                {
                  deviceData.isDesktop ? <div className="modalInnerTitle">編輯複製</div> : null
                }
                <div className="modalInnerContent">
                  <Box mb={deviceData.isDesktop ? 32 : 0}>
                    <div className='copyEditBtnBox'>
                      <Button
                        disabled={!canOperateExamPaper}
                        variant={canOperateExamPaper ? 'brand1' : 'ui02'}
                        onClick={() => {copyEditExamPaper(modalInfo, 'Copy');}}
                      >
                        <CopySolid /> 複製試卷
                      </Button>
                      <Button
                        disabled={!canOperateExamPaper}
                        variant={canOperateExamPaper ? 'brand1' : 'ui02'}
                        onClick={() => {copyEditExamPaper(modalInfo, 'Edit');}}
                      >
                        <FileEditSolid /> 編輯試卷
                      </Button>
                    </div>
                  </Box>
                </div>
                {
                  deviceData.isDesktop ? <div className="modalInnerTitle">操作</div> : null
                }
                <div className="modalInnerContent">
                  <Box className="buttonZone" mb={deviceData.isDesktop ? 54 : 0}>
                    <Button
                      variant={'brand1'}
                      onClick={() => {goToExamPage(modalInfo.uid);}}
                    >
                      <ExternalLinkAltSolid/> 預覽試卷
                    </Button>
                    {
                      (modalInfo.downloadName && modalInfo.download) &&
                        <Button variant={(modalInfo.downloadName && modalInfo.download) ? 'brand1' : 'ui02'}
                          disabled={!(modalInfo.downloadName && modalInfo.download)}
                          onClick=
                            {
                              () => {
                                blobDownload(
                                  modalInfo.download,
                                  modalInfo.downloadName,
                                  'GET'
                                );
                              }
                            }
                        ><FileDownloadSolid /> 下載試卷</Button>
                    }
                    {
                      modalInfo.attributes.outputType === 'Quick' && showCreateQuizBtn &&
                        <Button
                          disabled={false}
                          variant={'brand1'}
                          onClick={() => createQuizExamModalToggle(modalInfo, 'create')}
                        >
                          <RocketSolid/>建立速測
                        </Button>
                    }
                  </Box>
                  {
                    deviceData.isDesktop && modalInfo.attributes.outputType !== 'Quick' &&
                      <Box className="modalPublicContent">
                        <div>
                          <div className="modalPublicContentTitle"><GlobeAsiaSolid />公開試卷</div>
                          <div className="modalHint">公開後，所有使用者將可以檢視這份試卷</div>
                        </div>
                        <div><Switch defaultChecked={modalInfo.attributes.isPublic} onChange={(checked) => {changeExamPaperPublicStatus(modalInfo.uid, checked);}} /></div>
                      </Box>
                  }
                </div>
              </div>
            </div>
          </Modal> : null
      }

      {/* 篩選彈窗 */}
      <Modal
        id={'filterDrawer'}
        visible={filterDrawerVisible}
        zIndex={1001}
        onCancel={filterDrawerFalse}
        width='100%'
        title={
          <>
            <span>篩選</span><div className={classNames('clearAllFilterBtn', (Object.keys(filterParamsMobile).length !== 0 || eduSubjectMobile.edu) ? 'active' : '' )} onClick={clearAllFilter}>清除</div>
          </>
        }
        footer={
          <>
            <Button
              className='reportModalBtn'
              variant={!checkChangeFilterBtn ? 'ui02' : 'brand2'}
              disabled={!checkChangeFilterBtn}
              onClick={doneChangeFilter}
            >使用</Button>
          </>
        }
      >
        <div className='filterDrawerContent'>
          <div className='title'>學制科目</div>
          <div>
            <div className='subTitle'>學制</div>
            <RadioGroup
              key={'eduOptions'}
              name={'eduOptions'}
              className='radioList' buttonStyle='solid'
              optionType='button'
              options={eduOptions}
              value={eduSubjectMobile.edu}
              onChange={(e) => {changeEduSubjectMobile(e.target.value, 'edu');}}
            />
          </div>
          {
            eduSubjectMobile.edu &&
            <div>
              <div className='subTitle'>科目</div>
              <RadioGroup
                key={'subjectOptions'}
                name={'subjectOptions'}
                className='radioList' buttonStyle='solid'
                optionType='button'
                options={subjectOptions[eduSubjectMobile.edu]}
                value={eduSubjectMobile.subject}
                onChange={(e) => {changeEduSubjectMobile(e.target.value, 'subject');}}
              />
            </div>
          }
        </div>
        <div className='filterDrawerContent'>
          <div className='title'>考試別</div>
          <RadioGroup
            key={transformMobileOptions(typeOptions)}
            className='radioList' buttonStyle='solid'
            optionType='button'
            options={transformMobileOptions(typeOptions)}
            value={filterParamsMobile.examType}
            onChange={(e) => {changeFilterParamsMobile(e.target.value, 'examType');}}
          />
        </div>
        <div className='filterDrawerContent'>
          <div className='title'>命題方式</div>
          <RadioGroup
            key={transformMobileOptions(patternOptions)}
            className='radioList' buttonStyle='solid'
            optionType='button'
            options={transformMobileOptions(patternOptions)}
            value={filterParamsMobile.drawUp}
            onChange={(e) => {changeFilterParamsMobile(e.target.value, 'drawUp');}}
          />
        </div>
      </Modal>
      {renderCreateQuizExamModal()}
    </StyledExamPaperListContent>
  );
};