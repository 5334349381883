import React, { useMemo, useState, useEffect, useContext } from 'react';
import { StudentIpAreaSelectContent, StudentIpExamSettingContent, StudentIpExamCompleteContent } from 'containers';
import { StepBox, fetchDataMachine, FETCH_DATA_EVENT, FETCH_DATA_STATE, CreateExamPaperParamsProvider, AuthProvider, MobileStepBox } from '@oneclass/ui-components';
import { ExamPaperHeader } from 'components';
import { Button } from '@oneclass/onedesign';
import { useMachine } from '@xstate/react';
import { setCookie, getCookie } from 'utils/common';
import { getDrawUpAutoCacheQuery } from 'api/drawUpAuto';
import { postCreatePractice } from 'api/practice';
import { useHistory } from 'react-router-dom';
import { StyledStudentIntelligentPropositionPage, StyledPageContent, UiBtnZone } from './StudentIntelligentPropositionPage.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';

const steps = [
  {
    title: '範圍選擇',
  },
  {
    title: '測驗設定',
  },
  {
    title: '出卷完成',
  },
];
const goTop = () => {
  const main = document.getElementById('mainLayout');
  main.scrollTop = 0;
};

const initSaveData = {
  education: '',
  subject: '',
  areaParams: {
    filterUsed: false
  },
  bookOptions: [],
};
export const StudentIntelligentPropositionPage = ({ className }) => {
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const history = useHistory();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const { createExamPaperParams: globalExamPaperData } = useContext(CreateExamPaperParamsProvider.createExamPaperContext);
  const [saveData, setSaveData] = useState({ ...initSaveData });
  const [areaParams, setAreaParams] = useState({});
  const [createExamPayload, setCreateExamPayload] = useState({});
  const [step, setStep] = useState(1);
  const [searchKey, setSearchKey] = useState('');


  const [stateGetDrawUpAutoCacheQuery, sendGetDrawUpAutoCacheQuery] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await getDrawUpAutoCacheQuery(event.pattern, event.payload);
        const { data, isSuccess, systemCode, message } = res;
        // const { curriculumMap = {}, bookMap = {}, sourceMap = {}, chapterMap = {}, yearMap = {} } = data?.selection || {};
        if (isSuccess) { goTop();}
        return {
          isSuccess, systemCode, message,
          data
        };
      },
    },
  });

  const [statePostCreatePractice, sendPostCreatePractice] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await postCreatePractice(event.payload);
        const { isSuccess, systemCode, message, data } = res;
        if (isSuccess) setStep(step + 1);
        const { uid = '' } = data || {};
        return {
          isSuccess, systemCode, message, uid
        };
      },
    },
  });

  const { data } = stateGetDrawUpAutoCacheQuery.context.result || {};
  const { uid } = statePostCreatePractice.context.result || {};

  const jumpStep = (current) => {
    setStep(current + 1);
  };
  const isChangeData = useMemo(() => {
    let result = false;
    if (step === 1) {
      if (Object.keys(saveData.areaParams).length === 1 || Object.keys(areaParams).length === 0) return false;
      Object.entries(areaParams).forEach(([key, value]) => {
        if (key !== 'filterUsed') {
          if (Array.isArray(value)) {
            if (saveData.areaParams[key].length === value.length) {
              if (saveData.areaParams[key].filter(item => !value.includes(item)).length > 0)result = true;
            } else {
              result = true;
            }
          } else {
            if (saveData.areaParams[key] !== value) result = true;
          }
        }

      });
    }

    return result;
  }, [areaParams, step]);

  const disabled = useMemo(() => {
    let result = false;
    switch (step) {
      default:
      case 2:
        if (JSON.stringify(createExamPayload) === '{}') result = true;
        break;
      case 1:
        Object.entries(areaParams).forEach(([key, value]) => {
          if (key !== 'filterUsed') {
            if (!value || value.length === 0) result = true;
          }

        });
        break;
    }
    return result;
  }, [step, areaParams, createExamPayload]);


  const resetData = () => {
    setSaveData({ ...initSaveData });
  };

  const getSendAreaParams = (data) => {
    setAreaParams({ ...data });
  };

  const getCreateExamPayload = (data) => {
    setCreateExamPayload({ ...data });
  };

  const onChangeFilterUsedQuestions = (e) => {
    setAreaParams({
      areaParams:
        {
          ...saveData.areaParams,
          filterUsed: e.target.checked
        }
    });
    setSaveData({
      ...saveData,
      areaParams: {
        ...saveData.areaParams,
        filterUsed: e.target.checked
      },
      education: globalExamPaperData?.edu || 'J',
      subject: globalExamPaperData?.subject || 'GE',
    });
    const { year, keys, bookIDs } = areaParams;
    sendGetDrawUpAutoCacheQuery(FETCH_DATA_EVENT.FETCH, {
      pattern: 'AutoPattern',
      payload: {
        year,
        education: globalExamPaperData?.edu || 'J',
        subject: globalExamPaperData?.subject || 'GE',
        bookIDs,
        keys,
        filterUsed: e.target.checked
      }
    });
  };

  const nextStep = async() => {
    switch (step) {
      case 2:
        sendPostCreatePractice(FETCH_DATA_EVENT.FETCH, { payload: createExamPayload });
        break;
      case 1:
        if (isChangeData) {
          setSaveData({
            ...initSaveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'GE',
          });
        } else {
          setSaveData({
            ...saveData,
            areaParams,
            education: globalExamPaperData?.edu || 'J',
            subject: globalExamPaperData?.subject || 'GE',
          });
        }
        setStep(step + 1);
        break;
      default:
        setStep(step + 1);
        goTop();
        break;
    }


  };
  const prevStep = () => {
    setStep(step - 1);
    goTop();
  };

  const getSaveData = (key, data) => {
    setSaveData({ ...saveData, [key]: data });
  };

  useEffect(() => {
    if (stateGetDrawUpAutoCacheQuery.matches(FETCH_DATA_STATE.DONE)) {
      setStep(2);
    }
  }, [stateGetDrawUpAutoCacheQuery.value]);

  useEffect(() => {
    if (!data) return;
    setSearchKey(data.searchKey);
  }, [data]);

  useEffect(() => {
    if (step === 2 && getCookie('createNewPaper') === null) {
      setCookie('createNewPaper', true, 1);
    }
  }, [step]);

  useEffect(() => {
    if (saveData.education === '' || saveData.subject === '') return;
    if (globalExamPaperData?.edu === saveData.education && globalExamPaperData?.subject === saveData.subject) return;
    resetData();
    setStep(1);
  }, [globalExamPaperData]);

  useEffect(() => {
    Object.entries(globalExamPaperData).forEach(([key, value]) => {
      if (value === '') history.push('./');
    });
  }, []);

  const isPro = useMemo(() => {
    return authState.context.userDetails?.userStatus?.usetype === 'Premium';
  }, [authState]);

  return (
    <StyledStudentIntelligentPropositionPage className={className} data-testid="StudentIntelligentPropositionPage">
      <ExamPaperHeader pageName='智能命題' globalExamPaperData={globalExamPaperData} />

      {
        deviceData.isDesktop ?
          <>
            <StepBox steps={steps} current={step - 1} onChange={jumpStep} stepdisabled={step === 3 ? true : false}>
              <StyledPageContent notLimitHeight={step === 2 || step === 1 }>
                {
                  step === 1 &&
                  <StudentIpAreaSelectContent
                    saveData={saveData}
                    examPaperType="AutoPattern"
                    memberEduSubject={globalExamPaperData}
                    getSendAreaParams={getSendAreaParams}
                    getSaveData={getSaveData}
                    identity={authState.context.userDetails?.userStatus?.identity}
                    isPro={isPro}
                  />
                }
                {
                  step === 2 &&
                  <StudentIpExamSettingContent
                    memberEduSubject={globalExamPaperData}
                    saveData={saveData}
                    // examPaperInfo={data?.questionGroup || {}}
                    onChangeFilterUsedQuestions={onChangeFilterUsedQuestions}
                    getCreateExamPayload={getCreateExamPayload}
                  />
                }
                {
                  step === 3 &&
                  <StudentIpExamCompleteContent
                    uid={uid}
                  >

                  </StudentIpExamCompleteContent>
                }
              </StyledPageContent>

              <UiBtnZone>
                {
                  step !== 1 && step !== 3 &&
                  <Button type="solid" textcolor={'#A1A4B1'} variant="brand1" size='large' isFull={false} onClick={() => {prevStep();}}>
                上一步
                  </Button>
                }
                {
                  step !== 3 &&
                  <Button
                    disabled={disabled}
                    variant={disabled ? 'ui02' : 'brand1'}
                    size='large'
                    isFull={false}
                    onClick={() => {
                      nextStep();
                      // if (isChangeData) {
                      //   if (step === 1 ) {
                      //     setAreaModalToggle();
                      //   }
                      //   if (step === 2) {
                      //     setModalToggle();
                      //   }
                      // } else {
                      //   nextStep();
                      // }
                    }}
                  >
                    {
                      step !== 2 ?
                        '下一步' :
                        '輸出試卷'
                    }
                  </Button>
                }


              </UiBtnZone>
            </StepBox>
          </>
          :
          <MobileStepBox steps={steps} current={step - 1} onChange={jumpStep} stepdisabled={step === 3 ? true : false}>
            <StyledPageContent notLimitHeight={step === 2 || step === 1 }>
              {
                step === 1 &&
                <StudentIpAreaSelectContent
                  saveData={saveData}
                  examPaperType="AutoPattern"
                  memberEduSubject={globalExamPaperData}
                  getSendAreaParams={getSendAreaParams}
                  getSaveData={getSaveData}
                  identity={authState.context.userDetails?.userStatus?.identity}
                />
              }
              {
                step === 2 &&
                <StudentIpExamSettingContent
                  memberEduSubject={globalExamPaperData}
                  saveData={saveData}
                  // examPaperInfo={data?.questionGroup || {}}
                  onChangeFilterUsedQuestions={onChangeFilterUsedQuestions}
                  getCreateExamPayload={getCreateExamPayload}
                />
              }
              {
                step === 3 &&
                <StudentIpExamCompleteContent
                  uid={uid}
                >

                </StudentIpExamCompleteContent>
              }
            </StyledPageContent>

            <UiBtnZone>
              {
                step !== 1 && step !== 3 &&
                <Button type="solid" textcolor={'#A1A4B1'} variant="brand1" size='large' isFull={false} onClick={() => {prevStep();}}>
              上一步
                </Button>
              }
              {
                step !== 3 &&
                <Button
                  disabled={disabled}
                  variant={disabled ? 'ui02' : 'brand1'}
                  size='large'
                  isFull={false}
                  onClick={() => {
                    nextStep();
                    // if (isChangeData) {
                    //   if (step === 1 ) {
                    //     setAreaModalToggle();
                    //   }
                    //   if (step === 2) {
                    //     setModalToggle();
                    //   }
                    // } else {
                    //   nextStep();
                    // }
                  }}
                >
                  {
                    step !== 2 ?
                      '下一步' :
                      '輸出試卷'
                  }
                </Button>
              }
            </UiBtnZone>
          </MobileStepBox>
      }

    </StyledStudentIntelligentPropositionPage>
  );
};