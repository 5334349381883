import styled from 'styled-components';

export const StyledLoadingContent = styled.div`
	width: 100%;
	min-height: 50vh !important;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StyledHomeQuesTypeSelectContent = styled.div.attrs(props => ({
  className: props.className
}))`
    padding: 32px 16px;
    width: 100%;

    .radioHaveSvg{
        position: relative;
        >span:not(.ant-radio-button){
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .pageTitle{
        font-size: 18px;
        padding-left: 16px;
        font-weight:500;
    }
    .flexBox{
        display:flex;
        justify-content: space-between;
    }
    .hideDate{
        justify-content: flex-end !important;
    }
    .flexEnd{
        justify-content: flex-end !important;
    }
    .switchType{
        display:flex;
        align-items: center;
        .switchLabel{
            margin-right: 8px;
        }
        .ant-radio-group-solid .ant-radio-button-wrapper{
            border-radius: 0;
            padding: 4px 12px;
            width: 102px;
        }
        .ant-radio-group-solid .ant-radio-button-wrapper:not(:first-child){
            margin-left: 0;
            border-left-width: 0px;
            :hover{
                border-left-width: 1px;
            }
        }
        .ant-radio-group-solid .ant-radio-button-wrapper:first-child{
            margin-left: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        .ant-radio-group-solid .ant-radio-button-wrapper:last-child{
            margin-left: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .diffculyRow{
        display: flex;
        >div{
            display: flex;
            align-items: center;
        }
    }
    .tableFooter{
        display: flex;
        justify-content:flex-end;
    }
    .tableSwitch{
        margin-left:8px;
    }
    .questionTableBody{
            min-height: auto;
            max-height: none;
        }
    .quesRow{
        &> div{
            &.havePadding{
                padding: 4px 16px;
            }
            &> div{
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                input{
                    text-align: right;
                }
                span{
                    color:${({ theme }) => theme.colors.text.text_06};
                }
            }
        }
        .ant-input{
            padding: 0 0;
            width:40px;
        }
    }
    .dndItem{
        padding: 0 0;
    }
    @media (max-width: 1024px) {
        padding: 16px 16px;
        .questionTypeName{
            padding: 8px 16px;
        }
        .setupBtn{
            padding: 8px 12px;
        }
        .havePadding{
            font-size:16px;
            color:${({ theme }) => theme.colors.text.text_07};
            display: flex;
            align-items:flex-start;
            flex-direction: column;
        }
        .scoreText{
            font-size:14px;
            color:${({ theme }) => theme.colors.text.text_06}
        }
        .tableFooter{
            display: none;
        }
        .scoreBottom{
            position: fixed;
            left: 0;
            bottom:86px;
            width: 100%;
            color: ${({ theme }) => theme.colors.brand.brand03};
            background-color:#fff;
            border-top: 1px solid #E4E7EC;
            padding: 8px 24px;
        }
        .tableZone{
            padding-bottom:0px !important ;
        }
        .scoreTextSelected{
            color: ${({ theme }) => theme.colors.brand.brand03};
        }
        // 配題與配分
        .drawerTitle{
            font-size:20px;
            color:${({ theme }) => theme.colors.text.text_07};
            margin-bottom:24px;
        }
        .drawerSection{
            font-size:16px;
            color:${({ theme }) => theme.colors.text.text_07};
            margin-bottom:8px;
        }
        .sectionSubTitle{
            margin-bottom: 8px;
            font-size:14px;
            color:${({ theme }) => theme.colors.text.text_06};                        
        }
        .drawerTable{
            border-bottom: 1px solid #E4E7EC;
            >div{
                display: flex;
                >div{
                    width: 50%;
                    padding: 8px 16px;
                    >span{
                        color:${({ theme }) => theme.colors.text.text_06};                        
                    }
                }
            }
            >div:nth-child(1){
                background-color:${({ theme }) => theme.colors.ui.ui_01};
            }
            >div:nth-child(2){
                display: flex;
                >div{
                    align-items:flex-end;
                    display: flex;
                    >div{
                        width: 50%;
                        margin-right: 4px;
                    }
                }
            }
        }
        .drawerTotalScore{
            display: flex;
            justify-content:flex-end;
            color: ${({ theme }) => theme.colors.brand.brand03};
            padding:8px 16px;
            width: 100%;
            border-bottom: 1px solid #E4E7EC;
        }
        .setupDrawerScoreBox{
            .ant-input{
                width: 50%;
            }
        }
        .filterBtn{
            display: flex;
            align-items: center;
            padding: 4px 12px;
            width: 132px;
            color: ${({ theme }) => theme.colors.text.text_05};
            background-color: ${({ theme }) => theme.colors.ui.white};
            border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
            cursor: pointer;
            >div{
                display: flex;
                align-items:center;
                >span{
                    margin-right:10px;
                }
            }
        }
        .filterDrawerContent{
            >div{
                display: flex;
                align-items:center;
                >span{
                    margin-right:18px ;
                }
            }
            >div:nth-child(1){
                margin-bottom:24px ;
                font-size:16px;
            }
            >div:nth-child(2){
                font-size:14px;
                margin-bottom:12px ;
                color:${({ theme }) => theme.colors.text.text_06};                        
            }
            div:nth-child(3){
                font-size:14px;
                margin-bottom:12px ;
                color:${({ theme }) => theme.colors.text.text_06};                        
            }
        }
        #setupDrawer{
            .ant-drawer-content-wrapper{
                height: unset !important;
            }
            .ant-drawer-content{
                overflow: hidden;
                border-radius:12px 12px 0 0;
                height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
                height: calc(var(--vh, 1vh) * 100);
            }
            .ant-drawer-body{
                padding: 16px 24px 16px !important;
                height: calc(calc(var(--vh, 1vh) * 100) - 54px - 84px) !important;
                overflow-y: auto;
            }
        }
    }
`;