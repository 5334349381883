import { Api } from '@oneclass/ui-components';
// import { stringify } from 'query-string';

export const getExportPaperRelated = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Exam/Export/Related`, payload);
  return response;
};


export const postExportPaper = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Exam/Export`, payload);
  return response;
};
