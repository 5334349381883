import React, { useEffect, useState } from 'react';
import { ExamPaperListContent, TestListContent } from 'containers';
import {
  AuthProvider,
  Tab,
  TabPane
} from '@oneclass/ui-components';
import { getCookie, setCookie } from 'utils/common';
import { useLocation } from 'react-router-dom';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { StyledExamPaperListPage } from './ExamPaperListPage.style';
import { Announcement } from 'components';

export const ExamPaperListPage = ({ className }) => {
  const [tabType, setTabType] = useState('paperList');
  const tabChange = (key) => {
    setTabType(key);
  };
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const location = useLocation();
  const { renderEduSubjectModal, setEduSubjectModalToggle, setEduSubjectModalFalse } = useEduSubjectModal(authState.context.userDetails?.preference, 'mainLayout', location.pathname);

  const isTutoring = authState.context.userDetails.userStatus?.organization?.type === 'Tutoring';
  const [isShowHint, setIsShowHint] = useState(true);

  useEffect(() => {
    if (getCookie('goToExamList') === 'true') {
      setTimeout(function () {
        tabChange('examList');
        setCookie('goToExamList', false, 1);
      }, 1000);
    }
  }, [getCookie('goToExamList')]);

  return (
    <StyledExamPaperListPage className={className} data-testid="ExamPaperListPage">
      {
        isTutoring ?
          <>
            {
              isShowHint &&
                <Announcement className={'Announcement'} setIsShowHint={setIsShowHint}/>
            }
            <ExamPaperListContent className='Tutoring' setEduSubjectModalToggle={setEduSubjectModalToggle} tabType={tabType} tabChange={tabChange} />
          </>
          :
          <Tab activeKey={tabType} onChange={tabChange} size='large'>
            <TabPane tab="試卷列表" key="paperList">
              <ExamPaperListContent setEduSubjectModalToggle={setEduSubjectModalToggle} tabType={tabType} tabChange={tabChange} />
            </TabPane>
            <TabPane tab="測驗列表" key="examList">
              <TestListContent setEduSubjectModalToggle={setEduSubjectModalToggle} tabType={tabType} />
            </TabPane>
          </Tab>
      }
      {renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)}
    </StyledExamPaperListPage>
  );
};