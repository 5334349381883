/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  Select,
  Pagination,
  Modal,
  sliceTableData,
  Input,
  PickListDropdown,
  sectionToChinese,
  Tab,
  TabPane
} from '@oneclass/ui-components';
import { TestLinkModalContent, TestQrCodeModalContent, TestCodeModalContent } from 'components';
import { Button } from '@oneclass/onedesign';
import { Tooltip } from 'antd';
import { ExternalLinkAltSolid, LinkSolid, DownloadSolid, CopySolid, TrashAltOutline, ChalkboardOutline, EditSolid } from '@onedesign/icon';
import { useBoolean } from 'ahooks';
import { StyledTestListDesktop, StyledFilterBar, StyledActionIcon } from './TestListDesktop.style';
import { v4 as uuidv4 } from 'uuid';

export const TestListDesktop = ({
  className,
  examLink,
  filterParams,
  eduSubjectOptions,
  filterData,
  pageState,
  authState,
  eduOptions,
  pageChange,
  setFilterCondition,
  goToReviewPage,
  copyEditExamPaper,
  fetchQuizExcel,
  transformTime,
  eduGradeOptions,
  renderCreateQuizExamModal,
  createQuizExamModalToggle,
  setCreateQuizExamModalFalse,
  modalVisible,
  setModalVisibleToggle,
  setModalVisibleFalse,
  canEditExam,
  goToExamPage,
  renderLoadingContent,
  isLoading,
  setIsLoading,
}) => {
  const [modalInfo, setModalInfo] = useState(null);
  // const [modalVisible, { toggle: setModalVisibleToggle, setFalse: setModalVisibleFalse }] = useBoolean(false);
  const [testModalVisible, { toggle: setTestModalVisibleToggle, setFalse: setTestModalVisibleFalse }] = useBoolean(false);

  const [tabType, setTabType] = useState('testCode');
  const tabChange = (key) => {
    setTabType(key);
  };
  const canOperateExamPaper = useMemo(() => {
    if (!authState.context.userDetails?.userStatus) return;
    const { identity } = authState.context.userDetails?.userStatus;
    return identity === 'Admin' ||
            identity === 'Editor' ||
            identity === 'Teacher';
  }, [authState.context.userDetails]);

  const showDetailModal = (data) => {
    if (!data) return;
    setModalInfo(data,
      setModalVisibleToggle());
  };

  const showShareModal = (data) => {
    if (!data) return;
    setModalInfo(data,
      setTestModalVisibleToggle());
  };


  const [shareModalData, setShareModalData] = useState({
    endTime: {
      month: '',
      day: '',
      time: '',
    },
    quizCode: {
      top4: '',
      last4: '',
    }
  });

  useEffect(() => {
    if (!modalInfo) return;
    const newTime = transformTime(modalInfo?.EndTime);
    setShareModalData({
      endTime: {
        month: newTime.slice(5, 7),
        day: newTime.slice(8, 10),
        time: newTime.slice(11, 17),
      },
      quizCode: {
        top4: modalInfo?.QuizCode.slice(0, 4),
        last4: modalInfo?.QuizCode.slice(4, 9),
      }
    });
  }, [modalInfo]);

  return (
    <StyledTestListDesktop className={className} data-testid="TestListDesktop">
      <Box mb={4}>
        <StyledFilterBar>
          <Input.Search
            placeholder="請輸入搜尋的內容"
            allowClear={false}
            onPressEnter={(e) => setFilterCondition(e.target.value, 'quizName')}
            onChange={(e) => setFilterCondition(e.target.value, 'quizName')}
            value={filterParams?.quizName}
            maxLength={30}
          />
          <Select placeholder='學制科目' allowClear={filterParams?.eduSubject ? true : false} options={eduSubjectOptions} value={filterParams.eduSubject || null} onChange={(val) => {setFilterCondition(val, 'eduSubject');}} />
          <PickListDropdown.DatePickListDropdown
            targetStr='的測驗'
            defaultTitle='測驗結束日期'
            defaultValue={null}
            onChange={(data) => {setFilterCondition(data, 'date');}}
          />
          <Select placeholder='受測學制年級' allowClear={filterParams?.eduGrade ? true : false} options={eduGradeOptions || []} value={filterParams.eduGrade || null} onChange={(val) => {setFilterCondition(val, 'eduGrade');}} />
        </StyledFilterBar>
      </Box>
      <Box>
        <Table className="tableZone">
          <Table.Header className='tableHeader'>
            <Table.Row isFixedWidth={true}>
              <Table.Item width={'238px'}>測驗名稱</Table.Item>
              <Table.Item width={'90px'}>學制科目</Table.Item>
              <Table.Item width={'144px'}>測驗開始時間</Table.Item>
              <Table.Item width={'144px'}>測驗結束時間</Table.Item>
              <Table.Item width={'88px'}>交卷人數</Table.Item>
              <Table.Item width={'56px'}>操作</Table.Item>
              <Table.Item width={'100px'}>匯出成績</Table.Item>
              <Table.Item width={'100px'}>查看更多</Table.Item>
            </Table.Row>
          </Table.Header>
          {
            isLoading
              ?
              renderLoadingContent()
              :
              <Table.Body bodyMaxHeight={true}>
                {
                  filterData && filterData.length > 0 ? sliceTableData(filterData, pageState.currentPage, pageState.limit).map(item => (
                    <Table.Row key={uuidv4()} isFixedWidth={true} className='tableRow'>

                      <Table.Item width={'238px'} >{item.QuizName}</Table.Item>
                      <Table.Item width={'90px'} >{item.EduSubjectName}</Table.Item>
                      <Table.Item width={'144px'}>{transformTime(item.StartTime)}</Table.Item>
                      <Table.Item width={'144px'}>{transformTime(item.EndTime)}</Table.Item>
                      <Table.Item width={'88px'} textalign={'center'}>{item.attendance}</Table.Item>
                      <Table.Item width={'56px'}>
                        <Tooltip placement="top" title={'分享測驗'} zIndex={100} color='#4e4e5a'>
                          <StyledActionIcon onClick={() => {showShareModal(item);}}><LinkSolid /></StyledActionIcon>
                        </Tooltip>
                      </Table.Item>
                      <Table.Item width={'100px'}><Button className='tableBtn' disabled={item.attendance !== 0 ? false : true} variant={item.attendance !== 0 ? 'brand1' : 'ui01'} onClick={() => {item.attendance !== 0 && fetchQuizExcel(item.QuizUID);}}>匯出成績</Button></Table.Item>
                      <Table.Item width={'100px'}><Button className='tableBtn' variant="brand1" onClick={() => {showDetailModal(item);}}>查看更多</Button></Table.Item>

                    </Table.Row>
                  )) :
                    <div className="tableEmpty">
                      <div>
                        目前還未擁有任何測驗喔！
                      </div>
                      <div>
                        可以將試卷類型為「速測」的試卷建立測驗，讓學生快速進行線上測驗
                      </div>
                    </div>
                }
              </Table.Body>
          }
        </Table>
        <div className="pageBlock">
          <Pagination
            total={filterData?.length || 0}
            defaultCurrent={1}
            current={pageState.currentPage}
            onChange={pageChange}
            defaultPageSize={pageState.limit}
          />
        </div>
      </Box>

      {
        modalInfo &&
        <Modal
          id={'detailModal'}
          visible={modalVisible}
          width={'900px'}
          onCancel={setModalVisibleFalse}
          title={
            <div>測驗詳情</div>
          }
        >
          <div className="modalContent">
            <div className="modalRow">
              <Box mb={4}>
                <div className="modalInnerTitle editBox">
                  <span>測驗設定</span>
                  {
                    canEditExam(modalInfo)
                      ?
                      <Button
                        disabled={!canEditExam(modalInfo)}
                        className={canEditExam(modalInfo) ? 'editBtn' : 'editBtn disabled'}
                        onClick={() => {createQuizExamModalToggle(modalInfo, 'edit');}}
                      >
                        <EditSolid/>編輯
                      </Button>
                      :
                      <Button
                        disabled={!canEditExam(modalInfo)}
                        className={canEditExam(modalInfo) ? 'editBtn' : 'editBtn disabled'}
                        onClick={() => {createQuizExamModalToggle(modalInfo, 'edit');}}
                      >
                        <Tooltip placement="top" title={'測驗已開始，無法編輯'} zIndex={1003} color='#4e4e5a'>
                          <EditSolid/>編輯
                        </Tooltip>
                      </Button>
                  }
                </div>
                <div className="modalInnerContent">
                  <div className="modalInfoRow"><div>測驗名稱</div><span>{modalInfo.QuizName}</span></div>
                  <div className="modalInfoRow"><div>測驗開始時間</div><span>{transformTime(modalInfo.StartTime)}</span></div>
                  <div className="modalInfoRow"><div>測驗結束時間</div><span>{transformTime(modalInfo.EndTime)}</span></div>
                  <div className="modalInfoRow"><div>作答時長</div><span>{modalInfo.Duration}分鐘</span></div>
                  <div className="modalInfoRow">
                    <div>受測對象年級</div>
                    <span>{eduOptions.find(edu => edu.value === modalInfo.Education).name}{sectionToChinese(modalInfo.Grade.substring(1))}年級</span>
                  </div>
                </div>
              </Box>
              <Box>
                <div className="modalInnerTitle">試卷資料</div>
                <div className="modalInnerContent">
                  <div className="modalInfoRow"><div>測驗試卷</div><span>{modalInfo.ExamName}</span></div>
                  <div className="modalInfoRow"><div>學制科目</div><span>{modalInfo.EduSubjectName}</span></div>
                </div>
              </Box>
            </div>

            <div className="modalRow">
              <Box>
                <div className="modalInnerTitle">操作</div>
                <div className="modalInnerContent">
                  <Box className="buttonZone" >
                    <div className='copyEditBtnBox'>
                      <Button
                        variant={'brand2'}
                        onClick={() => {showShareModal(modalInfo);}}
                      >
                        <LinkSolid /> 分享測驗
                      </Button>

                      <Button
                        variant={'brand2'}
                        onClick={() => {goToExamPage(modalInfo?.ExamUID);}}
                      >
                        <ExternalLinkAltSolid /> 預覽試卷
                      </Button>

                      {/* <Button
                        disabled={true}
                        variant={'ui02'}
                        onClick={() => {}}
                      >
                        <CopySolid /> 複製測驗
                      </Button>

                      <Button
                        disabled={true}
                        variant={'ui02'}
                        onClick={() => {}}
                      >
                        <TrashAltOutline /> 刪除測驗
                      </Button> */}
                    </div>
                  </Box>
                </div>
              </Box>
              <Box>
                <div className="modalInnerTitle">測驗結果</div>
                <div className="modalInnerContent">
                  <div className="modalInfoRow countPer"><div>交卷人數</div><span>{modalInfo.attendance}</span></div>
                  <Box className="buttonZone" mt={4}>
                    <div className='copyEditBtnBox'>
                      <Button
                        disabled={modalInfo.attendance !== 0 ? false : true}
                        variant={modalInfo.attendance !== 0 ? 'brand2' : 'ui02'}
                        onClick={() => {modalInfo.attendance !== 0 && fetchQuizExcel(modalInfo.QuizUID);}}
                      >
                        <Tooltip trigger={modalInfo.attendance === 0 ? 'hover' : ''} placement="top" title={'尚無人交卷'} zIndex={1003} color='#4e4e5a'>
                          <DownloadSolid /> 匯出成績
                        </Tooltip>
                      </Button>

                      <Button
                        disabled={modalInfo.attendance !== 0 ? false : true}
                        variant={modalInfo.attendance !== 0 ? 'brand2' : 'ui02'}
                        onClick={() => {goToReviewPage(modalInfo.SchoolYear, modalInfo.QuizCode, modalInfo.QuizUID);}}
                      >
                        <Tooltip trigger={modalInfo.attendance === 0 ? 'hover' : ''} placement="top" title={'尚無人交卷'} zIndex={1003} color='#4e4e5a'>
                          <ChalkboardOutline /> 檢討測驗
                        </Tooltip>
                      </Button>
                    </div>
                  </Box>
                  {/* {
                    modalInfo.attendance === 0 && <div className='exportTip'>待有人交卷，即可匯出成績</div>
                  } */}
                </div>
              </Box>

            </div>
          </div>
        </Modal>
      }
      {
        modalInfo &&
        <Modal
          id={'testModal'}
          visible={testModalVisible}
          width={'860px'}
          onCancel={setTestModalVisibleFalse}
          title={
            ''
          }
        >
          <Tab activeKey={tabType} onChange={tabChange} size='large'>
            <TabPane tab="測驗碼" key="testCode">
              <TestCodeModalContent examLink={examLink} modalInfo={modalInfo} shareModalData={shareModalData} />
            </TabPane>
            <TabPane tab="測驗連結" key="testLink">
              <TestLinkModalContent examLink={examLink} modalInfo={modalInfo} shareModalData={shareModalData} />
            </TabPane>
            <TabPane tab="QR code" key="qrCode">
              <TestQrCodeModalContent examLink={examLink} modalInfo={modalInfo} shareModalData={shareModalData} />
            </TabPane>
          </Tab>
        </Modal>
      }

      {
        renderCreateQuizExamModal()
      }

    </StyledTestListDesktop>
  );
};

TestListDesktop.propTypes = {
  className: PropTypes.string,
  filterParams: PropTypes.object,
  eduSubjectOptions: PropTypes.array,
  typeOptions: PropTypes.array,
  patternOptions: PropTypes.array,
  filterData: PropTypes.array,
  pageState: PropTypes.object,
  authState: PropTypes.object,
  pageChange: PropTypes.func,
  setFilterCondition: PropTypes.func,
  goToReviewPage: PropTypes.func,
  copyEditExamPaper: PropTypes.func,
};