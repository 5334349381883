import styled from 'styled-components';

export const StyledTestListContent = styled.div.attrs(props => ({
  className: props.className
}))`
/* Code Here */

@media (max-width: 1024px) {
  .loadingContent{
      height: calc(100vh - 169px);
  }
}
`;