import styled from 'styled-components';


export const StyledDragRow = styled.div`
    width: 80px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
  svg {
      color: ${({ disabled, theme }) => disabled ? theme.colors.ui.ui_04 : theme.colors.ui.ui_05};
  }
`;

export const StyledHomeLayoutOptionContent = styled.div.attrs(props => ({
  className: props.className
}))`
    padding: 32px 24px;
    width: 100%;
    .pageTitle{
        font-size: 18px;
        padding-left: 16px;
        font-weight:500;
        @media (max-width: 1024px) {
            padding-left: 0px;
        }
    }
    .pageTitleBox{
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper{
        padding:12px 16px;
        font-size: 16px;
        @media (max-width: 1024px) {
            padding: 8px 16px;
            margin: 0 8px 8px 0;
            margin-left: 0 !important;
        }
    }

    .tableFooter{
        display: flex;
        justify-content:flex-end;
    }
    .tableBody{
        min-height: auto;
        & >div{
            &> div{
                padding: 8px 16px;
            }
        }
    }
    @media (max-width: 1024px) {
        .mutiCheck{
            >div{
                padding: 8px 20px;
                border-radius: 20px;
            }
        }
        // 配題與配分
        .setupDrawer{
            .ant-drawer-content-wrapper{
                height: unset !important;
            }
            .ant-drawer-content{
                overflow: hidden;
                border-radius:12px 12px 0 0;
                height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
                height: calc(var(--vh, 1vh) * 100);
            }
            .ant-drawer-body{
                padding: 16px 24px 16px !important;
                height: calc(calc(var(--vh, 1vh) * 100) - 54px - 84px) !important;
                overflow-y: auto;
            }
            .setupDrawerTotalScore{
                color: ${({ theme }) => theme.colors.brand.brand03};
                padding: 0 0 8px 0;
                border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
                margin-bottom: 24px;
                >span{
                    display: block;
                }
                >span:nth-child(1){
                    margin-bottom: 4px;
                }
            }
            .questionTypeBox{
                margin-bottom: 24px;
            }
            .drawerTitle{
                font-size:20px;
                color:${({ theme }) => theme.colors.text.text_07};
                margin-bottom:24px;
            }
            .drawerSection{
                font-size:16px;
                color:${({ theme }) => theme.colors.text.text_07};
                margin-bottom:8px;
            }
            .sectionSubTitle{
                margin-bottom: 8px;
                font-size:14px;
                color:${({ theme }) => theme.colors.text.text_06};                        
            }
            .drawerTable{
                border-bottom: 1px solid #E4E7EC;
                >div{
                    display: flex;
                    >div{
                        width: 50%;
                        padding: 8px 16px;
                        >span{
                            color:${({ theme }) => theme.colors.text.text_06};                        
                        }
                    }
                }
                >div:nth-child(1){
                    background-color:${({ theme }) => theme.colors.ui.ui_01};
                }
                >div:nth-child(2){
                    display: flex;
                    >div{
                        align-items:flex-end;
                        display: flex;
                        >div{
                            width: 50%;
                            margin-right: 4px;
                        }
                    }
                }
            }
            .drawerTotalScore{
                display: flex;
                justify-content:flex-end;
                color: ${({ theme }) => theme.colors.brand.brand03};
                padding:8px 16px;
                width: 100%;
                border-bottom: 1px solid #E4E7EC;
            }
            .setupDrawerScoreBox{
                .ant-input{
                    width: 50%;
                }
                input{
                    text-align: right;
                }
            }
            .chartPieSolidIcon{
                position: absolute;
                top: 16px;
                right: 20px;
            }
            .tipText{
                color:${({ theme }) => theme.colors.text.text_05};                        
            }
        }
    }
`;
