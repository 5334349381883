// eslint-disable-next-line react/prop-types
import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Box,
  fetchDataMachine,
  FETCH_DATA_EVENT,
  AuthProvider,
} from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { ExternalLinkAltSolid, FolderOpenSolid, PaperPlaneSolid, RocketSolid } from '@onedesign/icon';
import { useMachine } from '@xstate/react';
import { getExamPaperPreview, getExamPaperExportProgress } from 'api/examPaper';
import { useHistory, useLocation } from 'react-router-dom';
import { useInterval } from 'ahooks';
import { blobDownload } from 'utils/common';
import { StyledHomeQuestionCompleteContent } from './HomeQuestionCompleteContent.style';
// import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import { default as deviceProvider } from 'providers/deviceProvider.js';
import { useEduSubjectModal } from 'utils/hooks/useEduSubjectModal';
import { useCreateQuizExamModal } from 'utils/hooks/useCreateQuizExamModal';
import { setCookie } from 'utils/common';

export const HomeQuestionCompleteContent = ({
  onlineExamUID,
  examUID,
  UID,
  outputType,
  globalExamPaperData,
  sendParams,
  examPaperTotalScoreData,
  setStep,
  setReCreateExam,
  eduSubjectData,
}) => {
  const history = useHistory();
  // const { isDesktop } = useDeviceDetect();
  const { deviceData, deviceDataProviderChange } = useContext(deviceProvider.deviceProviderContext);
  const location = useLocation();
  const { useAuthState } = AuthProvider;
  const authState = useAuthState();
  const [stateGetExamPaperPreview, sendGetExamPaperPreview] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await getExamPaperPreview(event.examUID);
        const { data, isSuccess, systemCode, message } = res;
        if (isSuccess) window.open(data);

        return {
          isSuccess, systemCode, message
        };
      },
    },
  });

  const [stateGetExamPaperExportProgress, sendGetExamPaperExportProgress] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_, event) => {
        const res = await getExamPaperExportProgress(event.UID);
        const { isSuccess, systemCode, message, data } = res;
        const {
          statusDesc,
          status,
          minutes,
          seconds,
          waitingPaper,
          download,
          downloadName
        } = data;
        return {
          isSuccess, systemCode, message,
          statusDesc,
          status,
          minutes,
          seconds,
          waitingPaper,
          download,
          downloadName
        };
      },
    },
  });

  const {
    statusDesc,
    status,
    minutes,
    seconds,
    waitingPaper,
    download,
    downloadName
  } = stateGetExamPaperExportProgress.context.result || {};

  const goToExamPage = () => {
    if (!onlineExamUID) return;
    sendGetExamPaperPreview(FETCH_DATA_EVENT.FETCH, {
      examUID: onlineExamUID
    });

  };

  const goToExamList = () => {
    history.push('/examPaperList');
  };
  // const downloadFile = () => {
  //   blobDownload(
  //     'https://firebasestorage.googleapis.com/v0/b/onepaper-1126a.appspot.com/o/examination%2Fad57f80e0d1a400482245cc3b7717e10.zip?alt=media&token=3d5bd472-7401-45a1-b60c-839ac897531e', //download,
  //     '2022-01-14-03-39-34國中英語試卷.zip', //downloadName,
  //     'GET'
  //   );
  // };
  const downloadFile = () => {
    blobDownload(
      download,
      downloadName,
      'GET'
    );
  };
  useInterval(() => {
    if (outputType !== 'Files') return;
    sendGetExamPaperExportProgress(FETCH_DATA_EVENT.FETCH, {
      UID
    });
  }, 20000);

  useEffect(() => {
    if (outputType !== 'Files') return;
    sendGetExamPaperExportProgress(FETCH_DATA_EVENT.FETCH, {
      UID
    });
  }, []);

  const jumpToExamList = () => {
    setCookie('goToExamList', true, 1);
    goToExamList();
  };

  const { renderCreateQuizExamModal, createQuizExamModalToggle, setCreateQuizExamModalFalse } = useCreateQuizExamModal(jumpToExamList);

  const paperData = useMemo(() => {
    if (!sendParams || !examUID) return {};
    return {
      uid: examUID,
      attributes: {
        questionAmount: examPaperTotalScoreData.questionCount,
        education: eduSubjectData.edu,
      },
      name: sendParams.paperName.value,
    };
  }, [sendParams, examUID]);

  const goBackStep1 = () => {
    setReCreateExam(true);
    setStep(1);
  };

  const { renderEduSubjectModal, setEduSubjectModalToggle, setEduSubjectModalFalse, useEduSubjectModalIsDone } = useEduSubjectModal(authState.context.userDetails?.preference, 'mainLayout', location.pathname, goBackStep1);

  return (
    <StyledHomeQuestionCompleteContent data-testid="HomeQuestionCompleteContent">

      {
        outputType === 'Files' ?
          <>
            {
              status !== 'Finished' ?
                <>
                  <Box pb={10}>
                    <img src='/assets/paperLoading.svg' alt='examPaper' />
                  </Box>
                  <Box pb={4} className='loadingRow'>
                    <div className="load-wrapp">
                      <div className="load-10">
                        <div className="bar"></div>
                      </div>
                    </div>
                  </Box>
                  <Box pb={8}>
                    <div className='titleRow'>
                      <div className="pageTitle">{statusDesc}</div>
                      {/* <div>完成組卷大約需要 {minutes !== 0 && `${minutes}分`}{seconds !== 0 && `${seconds}秒`}</div> */}
                    </div>

                  </Box>
                  <div className='fixedBottomBox'>
                    <Box pb={4} className='btnBox'>
                      <Button onClick={() => setEduSubjectModalToggle()}><PaperPlaneSolid/>再次出卷</Button>
                      <Button onClick={() => {goToExamList();}}><FolderOpenSolid/>試卷列表</Button>
                    </Box>
                  </div>
                  {/* <Box pb={6} className='controlBtnBox'>
                    <Button isLoading={stateGetExamPaperPreview.context.isLoading} type='solid' variant='ui01' onClick={() => {goToExamList();}}><FolderOpenSolid />前往試卷列表</Button>
                  </Box> */}
                  <Box pb={6}>
                    <div className='textRow'>
                      <div className="hint">您可留在此頁等候組卷完成並查看試卷</div>
                      <div className="hint">或離開此頁面操作其他功能{!deviceData.isDesktop ? <br/> : '，'}組卷完成後將可於試卷列表查看試卷</div>
                    </div>
                  </Box>
                </>
                :
                <>
                  <Box pb={10}>
                    <img src='/assets/paperComplete.svg' alt='examPaper' />
                  </Box>
                  <Box pb={8}>
                    <div className="pageTitle">組卷完成</div>
                  </Box>
                  <div className='fixedBottomBox'>
                    <Box pb={3} className='controlBtnBox'>
                      <Button isLoading={stateGetExamPaperPreview.context.isLoading} variant='brand1' onClick={downloadFile} ><ExternalLinkAltSolid />下載檔案</Button>
                    </Box>
                    <Box pb={4} className='btnBox'>
                      <Button onClick={() => setEduSubjectModalToggle()}><PaperPlaneSolid/>再次出卷</Button>
                      <Button onClick={() => {goToExamList();}}><FolderOpenSolid/>試卷列表</Button>
                    </Box>
                  </div>
                  <Box pb={3}>
                    <div className="hint">線上試卷已組卷完成，<br/>您也可至試卷列表查看試卷並進行更多操作</div>
                  </Box>
                </>
            }
          </> :
          <>
            <Box pb={10}>
              <img src='/assets/paperComplete.svg' alt='examPaper' />
            </Box>
            <Box pb={8}>
              <div className="pageTitle">組卷完成</div>
            </Box>
            <div className='fixedBottomBox'>
              <Box pb={3} className='controlBtnBox'>
                {
                  outputType === 'Quick' ?
                    <Button variant='brand1' onClick={() => {examUID && createQuizExamModalToggle(paperData, 'create');}}><RocketSolid />建立測驗</Button>
                    :
                    <Button isLoading={stateGetExamPaperPreview.context.isLoading} variant='brand1' onClick={() => {goToExamPage();}}><ExternalLinkAltSolid />檢視試卷</Button>
                }
              </Box>
              <Box pb={4} className='btnBox'>
                <Button onClick={() => setEduSubjectModalToggle()}><PaperPlaneSolid/>再次出卷</Button>
                <Button onClick={() => {goToExamList();}}><FolderOpenSolid/>試卷列表</Button>
              </Box>
            </div>

            <Box pb={3}>
              <div className="hint">線上試卷已組卷完成，<br/>您也可至試卷列表查看試卷並進行更多操作</div>
            </Box>
          </>
      }
      {
        renderEduSubjectModal(authState.context.userDetails?.onePaperIdentity)
      }
      {renderCreateQuizExamModal()}
    </StyledHomeQuestionCompleteContent>
  );
};